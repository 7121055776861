<div #scroll class="w-100">

  <!--top block with name, logo  and search-->
  <div *ngIf="!userContentCase || homeSearch"
    class="header mat-elevation-z2 w-100 d-flex justify-content-between inherit-height">

    <a [href]="'/internal'">
      <img [src]="authService.company?.company_logo" alt="company logo" class="inherit-height pt-3 pl-1"
        style="max-height: 60px; max-width: 200px;"
        onerror="this.onerror=null; this.src='assets/logo/clypp_logo_small.png'">
    </a>

    <!--search field-->
    <div *ngIf="authService.currentUser?.is_profile_completed && !smallSc && !userContentCase"
      class="d-flex justify-content-center pt-1" style="width: 30%;">
      <mat-form-field appearance="outline" class="w-100" style="font-size: initial; ">
        <mat-icon (click)="applyFilter()" class="pointer" matPrefix>search</mat-icon>
        <input (keyup.enter)="applyFilter()" [(ngModel)]="searchQuery" autocomplete="off" matInput cdkFocusInitial
          autofocus placeholder="{{'Search your organization\'s content…'|translate}}">
        <mat-icon (click)="clearInput();" *ngIf="searchQuery || userContentCase" class="pointer"
          matSuffix>close</mat-icon>
      </mat-form-field>
    </div>
    <div *ngIf="!userContentCase" class="d-flex justify-content-end inherit-height mr-3 align-items-center">
      <div *ngIf="authService.currentUser?.is_profile_completed && !userContentCase" class="d-flex justify-content-end "
        style="height: fit-content;">

        <!--Show it only in large screen-->
        <button (click)='navbarService.createPRVideo();'
          [ngClass]="authService.currentUser?.is_creator ? 'mat-flat-button text-white' : 'mat-stroked-button'"
          class="borderLeft d-none d-md-inline" style="right:1px">
          <mat-icon class="mr-1" style="padding-top:0.2em;">add</mat-icon>
          <span class="text-nunito font-size-16" translate>New Clypp</span>
        </button>


        <!--Show it only in large screen-->
        <button [matMenuTriggerFor]="createMenu"
          [ngClass]="authService.currentUser?.is_creator ? 'mat-flat-button text-white' : 'mat-stroked-button'"
          class="borderRight d-none d-md-inline">
          <mat-icon class="pt-2">expand_more</mat-icon>
        </button>


        <!--Show it only in small screen when user is not a creator-->
        <button *ngIf="!authService.currentUser?.is_creator" (click)="navbarService.setCreatorPopup();"
          class="d-inline d-md-none border-radius-20 text-dark" color="primary" mat-stroked-button>
          <mat-icon>add</mat-icon>
        </button>

        <!--Show it only in small screen when user is a creator-->
        <button *ngIf="authService.currentUser?.is_creator" [matMenuTriggerFor]="createMenu"
          class="d-inline d-md-none border-radius-20 userButton" color="primary" mat-flat-button>
          <mat-icon>add</mat-icon>
        </button>

        <mat-menu #createMenu="matMenu">
          <button (click)="navbarService.createPRVideo();" class="d-inline d-md-none" mat-menu-item>
            <mat-icon class="material-icons-outlined">radio_button_checked</mat-icon>
            <span class="text-nunito font-size-16" translate>New Clypp</span>
          </button>
          <button (click)="navbarService.createTopic()" mat-menu-item>
            <mat-icon class="material-icons-outlined">post_add</mat-icon>
            <span class="text-nunito font-size-16" translate>New Page</span></button>
          <button (click)="navbarService.addPlaylist()" mat-menu-item>
            <mat-icon>playlist_add</mat-icon>
            <span class="text-nunito font-size-16" translate>Neue Playlist</span></button>
          <button (click)="navbarService.openVideoRequestDialog()" mat-menu-item>
            <mat-icon class="material-icons-outlined">add_comment</mat-icon>
            <span class="text-nunito font-size-16" translate>New Request</span></button>
        </mat-menu>
      </div>
      
      <div [matMenuTriggerFor]="menu" class="avatar-fab cursor-pointer mr-1" matTooltip="{{'Mein Profil' | translate}}">
        <img [src]="authService.currentUser?.profile_pic" alt="profile"
          onerror="this.onerror=null; this.src='https://avatar-service-platform.personio.de/'" />
      </div>

    </div>
  </div>

  <div class="pt-4">

    <mat-card-title *ngIf="!userContentCase && !homeSearch" [title]="authService.currentUser?.email"
      class="col-lg-6 col-md-12 col-sm-12 col-12 fontStyle ">
      {{greetings_text}},&nbsp;{{authService.currentUser?.firstName}}&nbsp;👋
    </mat-card-title>

    <mat-menu #menu="matMenu">
      <button (click)="openAccountPage()" mat-menu-item>
        <mat-icon class="material-icons-outlined">manage_accounts</mat-icon>
        <span class="text-nunito font-size-16" translate>My Account</span>
      </button>
      <button (click)="openFeedbackDialog();" mat-menu-item>
        <mat-icon class="material-icons-outlined">feedback</mat-icon>
        <span class="text-nunito font-size-16" translate>Contact Support</span>
      </button>
      <button (click)="clyppPublicPage();" mat-menu-item>
        <mat-icon class="material-icons-outlined">dvr</mat-icon>
        <span class="text-nunito font-size-16" translate>Video Tutorials</span>
      </button>
      <mat-divider></mat-divider>
      <button (click)="openHelpPage();" mat-menu-item>
        <mat-icon class="material-icons-outlined">live_help</mat-icon>
        <span class="text-nunito font-size-16" translate>Help Center</span>
      </button>
      <button (click)="openNewsPage();" mat-menu-item>
        <mat-icon class="material-icons-outlined">new_releases</mat-icon>
        <span class="text-nunito font-size-16" translate>Neuigkeiten</span>
      </button>
    </mat-menu>
    <div *ngIf="userActivityResp && !userContentCase && !homeSearch"
      class="d-flex flex-wrap justify-content-around my-3">
      <!--Your activities-->
      <div class="d-flex flex-wrap justify-content-around" style="min-width: 50%;">
        <div class="simple-card" style="margin-top: 10px;">
          <p class="text-nunito mt-4" style="font-size:large;" translate>
            Your activities
          </p>
          <div class="d-flex mt-4">
            <mat-icon class="material-symbols-outlined" color="primary"
              style="font-size: 35px;width: 35px;height: 35px;">
              sentiment_very_satisfied
            </mat-icon>
            <div class="fontStyle ml-2 align-self-center" style="font-size: xx-large;">
              {{userActivityResp.n_7_days}}
            </div>
          </div>
          <div *ngIf="increasePercentage" class="mt-4">
            <!--hide in case of 0-->
            <div *ngIf="increasePercentage > 0" class="d-flex align-items-center" style="font-size:large;">
              <mat-icon class="mr-1" style="color: mediumseagreen;">arrow_upward</mat-icon>
              <span style="color:mediumseagreen;">{{increasePercentage}}%</span>
              <span class="fontNormal ml-2" translate>more than last 7 days</span>
            </div>
            <div *ngIf="increasePercentage < 0" class="d-flex align-items-center" style="font-size:large;">
              <mat-icon class="mr-1" color="warn">arrow_downward</mat-icon>
              <!--todo: show absolute value-->
              <span style="color:red;">{{increasePercentage}}%</span>
              <span class="fontNormal ml-2" translate>less than last 7 days</span>
            </div>
          </div>
        </div>
        <!-- Days Active -->
        <div class="simple-card">
          <p class="text-nunito mt-4" style="font-size:large;" translate>
            Days active
          </p>
          <span class="text-nunito mt-4">
            <mat-icon color="primary" style="vertical-align: bottom;">
              event_available
            </mat-icon>
            {{active_days_label.length}}&nbsp;{{'of the last 7 days'|translate}}
          </span>
          <div class="d-flex mt-4">
            <div *ngFor="let i of seven_days_label; let index = index;"
              class="d-flex flex-column align-items-center mx-1">
              <span class="text-nunito">{{i|translate}}</span>
              <mat-icon *ngIf="!active_days_label.includes(i)" color="primary">radio_button_unchecked</mat-icon>
              <mat-icon *ngIf="active_days_label.includes(i) && index != 6" color="primary">check_circle</mat-icon>
              <mat-icon *ngIf="index == 6" style="color: goldenrod;">check_circle</mat-icon>
            </div>
          </div>
        </div>
      </div>

      <!-- Your Workspace & Your Content -->
      <div class="d-flex flex-wrap justify-content-around" style="min-width: 50%;">
        <div class="simple-card">
          <mat-button-toggle-group (change)="onChartChange();" [(ngModel)]="chart_button_toggle"
            aria-label="button toggle" class="w-100" style="border-radius: 10px 10px 0px 0px;">
            <!--if user is a creator, then show 50%, else 100% width of first button-->
            <mat-button-toggle
              [ngClass]="authService.currentUser.is_creator ? 'w-fill-available' : 'w-100 bg-transparent'" value="all">
              <span class="text-nunito font-medium" translate>Your workspace</span>
            </mat-button-toggle>
            <!--hidden for non creators-->
            <mat-button-toggle *ngIf="authService.currentUser.is_creator" class="w-fill-available" value="mine">
              <span class="text-nunito font-medium" translate>Your content</span>
            </mat-button-toggle>
          </mat-button-toggle-group>
          <div class="bg-white d-flex" style="height: 160px;">
            <div class="text-center position-absolute" style="width: 205px; margin-top: 63px;">
              <h2 *ngIf="chart_button_toggle=='all'" class="text-nunito m-0">
                <b>{{
                  userActivityResp.n_all_topics + userActivityResp.n_all_playlists +
                  userActivityResp.n_all_videos
                  }}</b>

              </h2>
              <h2 *ngIf="chart_button_toggle=='mine'" class="text-nunito m-0">
                <b>{{
                  userActivityResp.n_my_topics + userActivityResp.n_my_videos +
                  userActivityResp.n_my_playlists
                  }}</b>
              </h2>
            </div>
            <canvas [ngClass]="chart_button_toggle=='all' ? 'd-block' : 'd-none'" class="p-2" id="canvas1"
              style="z-index: 1;"></canvas>
            <canvas [ngClass]="chart_button_toggle=='mine' ? 'd-block' : 'd-none'" class="p-2" id="canvas2"
              style="z-index: 1;"></canvas>
          </div>
        </div>

        <!-- Explore New Content -->
        <div class="simple-card">
          <p class="text-nunito mt-4" style="font-size:large;" translate>
            Explore new content
          </p>
          <div class="w-100" style="overflow-x: hidden;">
            <button (click)="navigateTouserContentCase(card.id);" *ngFor="let card of recent_users"
              class="my-2 d-flex align-items-center" mat-menu-item>
              <img [alt]="card.email" [src]="card.userprofile.profile_pic" aria-label="view clypps by this user"
                class="cursor-pointer mr-3" mat-card-avatar>
              <div class="d-flex flex-column line-height-initial">
                <mat-card-title [title]="card.email" class="fontNormal"
                  style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                  {{ card.first_name }}&nbsp;{{ card.last_name }}
                </mat-card-title>
                <mat-card-subtitle class="text-nunito mb-1" title="{{'in the last 30 days'|translate}}">
                  {{ card.n_videos_30_days }}
                  {{ card.n_videos_30_days == 1 ? ('new Clypp' | translate) : ('new Clypps' | translate) }}
                  <!--todo: use pages-->
                  <!--{{card.n_videos_30_days}}&nbsp;
                {{card.n_videos_30_days == 1 ? ('Page'|translate) : ('Pages'|translate)}}-->
                </mat-card-subtitle>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Tags-->
    <div *ngIf="!userContentCase && !homeSearch" class="chip-list m-3 p-3">
      <mat-chip-list aria-label="tags">
        <!-- load all videos with cleared search -->
        <!-- <mat-chip (click)="getAllClypps()">
          <mat-icon style="font-size: large;">play_circle_outline</mat-icon>
          {{'All Clypps' | translate}}
        </mat-chip> -->
        <mat-chip (click)="partnerSelected(partner);" *ngFor="let partner of partner_companies">
          {{partner.sharing_company.name}}
        </mat-chip>
        <mat-chip (click)="tagSelected(tag);" *ngFor="let tag of tags">
          {{tag.name}}
        </mat-chip>
      </mat-chip-list>
    </div>
    <div *ngIf="mixed_tags_content.length == 0 && !showSpinner && !homeSearch"
      class="d-flex justify-content-center align-items-center" style="height: 300px;">
      <h3>
        {{'No results. Search in other categories or'|translate}}
        <u (click)="navbarService.openVideoRequestDialog();" class="pointer">
          {{'ask your colleagues'|translate}}
        </u>
      </h3>
    </div>
    <!--Tags/ Partner Company Name-->
    <div *ngIf="mixed_tags_content.length && !userContentCase && page_title != '' && !homeSearch"
      class="border-radius-10 m-3 p-3 bg-primary-555">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h2 class="fontStyle d-flex align-items-center m-0">
          {{page_title}}
        </h2>
      </div>

      <!--Tags/ Partner Company results-->
      <div *ngIf="page_title != '' && mixed_tags_content.length !=0 && !homeSearch">
        <div class="left-right-div" id="all-clypps-tags">
          <ng-container *ngFor="let card of mixed_tags_content">
            <app-video-card (click)="playVideo(card.data.id)" *ngIf="card.type == 'video'" [backendUrl]="backendUrl"
              [videoObject]="card.data" [disableClick]="true" style="margin-right: 2rem; max-width: 320px;">
            </app-video-card>
            <app-topic-card (click)="displayPage(card.data.id)" [backendUrl]="backendUrl" *ngIf="card.type == 'topic'"
              [topic_object]="card.data" [disableClick]="true" style="margin-right: 2rem; max-width: 320px;">
            </app-topic-card>
          </ng-container>

        <!--button 1 with absolute position to the left-->
          <button *ngIf="mixed_tags_content.length > 1" (click)="scrollToLeftRight('all-clypps-tags',true);"
            class="left-right-button left-scroll-button" mat-button>
            <mat-icon class="text-white">
              chevron_left
            </mat-icon>
          </button>

          <!--button 2 with absolute position to the left-->
          <button *ngIf="mixed_tags_content.length > 1" (click)="scrollToLeftRight('all-clypps-tags');"
            class="left-right-button right-scroll-button" mat-button>
            <mat-icon class="text-white">
              chevron_right
            </mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="showSpinner && mixed_tags_content.length == 0 && !userContentCase && page_title != ''  "
      class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
    <!-- featured -->
    <div *ngIf="mixed_featured_content.length && !userContentCase && !homeSearch"
      class="border-radius-10 m-3 p-3 bg-primary-555">
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="fontStyle d-flex align-items-center m-0">
          <mat-icon class="material-icons-outlined mr-2" color="primary">grade</mat-icon>
          {{'Hervorgehoben'|translate}}
        </h2>
      </div>

      <div class="left-right-div" id="featured-content">
        <ng-container *ngFor="let card of mixed_featured_content">
          <app-video-card (click)="playVideo(card.data.id)" *ngIf="card.type == 'video'" [backendUrl]="backendUrl"
            [videoObject]="card.data" [disableClick]="true" style="margin-right: 2rem; max-width: 320px;">
          </app-video-card>
          <app-topic-card (click)="displayPage(card.data.id)" [backendUrl]="backendUrl" *ngIf="card.type == 'topic'"
            [topic_object]="card.data" [disableClick]="true" style="margin-right: 2rem; max-width: 320px;">
          </app-topic-card>
        </ng-container>

        <!--button 1 with absolute position to the left-->
        <button (click)="scrollToLeftRight('featured-content',true);" class="left-right-button left-scroll-button"
          mat-button>
          <mat-icon class="text-white">
            chevron_left
          </mat-icon>
        </button>
        <!--button 2 with absolute position to the left-->
        <button (click)="scrollToLeftRight('featured-content');" class="left-right-button right-scroll-button"
          mat-button>
          <mat-icon class="text-white">
            chevron_right
          </mat-icon>
        </button>
      </div>
    </div>

    <!-- continue watching-->
    <div *ngIf="history_videos.length && !userContentCase && !homeSearch"
      class="border-radius-10 m-3 p-3 bg-primary-555">
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="fontStyle d-flex align-items-center m-0">
          <mat-icon class="material-icons-outlined mr-2" color="primary">history</mat-icon>
          {{'Continue watching'|translate}}
        </h2>
      </div>

      <div class="left-right-div" id="history">
        <app-video-card-history (click)="playVideo(video.video['id'])" *ngFor="let video of history_videos;"
          [backendUrl]="backendUrl" [disableClick]="true" [videoObject]="video"
          style="margin-right: 2rem; max-width: 320px;">
        </app-video-card-history>

        <!--button 1 with absolute position to the left-->
        <button *ngIf="history_videos.length > 4" (click)="scrollToLeftRight('history',true);"
          class="left-right-button left-scroll-button" mat-button>
          <mat-icon class="text-white">
            chevron_left
          </mat-icon>
        </button>

        <!--button 2 with absolute position to the left-->
        <button *ngIf="history_videos.length > 4" (click)="scrollToLeftRight('history');"
          class="left-right-button right-scroll-button" mat-button>
          <mat-icon class="text-white">
            chevron_right
          </mat-icon>
        </button>
      </div>
    </div>

    <!--Latest Clypps -->
    <div *ngIf="latestvideos.length && !userContentCase && !homeSearch" class="border-radius-10 m-3 p-3 bg-primary-555">
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="fontStyle d-flex align-items-center m-0">
          <mat-icon class="material-icons-outlined mr-2" color="primary">play_circle</mat-icon>
          {{'Latest Clypps for you'|translate}}
        </h2>
      </div>

      <!--latest clypps-->
      <div class="left-right-div" id="latest-clypps">

        <app-video-card (click)="playVideo(video['id'])" *ngFor="let video of latestvideos" [backendUrl]="backendUrl"
          [disableClick]="true" [videoObject]="video" style="margin-right: 2rem; max-width: 320px;">
        </app-video-card>
        
          <!--button 1 with absolute position to the left-->
        <button *ngIf="latestvideos.length > 4" (click)="scrollToLeftRight('latest-clypps',true);"
          class="left-right-button left-scroll-button" mat-button>
          <mat-icon class="text-white">
            chevron_left
          </mat-icon>
        </button>
        <!--button 2 with absolute position to the left-->
        <button (click)="scrollToLeftRight('latest-clypps');" class="left-right-button right-scroll-button" mat-button>
          <mat-icon class="text-white">
            chevron_right
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- user content component -->
  <app-user-view *ngIf='userContentCase' (backToInternal)="backToInternal($event)" [isInternalMode]=true
    [userId]="userId"></app-user-view>
  <!-- explore pages -->
  <div *ngIf="!userContentCase && all_topics.length && !homeSearch" class="border-radius-10 m-3 p-3 bg-primary-555">
    <div class="d-flex justify-content-between align-items-center">
      <h2 class="fontStyle d-flex align-items-center m-0">
        <mat-icon class="material-icons-outlined mr-2" color="primary">article</mat-icon>
        {{'Explore pages from others'|translate}}
      </h2>
    </div>
    <div class="d-flex justify-content-start left-right-div" id="newest-topics">
      <app-topic-card (click)="displayPage(topic['id'])" *ngFor="let topic of all_topics;" [backendUrl]="backendUrl"
        [topic_object]="topic" [disableClick]="true" style="margin-right: 2rem; max-width: 320px;">
      </app-topic-card>

      <!--button 1 with absolute position to the left-->
      <button (click)="scrollToLeftRight('newest-topics', true);" class="left-right-button left-scroll-button"
        mat-button>
        <mat-icon class="text-white">
          chevron_left
        </mat-icon>
      </button>

      <!--button 2 with absolute position to the left-->
      <button (click)="scrollToLeftRight('newest-topics');" class="left-right-button right-scroll-button" mat-button>
        <mat-icon class="text-white">
          chevron_right
        </mat-icon>
      </button>
    </div>
  </div>
  <!-- Home Search -->
  <div *ngIf='homeSearch' (scrolled)="onScrollDown()" [fromRoot]="true"
    [infiniteScrollContainer]="'mat-sidenav-content'" [infiniteScrollDistance]="1" [infiniteScrollThrottle]="500"
    [scrollWindow]="false" class="container-fluid" (window:resize)="resize($event.target.innerWidth)" infiniteScroll>
    <div class='row d-flex justify-content-between'>
      <mat-card-title class="align-items-center justify-content-start px-3 mx-3">
        <span class="h4 fontStyle" style="position:relative;top:0.2em;">
          {{n_results}}
          <span *ngIf="n_results == 1">{{'Result'|translate}} for
            {{searchKey}}</span>
          <span *ngIf="n_results !=1">{{'Results'|translate}} for
            {{searchKey}}
          </span>
        </span>
        <button class="border-radius-20" mat-stroked-button (click)="back()">
          <mat-icon class="mr-1">arrow_back</mat-icon>
          <span class="text-nunito font-size-16" translate>Back</span></button>
      </mat-card-title>
      <mat-button-toggle-group (change)="searchTypeChanged()" [(ngModel)]="search_type" aria-label="search type"
        style="border-radius: 0.7rem;height: 2.8rem;right: 0.5rem;" class="align-items-center justify-content-end">
        
        <mat-button-toggle value="Clypps">
          <mat-icon class="material-icons-outlined iconSize">play_circle</mat-icon>
          {{'Clypps'|translate}}
          <span class="text-gray">{{n_video_results}}</span>
        </mat-button-toggle>
        
        <mat-button-toggle value="Pages">
          <mat-icon class="material-icons-outlined iconSize">sticky_note_2</mat-icon>
          {{'Pages'|translate}}
          <span class="text-gray">{{n_topic_results}}</span>
        </mat-button-toggle>

      </mat-button-toggle-group>
    </div>

    <mat-grid-list *ngIf="video_search_results.length" [cols]="cols" [gutterSize]="'1rem'" class="mt-2"
      rowHeight="320px">
      <mat-grid-tile *ngFor="let video of video_search_results" style="box-shadow: none;">
        <app-video-card (click)="playVideo(video['id'])" [backendUrl]="backendUrl" [disableClick]="true"
          [videoObject]="video" class="mx-2"></app-video-card>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list *ngIf="topics_search_results.length" [cols]="cols" [gutterSize]="'1rem'" class="mt-2"
      rowHeight="320px">
      <mat-grid-tile *ngFor="let topic of topics_search_results" style="box-shadow: none;">
        <app-topic-card (click)="displayPage(topic['id'])" [backendUrl]="backendUrl" [topic_object]="topic"
          [disableClick]="true" class="mx-2">
        </app-topic-card>
      </mat-grid-tile>
    </mat-grid-list>

    <div *ngIf="n_results == 0 && !showSpinner" style="display: flex;
    align-items: center;
    height: 200px;
    position: relative;
    justify-content: center;">
      <h3>
        {{'No results. Search in other categories or'|translate}}
        <u (click)="navbarService.openVideoRequestDialog();" class="pointer">
          {{'ask your colleagues'|translate}}
        </u>
      </h3>
    </div>
  </div>
</div>
<!--scroll top button-->
<div *ngIf="mixed_tags_content.length" class="scroll-to-top">
  <button (click)="scrollTop()" mat-fab>
    <mat-icon>vertical_align_top</mat-icon>
  </button>
</div>