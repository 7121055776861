import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { TopicCard, VideoCard } from '../models/video/video.interface';
import { PlaylistCard } from '../models/playlist/playlist';

interface MixedCard {
  type: 'video' | 'topic' | 'playlist';
  data: VideoCard | PlaylistCard | TopicCard;
}
@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent implements OnInit {
  @Input('userId') userId: number;
  @Input('isInternalMode') isInternalMode: boolean;
  @Output() backToInternal = new EventEmitter<boolean>();
  videos: VideoCard[] = [];
  filteredVideos: VideoCard[] = [];
  topics: TopicCard[] = [];
  filteredTopics: TopicCard[] = [];
  backendUrl: string = '';
  id: number = 0;
  userName: string;
  mixed_user_content: MixedCard[] = [];
  filter_option: string = 'All';
  searchText: string = '';
  sort_option: string = 'Newest';
  constructor(public dataService: DataService,
    private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    if (!this.isInternalMode) {
      this.route.params.subscribe(params => {
        this.id = +params['id'];

      });
    }

    if (this.isInternalMode) this.id = this.userId;
    this.getuserVideos();
  }
  getuserVideos() {

    let userUrl = `user/${this.id}/content/`;
    this.dataService.getURL(userUrl, { observe: 'body', responseType: 'json' }).subscribe((resp: any) => {
      this.userName = resp.name;
      this.videos = resp.videos as VideoCard[];;
      this.topics = resp.topics as TopicCard[];
      for (let i of this.videos as VideoCard[]) {
        this.mixed_user_content.push({ type: "video", data: i });
      }

      for (let i of this.topics as TopicCard[]) {
        this.mixed_user_content.push({ type: "topic", data: i });
      }
      this.videos = this.videos.filter(el =>
        Math.round((new Date().getTime() - new Date(el.added_on).getTime()) / (1000 * 60 * 60 * 24)) >= 30
      )
      this.topics = this.topics.filter(el =>
        Math.round((new Date().getTime() - new Date(el.added_on).getTime()) / (1000 * 60 * 60 * 24)) > 30
      )
      this.filteredTopics = this.topics
      this.filteredVideos = this.videos
      this.mixed_user_content = this.mixed_user_content.filter(el =>
        Math.round((new Date().getTime() - new Date(el.data.added_on).getTime()) / (1000 * 60 * 60 * 24)) < 30
      )
      this.mixed_user_content.sort((a, b) =>
        new Date(b.data.added_on).getTime() - new Date(a.data.added_on).getTime());
    }, (err) => {
      window.alert(err.error);
    });


  }
  scrollToLeftRight(id: string, left: boolean = false) {
    const element = document.getElementById(id);
    // element has 2+x children, first and last children are buttons
    // scroll distance = width of each child * number of children visible
    const child_width = element.firstElementChild.clientWidth;
    const viewport_width = element.clientWidth;
    const number_of_visible_children = Math.max(Math.floor(viewport_width / child_width), 1);
    // above number may become 0 for very large zoom levels, hence using max

    let scroll_distance = (child_width + 20) * number_of_visible_children;
    // margin-right is added
    if (left) {
      scroll_distance = -scroll_distance;
    }

    element.scrollBy({
      behavior: "smooth",
      left: scroll_distance,
      top: 0
    });
  }
  applySearchFilter() {
    let videos;
    let pages;
    if (this.filter_option == 'Featured') {
      videos = this.videos.filter(el => el.is_featured);
      pages = this.topics.filter(el => el.is_featured);
    }
    else if (this.filter_option == 'External') {
      videos = this.videos.filter(el => el.is_external)
      pages = this.topics.filter(el => el.is_external)

    }

    else {
      videos = this.videos;
      pages = this.topics
    }
    if (this.searchText != "") {
      videos = videos.filter(el => el.title.toLowerCase().includes(this.searchText.toLowerCase()))
      pages = pages.filter(el => el.title.toLowerCase().includes(this.searchText.toLowerCase()))
    }
    if (this.sort_option == 'Last Edited') {
      videos = videos.sort((a, b) =>
        new Date(b.last_edited_on).getTime() - new Date(a.last_edited_on).getTime());
      pages = pages.sort((a, b) =>
        new Date(b.last_edited_on).getTime() - new Date(a.last_edited_on).getTime());
    }
    else if (this.sort_option == 'Title') {
      videos = videos.sort((a, b) => a.title.localeCompare(b.title));
      pages = pages.sort((a, b) => a.title.localeCompare(b.title));
    }
    else if (this.sort_option == 'Newest') {
      videos = videos.sort((a, b) => new Date(b.data.added_on).getTime() - new Date(a.data.added_on).getTime());
      pages = pages.sort((a, b) => new Date(b.data.added_on).getTime() - new Date(a.data.added_on).getTime());
    }
    else if (this.sort_option == 'Most Viewed') {
      videos = videos.sort((a, b) => b.views - a.views);
      pages = pages.sort((a, b) => b.views - a.views);
    }

    this.filteredVideos = videos;
    this.filteredTopics = pages;
  }
  back() {
    if(this.isInternalMode)
    this.backToInternal.emit(false)
  else
  window.history.back();
  this.searchText = '';
  this.filteredTopics = [];
  this.filteredVideos = []
  this.sort_option = '';
  this.filter_option = '';
  this.mixed_user_content = [];
  }
  
 
}

