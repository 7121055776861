<div *ngIf="inProgress !=false" class="fontNormal loading-overlay">
  <div class="fontNormal overlay-content">
    <mat-spinner [diameter]="40"></mat-spinner>
    <span class="d-none d-lg-block" style="padding-top: -1rem" translate>
      Bitte warten...
    </span>
  </div>
</div>


<div class="d-flex justify-content-between flex-wrap">
  <!--video title-->
  <div class="d-flex flex-column overflow-hidden">
    <mat-card-title class="fontStyle line-height-initial">
      {{ data.title }}
    </mat-card-title>
    <mat-card-subtitle class="fontStyle font-medium">
      {{ 'Original language' | translate }}:&nbsp;{{ languages_dict.get(data.originalLanguage) }}
    </mat-card-subtitle>
  </div>

  <button (click)="closePopup();" mat-icon-button>
    <mat-icon>
      close
    </mat-icon>
  </button>
</div>

<div class="d-flex justify-content-between align-items-center mb-2">
  <!--new language block-->
  <div class="d-flex align-items-baseline">
    <mat-form-field appearance="outline" class="mr-3 mt-2 border-radius-10">
      <mat-label class="text-nunito" translate>Add translation</mat-label>
      <mat-select [(ngModel)]="newLanguage">
        <mat-option *ngFor="let language of add_languages_array" [value]="language[0]">
          {{ language[1] }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button (click)="newLang();" [disabled]="newLanguage == null"
            class="border-radius-10"
            mat-stroked-button>
      <mat-icon class="mr-1">add</mat-icon>
      <span translate>Add</span>
    </button>
  </div>

  <!-- update all translations button-->
  <button (click)="updateAllTranslations();" *ngIf="translations.length"
          [disabled]="is_ai_service_disabled" mat-icon-button
          matTooltip="{{'Update all'|translate}}" matTooltipPosition="left">
    <mat-icon class="text-gray">update</mat-icon>
  </button>
</div>

<div *ngFor="let translation of translations" class="translations-panel">
  <mat-expansion-panel class="mat-elevation-z0 expansion-panel" multi="false">
    <mat-expansion-panel-header class="m-1">
      <mat-panel-title class="fontBold">
        <div class="my-1 d-flex align-items-center">
          <h3 class="mb-0 mr-3" translate>{{ languages_dict.get(translation.language) }}</h3>
        </div>
      </mat-panel-title>
      <mat-panel-description>
        <button (click)="$event.stopPropagation(); delete_or_update(translation.id, true)"
                [disabled]="is_ai_service_disabled"
                mat-icon-button
                matTooltip="{{'Update translation' | translate}}"
                matTooltipPosition="above">
          <mat-icon>update</mat-icon>
        </button>
        <button (click)="$event.stopPropagation(); delete_or_update(translation.id, false)"
                [disabled]="is_ai_service_disabled"
                mat-icon-button
                matTooltip="{{'Delete translation' | translate}}"
                matTooltipPosition="above">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="group-card">
      <mat-tab-group [attr.mat-stretch-tabs]="stretch" class="container-fluid">
        <mat-tab class="tabheight" label="{{'Titel' | translate }} & {{'Beschreibung' | translate}}">
          <div class="row" style="margin-left:0px;margin-right:0px">
            <mat-form-field appearance="outline" floatLabel="always" required style="width: 100%;">
              <mat-label translate>Titel</mat-label>
              <input [(ngModel)]="translation.title" matInput maxlength="100" placeholder="Title*">
            </mat-form-field>
            <h2 translate>Beschreibung</h2>&nbsp;
            <quill-editor #customQuill [(ngModel)]="translation.desc" [styles]="{'border-bottom-left-radius': '5px','border-bottom-right-radius': '5px',
                          'min-height': '150px', 'font-size': 'medium', 'width': '100%'}" class="custom-quill"
                          placeholder="{{'Beschreibung' | translate}}">
              <div quill-editor-toolbar>
                  <span class="ql-formats">
                    <button [title]="'Bold'" class="ql-bold"></button>
                    <button [title]="'Italic'" class="ql-italic"></button>
                    <button [title]="'Underline'" class="ql-underline"></button>
                    <button [title]="'Strike'" class="ql-strike"></button>
                    <button [title]="'Code'" class="ql-code-block"></button>
                    <button class="ql-header" value="1"></button>
                    <button class="ql-header" value="2"></button>
                    <button class="ql-clean"></button>
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                  </span>
              </div>

            </quill-editor>
          </div>
          <div class="footer-row">
            <div class="footer-btn">
              <button (click)="save(translation,0)" [disabled]="!translation.title" color="primary"
                      mat-flat-button>
                {{ 'Speichern'|translate }}
              </button>

              <button (click)="closePopup()" mat-stroked-button>
                {{ 'Cancel'|translate }}
              </button>
            </div>
          </div>
        </mat-tab>
        <mat-tab [disabled]="!translation.vtt_file_data" label="{{ 'Subtitles' | translate }}">
          <div class="row" style="margin-left:0px;margin-right:0px">
            <mat-form-field appearance="outline" style="width: 100%; height:100%;">
              <mat-label translate>WEBVTT</mat-label>
              <textarea [(ngModel)]="translation.vtt_file_data" matInput
                        style="min-height: 300px;resize:none !important; font-family:'nunito';height: 100%"></textarea>
            </mat-form-field>
            <p class="text-nunito text-gray mat-hint">
              <span translate>Please only edit texts, not time stamps.</span><br>
              <span translate>When automatically creating subtitles with AI, there may be wrongly detected phrases.</span>
            </p>
          </div>
          <div class="footer-row">
            <div class="footer-btn mt-0">
              <button (click)="save(translation,1)"
                      [disabled]="!translation.vtt_file_data || getStartOfScript(translation.vtt_file_data)"
                      aria-label="save"
                      color="primary" mat-flat-button>
                {{ 'Speichern'|translate }}
              </button>
              <button (click)="closePopup()" aria-label="cancel" mat-stroked-button>
                {{ 'Cancel'|translate }}
              </button>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-expansion-panel>
</div>
