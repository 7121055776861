<div class="h-100" style="overflow-y: auto; overflow-x: hidden; font-family: 'nunito';" (window:resize)="onResize();">
  <mat-toolbar class="bg-white mat-elevation-z2 position-absolute" style="z-index: 3">
    <!-- the toolbar is now fixed with z-index 3. The seeker has a z-index of 2 -->
    <mat-toolbar-row class="justify-content-between">
      <button (click)="backRecording()" class="m-3 border-radius-20" color="primary" mat-stroked-button>
        <mat-icon class="icon-for-standard-button">arrow_back_ios</mat-icon>
        <span class="d-none d-lg-inline" translate>{{'Record' | translate}}</span>
      </button>

      <!--hide the logo on small screen otherwise buttons are not visible-->
      <img class="inherit-height d-none d-lg-inline cursor-pointer"
           routerLink="/start"
           title="{{'To homepage'|translate}}" alt="Company Logo"
           style="position: absolute; margin: auto; right: 0; left: 0;"
           src="{{authService.company.company_logo}}">

      <div class="d-flex justify-content-between" *ngIf="!isAudioRecordingMode && playerLoaded">
        <!--show these 3 button only if we're not recording audio and player is ready-->
        <!--button is hidden if audio recording or player is not ready-->
        <button (click)="needHelp()" mat-button>
          <mat-icon class="icon-for-standard-button">help_outline</mat-icon>
        </button>

        <button (click)="saveAndReload()" class="border-radius-20" color="primary"
                matTooltip="{{'Save changes' | translate}}" matTooltipPosition="left"
                mat-stroked-button>
          <mat-icon class="icon-for-standard-button material-icons-outlined">cloud_sync</mat-icon>
        </button>

        <button (click)="processVideo()" [disabled]="!playerLoaded" [ngStyle]="playerLoaded ? {} : {'cursor': 'not-allowed'}" class="ml-2 border-radius-20"
                color="primary" mat-flat-button>
          <span class="d-none d-md-inline" translate>{{'Process Clypp' | translate}}</span>
          <mat-icon class="icon-for-standard-button">arrow_forward_ios</mat-icon>
        </button>

      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="w-100 h-50 d-flex" style="border-bottom: 1px solid lightgray; margin-top: 65px;">
    <!-- this has a margin of 65px because the toolbar on top is 64px-->
    <!--this section contains edit and video blocks-->
    <div class="w-50 h-100" style="border-right: 1px solid lightgray;">
      <!--left toolbars-->
      <div *ngIf="isAudioRecordingMode" class="h-100" style="overflow: scroll;">
        <!--if overlay audio being recorded, show notes and exit button-->
        <button (click)="overlayAudioRecordingMode(false);" *ngIf='!is_audio_recording'
                [disabled]="showCountdown" class="m-3" mat-stroked-button>
          <!--cancel button is only visible if I am not recording ov-->
          <mat-icon class="mr-1">mic_off</mat-icon>
          <span translate>Cancel</span>
        </button>

        <!--text area for notes-->
        <mat-form-field appearance="outline" class="w-100 p-3">
          <mat-label translate>Write your script here</mat-label>
          <textarea matInput autofocus [(ngModel)]="script" [rows]="scriptRows"></textarea>
        </mat-form-field>
      </div>

      <div *ngIf="!isAudioRecordingMode" class="h-100">
        <!--if overlay audio not being recorded-->
        <mat-tab-group (selectedIndexChange)="selected_tab.setValue($event)" [selectedIndex]="selected_tab.value"
                       class="h-100">

          <!--audio-->
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="mr-1" matTooltip="(1)" matTooltipPosition="above" color="primary">
                volume_up
              </mat-icon>
              Audio
            </ng-template>

            <div class="stickyHeader pt-3">
              <button mat-stroked-button class="ml-3 border-radius-20" [matMenuTriggerFor]="audioOverlayMenu">
                <mat-icon class="icon-for-standard-button">add_circle_outline</mat-icon>
                {{'Add'|translate}}
              </button>

              <button [matMenuTriggerFor]="audioMoreOptionsMenu" class="ml-2" mat-icon-button>
                <mat-icon>more_horiz</mat-icon>
              </button>

              <mat-menu #audioMoreOptionsMenu="matMenu">
                <!-- this button detaches audio from video, then transcribes it-->
                <button (click)="detachAudioFromVideo();" [disabled]="audio_vol==0" mat-menu-item
                        title="{{'This detaches the original audio from the video and adds it as a new audio overlay.'|translate}}">
                  <mat-icon>call_split</mat-icon>
                  <span translate>Detach audio</span>
                </button>
                <!-- this button opens tts dialog and updates all audios -->
                <button (click)="updateAllAudios();"
                        [disabled]="audios.length == 0 || !authService.company.is_transcription_service_enabled"
                        mat-menu-item>
                  <mat-icon>update</mat-icon>
                  <span translate>Update all</span>
                </button>
                <!-- this button deletes all audio overlays -->
                <button (click)="deleteAllAudios();" [disabled]="audios.length == 0" mat-menu-item>
                  <mat-icon>delete_outline</mat-icon>
                  <span translate>Delete all</span>
                </button>
              </mat-menu>

              <mat-menu #audioOverlayMenu>
                <button mat-menu-item (click)="addTextToSpeech();"
                  [disabled]="!authService.company.is_transcription_service_enabled">
                  <mat-icon class="material-icons-outlined">record_voice_over</mat-icon>
                  <span translate>Text to speech</span>
                </button>
                <!-- this button opens tts dialog and send a patch call to create multiple audios -->
                <button (click)="convertToAIVoice();"
                        [disabled]="audio_vol==0 || !authService.company.is_transcription_service_enabled" mat-menu-item
                        title="{{'Convert your voice into an authentic AI voice and translate the audio track directly.'|translate}}">
                  <mat-icon class="material-icons-outlined">try</mat-icon>
                  <span translate>Convert to AI Voice</span>
                </button>
                <label class="m-0" for="audio-upload" mat-menu-item matTooltip="mp3, wav, opus"
                       matTooltipPosition="right">
                  <mat-icon class="material-icons-outlined">file_upload</mat-icon>
                  <span translate>Upload audio file</span>
                </label>
                <!--only show audio overwrite button if a mic is attached to a computer-->
                <button (click)="overlayAudioRecordingMode(true);" [disabled]="!microphone_attached" mat-menu-item>
                  <mat-icon>mic_none</mat-icon>
                  <span translate>Record audio</span>
                </button>
              </mat-menu>

              <input (change)="uploadAudioFile()" accept=".mp3,.wav,.opus" hidden id="audio-upload" type="file" />

              <!--volume slider-->
              <div class="d-flex flex-wrap justify-content-start">
                <div class="d-flex align-items-center mx-3 my-1">
                  <span class="mr-2 text-nunito" translate>Original audio</span>
                  <mat-icon *ngIf="audio_vol>0.5" class="mr-2 material-icons-outlined">volume_up</mat-icon>
                  <mat-icon *ngIf="audio_vol<=0.5 && audio_vol!=0" class="mr-2 material-icons-outlined">volume_down
                  </mat-icon>
                  <mat-icon *ngIf="audio_vol==0" class="mr-2 material-icons-outlined">volume_mute</mat-icon>
                  <mat-slider (change)="totalAudioVolumeChanged();" [(ngModel)]="audio_vol" max="1" min="0" step="0.1"
                    thumbLabel="true">
                  </mat-slider>
                </div>

                <!-- noise reduction toggle-->
                <div class="mx-3 my-1 d-flex align-items-center">
                  <span translate class="mr-2 text-nunito">Noise reduction</span>
                  <mat-icon class="mr-2" matTooltipPosition="right"
                            matTooltip="{{'Only activate this if you recorded your audio in a noisy surrounding. ' +
                             'Otherwise it may reduce the overall quality.'|translate}}">
                    info_outline
                  </mat-icon>
                  <mat-slide-toggle [(ngModel)]="noise_sup">
                  </mat-slide-toggle>
                </div>
              </div>
            </div>

            <mat-accordion>
              <mat-expansion-panel (closed)="a.expanded = false; a.selected = false;" (opened)="selectAudio(i);"
                                   *ngFor="let a of audios; let i = index;"
                                   [expanded]="a.expanded" class="mt-2 textBox-border mx-3">
                <mat-expansion-panel-header>
                  <mat-panel-title class="align-items-center" style="white-space: nowrap; overflow: hidden;">
                    <mat-spinner [id]="'audio-spinner-'+a.id" diameter="30" hidden class="mr-2"
                                 matTooltip="{{'Transcribing'|translate}}" matTooltipPosition="left" mode="indeterminate"></mat-spinner>
                    <!-- delete audio-->
                    <button (click)="deleteAudio(a.id);" [id]="'audio-controls-'+a.id" mat-icon-button
                            matTooltip="{{'Löschen'|translate}}" matTooltipPosition="above">
                      <mat-icon mat-list-icon>delete_outline</mat-icon>
                    </button>
                    <!--edit audio-->
                    <button (click)="editTextToSpeech(a);"
                            [disabled]="!authService.company.is_transcription_service_enabled"
                            matTooltip="{{'Bearbeiten'|translate}}" matTooltipPosition="above" mat-icon-button>
                      <mat-icon class="material-icons-outlined">edit</mat-icon>
                    </button>
                    {{a.text}}
                  </mat-panel-title>
                  <mat-panel-description class="d-none d-md-flex" style="min-width: 170px;">
                    {{ toHHMMSS(a.start_time) }}&nbsp;-&nbsp;{{ toHHMMSS(a.end_time) }}
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!--this div contains panel items-->
                <div class="d-flex align-items-center">
                  <!--start time input-->
                  <mat-form-field appearance="outline" class="mr-2" hideRequiredMarker style="width: 120px;">
                    <mat-label>Start</mat-label>
                    <input (focusin)="textAreaFocusIn();" (focusout)="textAreaFocusOut();"
                           [value]="toHHMMSS(a.start_time)"
                           (change)="checkAudioTimes(a, $event);"
                           matInput placeholder="00:00:00" required type="text">
                  </mat-form-field>

                  <!--audio volume slider-->
                  <div class="d-flex align-items-center mb-2">
                    <mat-icon *ngIf="a.volume>1" class="material-icons-outlined">campaign</mat-icon>
                    <mat-icon *ngIf="a.volume>0.5 && a.volume<=1" class="material-icons-outlined">volume_up</mat-icon>
                    <mat-icon *ngIf="a.volume<=0.5 && a.volume!=0" class="material-icons-outlined">volume_down
                    </mat-icon>
                    <mat-icon *ngIf="a.volume==0" class="material-icons-outlined">volume_mute</mat-icon>
                    <mat-slider [(ngModel)]="a.volume" (change)="audioVolumeChanged($event, i);"
                                matTooltip="{{'Volume'|translate}}" matTooltipPosition="above"
                                class="ml-2" max="2" min="0" step="0.1" thumbLabel>
                    </mat-slider>
                  </div>

                  <!--split audio-->
                  <button (click)="splitAudio(a);" mat-icon-button matTooltip="{{'Split'|translate}}"
                          matTooltipPosition="above">
                    <mat-icon>content_cut</mat-icon>
                  </button>
                  <!--download audio-->
                  <button (click)="downloadAudio(a);" [disabled]="authService.company?.max_upload_video_height == '720'"
                          mat-icon-button matTooltip="{{'Herunterladen'|translate}}" matTooltipPosition="above">
                    <mat-icon class="material-icons-outlined">download</mat-icon>
                  </button>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </mat-tab>

          <!--blurs-->
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon matTooltip="(2)" matTooltipPosition="above" class="mr-1" color="primary">
                blur_on
              </mat-icon>
              {{'Blurs'|translate}}
            </ng-template>
            <div class="mx-3 mb-3">
              <div class="stickyHeader py-3">
                <!--add blur menu-->
                <button [hidden]="face_and_object_progress!=0" [matMenuTriggerFor]="addBlurMenu"
                        class="border-radius-20" mat-stroked-button>
                  <!--this button is visible by default, hidden while detecting-->
                  <mat-icon class="icon-for-standard-button">add_circle_outline</mat-icon>
                  <span translate>Add</span>
                </button>

                <button [hidden]="face_and_object_progress==0" class="border-radius-20" disabled
                        id="blur-disabled-button" mat-stroked-button>
                  <!--this button is hidden by default, visible while detecting-->
                  <span translate>Detecting…</span>
                  <mat-progress-bar mode="determinate" [value]="face_and_object_progress"></mat-progress-bar>
                </button>

                <!--delete all blurs, this will re-enable face blur buttons -->
                <button mat-icon-button class="ml-2" [matMenuTriggerFor]="blurMoreOptionsMenu">
                  <mat-icon>more_horiz</mat-icon>
                </button>

                <mat-menu #blurMoreOptionsMenu="matMenu">
                  <button (click)="deleteAllBlurs();"
                          [disabled]="blurs.length == 0" mat-menu-item>
                    <mat-icon>delete_outline</mat-icon>
                    <span translate>Delete all</span>
                  </button>
                </mat-menu>

                <mat-menu #addBlurMenu="matMenu">
                  <button (click)="addBlur();" mat-menu-item matTooltip="(b) (v)" matTooltipPosition="right">
                    <mat-icon>crop_16_9</mat-icon>
                    <span translate>Custom box</span>
                  </button>
                  <button (click)="blurFacesOrObjects('face');" mat-menu-item>
                    <mat-icon>face</mat-icon>
                    <span translate>Blur faces</span>
                  </button>
                  <button (click)="blurFacesOrObjects('person');" mat-menu-item>
                    <mat-icon>person_outline</mat-icon>
                    <span translate>Blur persons</span>
                  </button>
                </mat-menu>
              </div>
              <mat-accordion>
                <mat-expansion-panel class="mt-2 textBox-border" *ngFor="let b of blurs; let i = index;"
                                     [id]="'blur-expansion-panel-'+i"
                                     [expanded]="b.selected"
                                     (opened)="selectBlur(i);" (closed)="b.selected = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title style="white-space: nowrap; overflow: hidden;" class="align-items-center">
                      <button mat-icon-button (click)="deleteBlur(i);">
                        <mat-icon mat-list-icon>delete_outline</mat-icon>
                      </button>
                      Blur&nbsp;{{i+1}}
                    </mat-panel-title>
                    <mat-panel-description class="d-none d-md-flex" style="min-width: 170px;">
                      {{toHHMMSS(b.from)}}&nbsp;-&nbsp;{{toHHMMSS(b.to)}}
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <!--this div contains panel items-->
                  <div>
                    <!--start time input-->
                    <mat-form-field appearance="outline" class="mr-2" style="width: 120px;" hideRequiredMarker>
                      <mat-label>Start</mat-label>
                      <input matInput placeholder="00:00:00" [value]="toHHMMSS(b.from)"
                             (focusout)="textAreaFocusOut()" (focusin)="textAreaFocusIn();"
                             (change)="checkTextBoxTimes(b, $event, true);"
                             type="text" required>
                    </mat-form-field>

                    <!--end time input-->
                    <mat-form-field appearance="outline" class="mr-2" style="width: 120px;" hideRequiredMarker>
                      <mat-label translate>End</mat-label>
                      <input matInput placeholder="00:00:00" [value]="toHHMMSS(b.to)"
                             (focusout)="textAreaFocusOut()" (focusin)="textAreaFocusIn();"
                             (change)="checkTextBoxTimes(b, $event, false);"
                             type="text" required>
                    </mat-form-field>

                    <mat-slider [(ngModel)]="b.blur"
                                matTooltip="{{'Blur strength'|translate}}" matTooltipPosition="above"
                                aria-label="Blur strength" max="40"
                                min="5" step="1">
                    </mat-slider>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </mat-tab>

          <!--Texts-->
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon matTooltip="(3)" matTooltipPosition="above" class="mr-1" color="primary">
                text_fields
              </mat-icon>
              {{'Texts'|translate}}
            </ng-template>
            <div class="mx-3 mb-3">
              <div class="stickyHeader py-3">
                <button (click)="addText();"
                        class="border-radius-20"
                        mat-stroked-button matTooltip="(t)" matTooltipPosition="right">
                  <mat-icon class="icon-for-standard-button">add_circle_outline</mat-icon>
                  {{'Add'|translate}}
                </button>
              </div>

              <mat-accordion>
                <mat-expansion-panel class="mt-2 textBox-border" *ngFor="let t of texts; let i = index;" [expanded]="t.selected"
                                     (opened)="t.selected = true" (closed)="t.selected = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title style="white-space: nowrap; overflow: hidden;" class="align-items-center">
                      <button mat-icon-button (click)="deleteText(i);">
                        <mat-icon mat-list-icon>delete_outline</mat-icon>
                      </button>
                      {{t.text}}
                    </mat-panel-title>
                    <mat-panel-description class="d-none d-md-flex" style="min-width: 170px;">
                      {{toHHMMSS(t.from)}}&nbsp;-&nbsp;{{toHHMMSS(t.to)}}
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <!--this div contains panel items-->
                  <div>
                    <!--font size-->
                    <mat-form-field appearance="outline" class="mr-2"
                                    style="width: 120px;" aria-label="font size">
                      <mat-label>{{'Font Size'|translate}}</mat-label>
                      <mat-select [(ngModel)]="t.fontsize"
                                  (selectionChange)="sessionStorage.setItem('fontsize', t.fontsize);">
                        <mat-option value="small">{{'Small'|translate}}</mat-option>
                        <mat-option value="medium">{{'Medium'|translate}}</mat-option>
                        <mat-option value="large">{{'Large'|translate}}</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <!--text color-->
                    <!-- https://zefoy.github.io/ngx-color-picker/ -->
                    <mat-form-field class="mr-2" style="max-width: 120px;" appearance="outline">
                      <mat-label>{{ 'Font Color'|translate }}</mat-label>
                      <input (click)="$event.preventDefault();"
                             (colorPickerOpen)="textAreaFocusIn();"
                             (colorPickerClose)="textAreaFocusOut();"
                             (colorPickerChange)="sessionStorage.setItem('fontcolor', $event)"
                             (cpPresetColorsChange)="saveColor($event);"
                             [(colorPicker)]="t.fontcolor"
                             [(ngModel)]="t.fontcolor"
                             [cpEyeDropper]="true"
                             [cpAlphaChannel]="'disabled'"
                             [cpOutputFormat]="'hex'"
                             [cpPositionOffset]="'-650%'"
                             [cpAddColorButton]="true"
                             [cpPresetColors]="presetColorArray"
                             [cpMaxPresetColorsLength]="6"
                             [cpAddColorButtonText]="'Save color'|translate"
                             [cpPresetLabel]="'Saved colors'|translate"
                             matInput
                             readonly
                             type="color">
                    </mat-form-field>

                    <!--box enabled-->
                    <mat-slide-toggle [(ngModel)]="t.box"
                                      (toggleChange)="sessionStorage.setItem('box', t.box ? '0' : '1');"
                                      class="mr-2" style="width: 180px; height: 80px;">
                      {{'Colored Background'|translate}}
                    </mat-slide-toggle>

                    <!--box color if enabled-->
                    <mat-form-field *ngIf="t.box" style="max-width: 120px; height: 80px;"
                                    appearance="outline">
                      <mat-label>{{'Background Color'|translate}}</mat-label>
                      <input (click)="$event.preventDefault();"
                             (colorPickerOpen)="textAreaFocusIn();"
                             (colorPickerClose)="textAreaFocusOut();"
                             (colorPickerChange)="sessionStorage.setItem('boxcolor', $event)"
                             (cpPresetColorsChange)="saveColor($event);"
                             [(colorPicker)]="t.boxcolor"
                             [(ngModel)]="t.boxcolor"
                             [cpEyeDropper]="true"
                             [cpAlphaChannel]="'disabled'"
                             [cpOutputFormat]="'hex'"
                             [cpPositionOffset]="'-650%'"
                             [cpAddColorButton]="true"
                             [cpPresetColors]="presetColorArray"
                             [cpPosition]="'bottom-left'"
                             [cpMaxPresetColorsLength]="6"
                             [cpAddColorButtonText]="'Save color'|translate"
                             [cpPresetLabel]="'Saved colors'|translate"
                             matInput
                             readonly
                             type="color">
                    </mat-form-field>

                    <!--text input-->
                    <mat-form-field appearance="outline" class="w-100"
                                    (focusout)="textAreaFocusOut()" (focusin)="textAreaFocusIn();">
                      <mat-label>Text</mat-label>
                      <textarea matInput placeholder="Title text here" [(ngModel)]="t.text"
                        rows="4" required></textarea>
                    </mat-form-field>

                    <!--start time input-->
                    <mat-form-field appearance="outline" class="mr-2" style="width: 120px;" hideRequiredMarker>
                      <mat-label>Start</mat-label>
                      <input matInput placeholder="00:00:00" [value]="toHHMMSS(t.from)"
                             (focusout)="textAreaFocusOut()" (focusin)="textAreaFocusIn();"
                             (change)="checkTextBoxTimes(t, $event, true);"
                             type="text" required>
                    </mat-form-field>

                    <!--end time input-->
                    <mat-form-field appearance="outline" style="width: 120px;" hideRequiredMarker>
                      <mat-label translate>End</mat-label>
                      <input matInput placeholder="00:00:00" [value]="toHHMMSS(t.to)"
                             (focusout)="textAreaFocusOut()" (focusin)="textAreaFocusIn();"
                             (change)="checkTextBoxTimes(t, $event, false);"
                             type="text" required>
                    </mat-form-field>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </mat-tab>

          <!--Zooms-->
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon matTooltip="(4)" matTooltipPosition="above" class="mr-1" color="primary">
                center_focus_weak
              </mat-icon>
              Zoom
            </ng-template>

            <div class="mx-3 mb-3">
              <div class="stickyHeader py-3">
                <button (click)="addZoomPan();"
                        class="border-radius-20"
                        mat-stroked-button matTooltip="(z)" matTooltipPosition="below">
                  <mat-icon class="icon-for-standard-button">add_circle_outline</mat-icon>
                  {{'Add'|translate}}
                </button>
                <mat-icon class="ml-2 material-icons-outlined" style="vertical-align: middle">
                  info
                </mat-icon>
                <span class="text-nunito" style="vertical-align: middle" translate>
                  Zooms should not overlap
                </span>
              </div>
              <mat-accordion>
                <mat-expansion-panel class="mt-2 textBox-border" *ngFor="let z of zoomPans; let i = index;" [expanded]="z.selected"
                                     (opened)="selectZoomPan(i);" (closed)="z.selected = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title style="white-space: nowrap; overflow: hidden;" class="align-items-center">
                      <button mat-icon-button (click)="deleteZoomPan(i);">
                        <mat-icon mat-list-icon>delete_outline</mat-icon>
                      </button>
                      Zoom&nbsp;{{i+1}}
                    </mat-panel-title>
                    <mat-panel-description class="d-none d-md-flex" style="min-width: 170px;">
                      {{toHHMMSS(z.data[0])}}&nbsp;-&nbsp;{{toHHMMSS(z.data[1])}}
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <!--this div contains panel items-->
                  <div>
                    <!--zoom factor slider-->
                    <div class="d-flex align-items-center mb-2">
                      <span class="mr-2 text-nunito" translate>Zoom factor</span>
                      <mat-icon *ngIf="z.data[4]==1" class="mr-2 material-icons-outlined">
                        crop_free
                      </mat-icon>
                      <mat-icon *ngIf="z.data[4]>1 && z.data[4]<=2" class="mr-2 material-icons-outlined">
                        center_focus_strong
                      </mat-icon>
                      <mat-icon *ngIf="z.data[4]>2" class="mr-2 material-icons-outlined">
                        center_focus_weak
                      </mat-icon>
                      <mat-slider [(ngModel)]="z.data[4]"
                                  max="3" min="1.05" step="0.05" thumbLabel>
                      </mat-slider>
                    </div>

                    <!--start time input-->
                    <mat-form-field appearance="outline" class="mr-2" style="width: 120px;" hideRequiredMarker>
                      <mat-label>Start</mat-label>
                      <input matInput placeholder="00:00:00" [value]="toHHMMSS(z.data[0])"
                             (focusout)="textAreaFocusOut()" (focusin)="textAreaFocusIn();"
                             (change)="checkZoomPanTimes(z, $event, true);"
                             type="text" required>
                    </mat-form-field>

                    <!--end time input-->
                    <mat-form-field appearance="outline" class="mr-2" style="width: 120px;" hideRequiredMarker>
                      <mat-label translate>End</mat-label>
                      <input matInput placeholder="00:00:00" [value]="toHHMMSS(z.data[1])"
                             (focusout)="textAreaFocusOut()" (focusin)="textAreaFocusIn();"
                             (change)="checkZoomPanTimes(z, $event, false);"
                             type="text" required>
                    </mat-form-field>

                    <!--transition enabled-->
                    <mat-slide-toggle [(ngModel)]="z.data[5]"
                                      matTooltipPosition="above"
                                      matTooltip="{{'If enabled, the video transitions smoothly in and out of the zoomed portion'|translate}}"
                                      class="mr-2" style="width: 180px; height: 80px;">
                      {{'Transition'|translate}}
                    </mat-slide-toggle>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </mat-tab>

          <!--Draw boxes-->
          <!--Images-->
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon matTooltip="(5)" matTooltipPosition="above" class="mr-1" color="primary">
                check_box_outline_blank
              </mat-icon>
              {{'Shapes'|translate}}
            </ng-template>

            <div class="mx-3 mb-3">
              <div class="stickyHeader py-3">
                <button mat-stroked-button class="ml-3 border-radius-20" [matMenuTriggerFor]="imageOverlayMenu">
                <mat-icon class="icon-for-standard-button">add_circle_outline</mat-icon>
                {{'Add'|translate}}
              </button>

              <mat-menu #imageOverlayMenu>
                <button mat-menu-item (click)="addDrawBox();">
                  <span translate>Box</span>
                </button>
                <button mat-menu-item [matMenuTriggerFor]="shapes">
                  <span translate>Shapes</span>
                </button>
                <button mat-menu-item [matMenuTriggerFor]="arrows">
                  <span translate>Arrows</span>
                </button>
                <label mat-menu-item for="image-upload" class="m-0" matTooltip="jpg, png, gif, webp"
                       matTooltipPosition="right">
                  <span translate>Hochladen</span>
                </label>
              </mat-menu>

              <mat-menu #shapes="matMenu">
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/done.gif')">
                  <img src="../assets/overlays/done.gif" style="height: 20px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/oval.gif')">
                  <img src="../assets/overlays/oval.gif" style="height: 25px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/rectangle.gif')">
                  <img src="../assets/overlays/rectangle.gif" style="height: 25px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/exclamation.gif')">
                  <img src="../assets/overlays/exclamation.gif" style="height: 20px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/cross.gif')">
                  <img src="../assets/overlays/cross.gif" style="height: 20px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/line.gif')">
                  <img src="../assets/overlays/line.gif" style="height: 20px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/dotted.gif')">
                  <img src="../assets/overlays/dotted.gif" style="height: 20px;">
                </button>

              </mat-menu>

              <mat-menu #arrows="matMenu">
                <!--black static-->
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/left-arrow.png')">
                  <img src="../assets/overlays/left-arrow.png" style="height: 20px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/right-arrow.png')">
                  <img src="../assets/overlays/right-arrow.png" style="height: 20px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/down-arrow.png')">
                  <img src="../assets/overlays/down-arrow.png" style="height: 20px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/up-arrow.png')">
                  <img src="../assets/overlays/up-arrow.png" style="height: 20px;">
                </button>
                <!--blue gift-->
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/N.gif')">
                  <img src="../assets/overlays/N.gif" style="height: 20px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/NE.gif')">
                  <img src="../assets/overlays/NE.gif" style="height: 20px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/E.gif')">
                  <img src="../assets/overlays/E.gif" style="height: 20px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/SE.gif')">
                  <img src="../assets/overlays/SE.gif" style="height: 20px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/S.gif')">
                  <img src="../assets/overlays/S.gif" style="height: 20px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/SW.gif')">
                  <img src="../assets/overlays/SW.gif" style="height: 20px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/W.gif')">
                  <img src="../assets/overlays/W.gif" style="height: 20px;">
                </button>
                <button mat-menu-item (click)="shapeSelected('../assets/overlays/NW.gif')">
                  <img src="../assets/overlays/NW.gif" style="height: 20px;">
                </button>
              </mat-menu>

              <input (change)="uploadImageFile()" accept=".jpg,.jpeg,.png,.gif,.webp"
                     hidden id="image-upload" type="file" />
              </div>
              <mat-accordion>
                <mat-expansion-panel class="mt-2 textBox-border" *ngFor="let d of drawBoxes; let i = index;" [expanded]="d.selected"
                                     (opened)="selectDrawBox(i);" (closed)="d.selected = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title style="white-space: nowrap; overflow: hidden;" class="align-items-center">
                      <button mat-icon-button (click)="deleteDrawBox(i);">
                        <mat-icon mat-list-icon>delete_outline</mat-icon>
                      </button>
                      Box&nbsp;{{i+1}}
                    </mat-panel-title>
                    <mat-panel-description class="d-none d-md-flex" style="min-width: 170px;">
                      {{toHHMMSS(d.data[0])}}&nbsp;-&nbsp;{{toHHMMSS(d.data[1])}}
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <!--this div contains panel items-->
                  <div class="d-flex flex-column">
                    <!--We need start time, end time, color & thickness-->
                    <!--color picker-->
                    <mat-form-field style="max-width: 120px;" appearance="outline">
                      <mat-label>{{ 'Box Color'|translate }}</mat-label>
                      <input (click)="$event.preventDefault();"
                             (colorPickerOpen)="textAreaFocusIn();"
                             (colorPickerClose)="textAreaFocusOut();"
                             (colorPickerChange)="sessionStorage.setItem('drawBoxColor', d.data[6])"
                             (cpPresetColorsChange)="saveColor($event);"
                             [(colorPicker)]="d.data[6]"
                             [(ngModel)]="d.data[6]"
                             [cpEyeDropper]="true"
                             [cpAlphaChannel]="'disabled'"
                             [cpOutputFormat]="'hex'"
                             [cpPositionOffset]="'-650%'"
                             [cpAddColorButton]="true"
                             [cpPresetColors]="presetColorArray"
                             [cpMaxPresetColorsLength]="6"
                             [cpAddColorButtonText]="'Save color'|translate"
                             [cpPresetLabel]="'Saved colors'|translate"
                             matInput
                             readonly
                             type="color">
                    </mat-form-field>

                    <!--start time input-->
                    <mat-form-field appearance="outline" style="width: 120px;" hideRequiredMarker>
                      <mat-label>Start</mat-label>
                      <input matInput placeholder="00:00:00" [value]="toHHMMSS(d.data[0])"
                             (focusout)="textAreaFocusOut()" (focusin)="textAreaFocusIn();"
                             (change)="checkZoomPanTimes(d, $event, true);"
                             type="text" required>
                    </mat-form-field>

                    <!--end time input-->
                    <mat-form-field appearance="outline" style="width: 120px;" hideRequiredMarker>
                      <mat-label translate>End</mat-label>
                      <input matInput placeholder="00:00:00" [value]="toHHMMSS(d.data[1])"
                             (focusout)="textAreaFocusOut()" (focusin)="textAreaFocusIn();"
                             (change)="checkZoomPanTimes(d, $event, false);"
                             type="text" required>
                    </mat-form-field>

                    <!--thickness-->
                    <mat-form-field appearance="outline" style="width: 120px;" aria-label="font size">
                      <mat-label>{{'Thickness'|translate}}</mat-label>
                      <mat-select [(ngModel)]="d.data[8]">
                        <mat-option [value]=5>{{'Small'|translate}}</mat-option>
                        <mat-option [value]=10>{{'Medium'|translate}}</mat-option>
                        <mat-option [value]=20>{{'Large'|translate}}</mat-option>
                        <mat-option value='fill'>{{'Fill'|translate}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </mat-expansion-panel>

                <mat-expansion-panel (closed)="im.selected = false" (opened)="selectImage(i);"
                                     *ngFor="let im of images; let i = index;"
                                     [expanded]="im.selected"
                                     class="mt-2 textBox-border">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="align-items-center" style="white-space: nowrap; overflow: hidden;">
                      <button (click)="deleteImage(i);" mat-icon-button>
                        <mat-icon mat-list-icon>delete_outline</mat-icon>
                      </button>
                      {{ getAttachedFilename(im.image_file) }}
                    </mat-panel-title>
                    <mat-panel-description class="d-none d-sm-flex">
                      {{ toHHMMSS(im.from) }}&nbsp;-&nbsp;{{ toHHMMSS(im.to) }}
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <!--this div contains panel items-->
                  <div>
                    <!--start time input-->
                    <mat-form-field appearance="outline" class="mr-2" hideRequiredMarker style="width: 120px;">
                      <mat-label>Start</mat-label>
                      <input matInput placeholder="00:00:00" [value]="toHHMMSS(im.from)"
                             (input)="im.changed=true"
                             (focusout)="textAreaFocusOut()" (focusin)="textAreaFocusIn();"
                             (change)="checkTextBoxTimes(im, $event, true);"
                             type="text" required>
                    </mat-form-field>

                    <!--end time input-->
                    <mat-form-field appearance="outline" class="mr-2" hideRequiredMarker style="width: 120px;">
                      <mat-label translate>End</mat-label>
                      <input matInput placeholder="00:00:00" [value]="toHHMMSS(im.to)"
                             (input)="im.changed=true"
                             (focusout)="textAreaFocusOut()" (focusin)="textAreaFocusIn();"
                             (change)="checkTextBoxTimes(im, $event, false);"
                             type="text" required>
                    </mat-form-field>

                    <!--reset aspect ratio-->
                    <button mat-icon-button
                            matTooltip="{{'Reset'|translate}}" matTooltipPosition="right"
                            (click)="resetImageOverlayAspectRatio(i);">
                      <mat-icon>aspect_ratio</mat-icon>
                    </button>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>

    </div>
    <div class="w-50 h-100 d-flex justify-content-center align-items-center position-relative">
      <!--right video-->
      <!--since resize method is called during full screen, disable confirm dialog-->
      <video #videoPlayer
             (loadeddata)="dataLoaded($event)"
             (loadedmetadata)="onPlayerReady($event)"
             (timeupdate)="timeUpdated()"
             (ended)="videoEnded();"
             (fullscreenchange)="confirmDialog?.close(false);"
             [src]="videoURL"
             [volume]="audio_vol" class="video-player"
             crossorigin="anonymous" playsinline
             preload="auto" type="video/mp4">
      </video>

      <div id="text-blur-div" class="text-blur-boundary" style="position: absolute; overflow: hidden;">
        <div *ngIf="showCountdown"
             class="countdownval w-100 h-100 d-flex justify-content-center align-items-center">
          {{timeLeft}}
        </div>

        <!--Zooms-->
        <div>
          <div *ngFor="let z of zoomPans; let i = index;">
            <div (cdkDragEnded)="zoomBoxDropped(i);"
                 (click)="selectZoomPan(i);"
                 [cdkDragFreeDragPosition]="{x: z.data[2]/videoUpscaleWidthFactor, y: z.data[3]/videoUpscaleHeightFactor}"
                 [id]="'zoom-box-'+i"
                 [style.display]="player.currentTime>= z.data[0] && player.currentTime<=z.data[1] ? 'block': 'none'"
                 [style.width]="100/z.data[4]+'%'"
                 cdkDrag
                 cdkDragBoundary=".text-blur-boundary"
                 class="position-absolute"
                 style="aspect-ratio: 16/9; border: 3px solid pink; cursor: move; box-shadow: 0px 0px 0px 50vw;"
            >
            </div>
          </div>
        </div>

        <!--Blurs-->
        <div *ngFor="let i of blurs; let index = index;">
          <!--bottom div has a backdrop filter, which is 0.3 pixel times the backend value-->
          <div cdkDragBoundary=".text-blur-boundary" cdkDrag class="blur-box"
               [id]="'blur-box-'+index"
               (click)="selectBlur(index);"
               (cdkDragEnded)="blurBoxDropped(index);"
               [style.backdrop-filter]="'blur('+0.3*i.blur+'px)'"
               [style.height]="i.height/videoUpscaleHeightFactor+'px'" [style.width]="i.width/videoUpscaleWidthFactor+'px'"
               [style.display]="player.currentTime>= i.from && player.currentTime<=i.to ? 'block': 'none'"
               [style.border]="i.selected ? '1px solid orange': 'none'"
               [ngStyle]="{'top': i.prev_y/videoUpscaleHeightFactor+'px', 'left': i.prev_x/videoUpscaleWidthFactor+'px'}"
          >
            <mat-icon class="resize-handle" *ngIf="i.selected"
                      [id]="'resize-icon-'+index"
                      matTooltip="{{'Resize'|translate}}" matTooltipPosition="right"
                      cdkDragBoundary=".text-blur-boundary"
                      (cdkDragMoved)="blurBoxResized($event, index);"
                      cdkDrag>
              south_east
            </mat-icon>
          </div>
        </div>

        <!--Images-->
        <div *ngFor="let im of images; let index = index;">
          <!--bottom div has a backdrop filter, which is 0.3 pixel times the backend value-->
          <div cdkDragBoundary=".text-blur-boundary" cdkDrag class="blur-box"
               style="background-size: 100% 100%;"
               [id]="'image-overlay-'+index"
               (click)="selectImage(index);"
               (cdkDragEnded)="imageOverlayDropped(index);"
               [style.background-image]="'url(' + im.image_file + ')'"
               [style.height]="im.height/videoUpscaleHeightFactor+'px'" [style.width]="im.width/videoUpscaleWidthFactor+'px'"
               [style.display]="player.currentTime>= im.from && player.currentTime<=im.to ? 'block': 'none'"
               [style.border]="im.selected ? '1px solid #2689A8': 'none'"
               [ngStyle]="{'top': im.prev_y/videoUpscaleHeightFactor+'px', 'left': im.prev_x/videoUpscaleWidthFactor+'px'}"
          >
            <mat-icon class="resize-handle" *ngIf="im.selected"
                      [id]="'im-ov-resize-icon-'+index"
                      matTooltip="{{'Resize'|translate}}" matTooltipPosition="right"
                      cdkDragBoundary=".text-blur-boundary"
                      (cdkDragMoved)="ImageOverlayResized($event, index);"
                      cdkDrag>
              south_east
            </mat-icon>
          </div>
        </div>

        <!--draw boxes-->
        <div>
          <div *ngFor="let d of drawBoxes; let i = index;">
            <div (cdkDragEnded)="drawBoxDropped(i);"
                 (click)="selectDrawBox(i);"
                 [cdkDragFreeDragPosition]="{x: d.data[2]/videoUpscaleWidthFactor, y: d.data[3]/videoUpscaleHeightFactor}"
                 [id]="'draw-box-'+i"
                 [style.display]="player.currentTime>= d.data[0] && player.currentTime<=d.data[1] ? 'block': 'none'"
                 [style.width]="d.data[4]/videoUpscaleWidthFactor+'px'"
                 [style.height]="d.data[5]/videoUpscaleHeightFactor+'px'"
                 [style.border-color]="d.data[6]"
                 [style.border-width]="d.data[8]/videoUpscaleWidthFactor+'px'"
                 [style.background-color]="d.data[8] == 'fill' ? d.data[6] : 'unset'"
                 cdkDrag
                 cdkDragBoundary=".text-blur-boundary"
                 class="position-absolute"
                 style="border-style: solid; cursor: move;"
            >
              <mat-icon class="resize-handle" *ngIf="d.selected"
                      [id]="'draw-box-resize-icon-'+i"
                      matTooltip="{{'Resize'|translate}}" matTooltipPosition="right"
                      cdkDragBoundary=".text-blur-boundary"
                      (cdkDragMoved)="drawBoxResized($event, i);"
                      cdkDrag>
                south_east
              </mat-icon>
            </div>
          </div>
        </div>

        <!--Texts-->
        <div *ngFor="let i of texts; let index = index;">
          <div cdkDragBoundary=".text-blur-boundary" cdkDrag cdkDragHandle class="text-box"
               [style.font-family]="'companyFont'"
               [id]="'text-box-'+index"
               (click)="selectText(index);"
               (cdkDragEnded)="textBoxDropped(index);"
               [style.display]="player.currentTime>= i.from && player.currentTime<=i.to ? 'block': 'none'"
               [style.background-color]="i.box ? i.boxcolor : 'transparent'"
               [style.color]="i.fontcolor"
               [style.max-width]="player.clientWidth+'px'"
               [style.max-height]="player.clientHeight+'px'"
               [style.font-size]="font_size_dict.get(i.fontsize)"
               [ngStyle]="{'top': i.prev_y/videoUpscaleHeightFactor+'px', 'left': i.prev_x/videoUpscaleWidthFactor+'px'}"
          >
            <span style="width: 100%;height: 100%;line-height: 0.9;">{{i.text}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-100">
    <mat-grid-list cols="10" rowHeight="50px">
      <!-- split, reset, delete, restore -->
      <mat-grid-tile colspan="4" rowspan="1" *ngIf="selected_tile_index == -1">
        <!--empty tile to align buttons on the right-->
      </mat-grid-tile>
      <mat-grid-tile class="grid-tile-content-left-align" colspan="4" rowspan="1" *ngIf="selected_tile_index > -1">
        <button (click)="splitVideo()" *ngIf='!isAudioRecordingMode' mat-button
                matTooltip="(c)" matTooltipPosition="above">
          <mat-icon>content_cut</mat-icon>
          <span class="d-none d-sm-inline text-nunito text-gray ml-2" translate>Split</span>
        </button>

        <button (click)="deleteSelectedSection()" *ngIf='tiles[selected_tile_index].active && !isAudioRecordingMode' mat-button
                matTooltip="(d)" matTooltipPosition="above">
          <mat-icon>delete_outline</mat-icon>
          <span class="d-none d-sm-inline text-nunito text-gray ml-2" translate>Löschen</span>
        </button>

        <button (click)="restoreSelectedSection()" *ngIf='!(tiles[selected_tile_index].active || isAudioRecordingMode)' mat-button
                matTooltip="(r)" matTooltipPosition="above">
          <mat-icon>replay</mat-icon>
          <span class="d-none d-sm-inline text-nunito text-gray ml-2" translate>Wiederherstellen</span>
        </button>

        <!--more menu-->
        <button mat-icon-button [matMenuTriggerFor]="moreMenu" [disabled]="isAudioRecordingMode">
          <mat-icon>more_horiz</mat-icon>
        </button>

        <mat-menu #moreMenu="matMenu">
          <!--reset trimmer-->
          <button (click)="resetTrimmer()" mat-menu-item
                  matTooltip="(o)" matTooltipPosition="right">
            <mat-icon>cached</mat-icon>
            <span translate>Reset</span>
          </button>

          <button (click)="undoTileAction()" [disabled]='tiles_history.length == 0' mat-menu-item
                  matTooltip="(u)" matTooltipPosition="right">
            <mat-icon>undo</mat-icon>
            <span translate>Undo</span>
          </button>

          <button (click)="deleteForever()" mat-menu-item
                  title="{{'Gray elements in the timeline will be removed forever and cannot be reset.'|translate}}">
            <mat-icon>remove_circle_outline</mat-icon>
            <span translate>Delete forever</span>
          </button>
        </mat-menu>
      </mat-grid-tile>

      <!-- timer -->
      <mat-grid-tile colspan="2" rowspan="1">
        <span #currentTimeSpan
              [hidden]="is_user_editing_current_time"
              (click)="editCurrentTime();"
              title="{{'Bearbeiten'|translate}}"
              class="text-nunito text-gray cursor-pointer"
              style="font-size: large;">
        </span>
        <!--user input for changing time-->
        <mat-form-field appearance="outline"
                        *ngIf="is_user_editing_current_time"
                        style="width: 100px;">
          <input (change)="setCurrentTime($event);"
                 (focusout)="textAreaFocusOut();"
                 [value]="toHHMMSS(currentTempTime)"
                 class="text-center"
                 id="time-input"
                 disableAutofill
                 matInput maxlength="11" minlength="8" placeholder="00:00:00" required type="text">
        </mat-form-field>
      </mat-grid-tile>

      <!-- video player control -->
      <mat-grid-tile *ngIf="!isAudioRecordingMode" colspan="3" rowspan="1">
        <button (click)="backwardVideo()" class="d-none d-sm-inline" mat-icon-button
                title="{{'Backward'|translate}}"
                matTooltip="(←)" matTooltipPosition="above">
          <mat-icon>replay_5</mat-icon>
        </button>
        <button (click)="playVideo();" *ngIf="player?.paused" mat-icon-button
                matTooltip="(space)" matTooltipPosition="above">
          <mat-icon style="transform: scale(1.7);">
            play_arrow
          </mat-icon>
        </button>

        <button (click)="pauseVideo();" *ngIf="!player?.paused" mat-icon-button>
          <mat-icon style="transform: scale(1.7);">
            pause
          </mat-icon>
        </button>

        <button (click)="forwardVideo()" class="d-none d-sm-inline" mat-icon-button
                title="{{'Forward'|translate}}"
                matTooltip="(→)" matTooltipPosition="above">
          <mat-icon>forward_5</mat-icon>
        </button>
      </mat-grid-tile>

      <!-- audio overlay control -->
      <mat-grid-tile *ngIf="isAudioRecordingMode" colspan="3" rowspan="1">
        <!-- start audio recording -->
        <button (click)="startAudioRecording();" *ngIf="!is_audio_recording"
                [disabled]="showCountdown" mat-icon-button
                matTooltip="{{'Audioaufnahme starten' | translate}}" matTooltipPosition="above">
          <mat-icon>
            radio_button_checked
          </mat-icon>
        </button>

        <!-- stop button -->
        <button (click)="stopAudioRecording()" *ngIf="is_audio_recording"
                [disabled]="showCountdown" mat-icon-button
                matTooltip="{{'Audioaufnahme beenden' | translate}}" matTooltipPosition="above">
          <mat-icon>
            stop_circle
          </mat-icon>
        </button>

        <!--mic selector and progress meter-->
        <mat-form-field appearance="outline" class="mt-1 d-none d-md-inline">
          <mat-select [(ngModel)]="mic_source" [disabled]="is_audio_recording" placeholder="Select a microphone">
            <mat-option *ngFor="let mic of mics; let index = index;" [value]="mic.deviceId">
              <!--In some cases, label is not provided by the browser, so we user text like "Microphone 1"-->
              {{mic.label || ("Microphone" | translate) + " " + (index+1) }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <canvas #meter *ngIf="!disableAudio || true" class="ml-1" [height]="HEIGHT" [width]="WIDTH"
                style="transform: rotate(180deg);">
        </canvas>
      </mat-grid-tile>

      <!-- record overlay audio, cancel, delete, full screen -->
      <mat-grid-tile colspan="1" rowspan="1">
        <!--button to change width of timeline-->
        <!--        todo: un-hide it-->
        <button (click)="toggleTimelineZoom()" mat-icon-button hidden>
          <mat-icon>{{zoom_mode}}</mat-icon>
        </button>

        <button (click)="fullscreen()"
                [disabled]="isAudioRecordingMode"
                mat-icon-button matTooltip="(f)" matTooltipPosition="above">
          <mat-icon style="transform: scale(1.5);">fullscreen</mat-icon>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div class="m-3" id="timeline-parent-div" style="overflow-x: hidden;">
    <div class="m-3" id="timeline-child-div">
      <!-- input: always called when user is sliding it -->
      <!-- change: only called after user is done sliding it -->
      <!-- if you increase the seeker z-index, then the toolbar's index should also be increased -->
      <mat-slider (input)="onChangeSeekerTime($event.value);"
                  [(ngModel)]="seekerValue" [disabled]='is_audio_recording'
                  style="z-index: 2;"
                  [max]="videoDuration*100" class="player-seeker w-100 p-0" min="0" step="0.01">
      </mat-slider>

      <!-- timeline with photos using div-->
      <div class="w-100 mt-2" style="background-color: aliceblue;">
        <!--timeline-->
        <div #timelineRef style="display: flex; height: 60px; overflow: hidden;">
          <!-- if there is no filmstrip, the tag below would be deleted -->
          <img [src]="filmstrip_src" class="w-fill-available"
               id="filmstrip-img"
               onerror="this.onerror=null; this.src='assets/images/Topics_Cover_Image.png'"
               style="height: fit-content;">
        </div>

        <!--audio-->
        <!--show only when audio is enabled-->
        <div id="initialAudioWaveForm" [style.scale]="'1 ' + audio_vol" class="overflow-hidden">
          <!--update the scale as per audio volume-->
        </div>

        <!--splits-->
        <!--instead of mat-grid-list, we'll use divs as columns can not have decimals but %age could be-->
        <!--it has height outside the class because we want to make it visible to the developer-->
        <!--it has class d-flex, justify & center to show the zoom icon in the middle-->
        <div class="w-100 d-flex" style="margin-top: -92px;">
          <div (click)="tileSelected(i)" *ngFor="let tile of tiles; let i = index;"
               class="timeline-tile d-flex justify-content-center align-items-center"
               [style.background]="tile.active ? 'transparent' : 'rgba(0, 0, 0, 0.4)'"
               [style.border]="i == selected_tile_index ? '4px solid coral !important' : '3px solid aliceblue'"
               [style.width]="(tile.end - tile.start)*100/(videoDuration) + '%'"
               style="height: 92px;">
          </div>
        </div>
      </div>

      <!--audios-->
      <!--overlay audio via drag and drop-->
      <div class="w-100 mt-2 audio-overlay-block" [hidden]="selected_tab.value != 0">
        <div class="mt-1 audio-overlay-bar justify-content-center"
             (click)="selectAudio(i);"
             *ngFor="let a of audios; let i = index;"
             [id]="'audio-overlay-' + i"
             [cdkDragFreeDragPosition] = "{x: a.start_time/videoDuration * timeline_child_div_width, y:0}"
             (cdkDragEnded)="audioTimeChanged(i);"
             (cdkDragStarted)="pauseVideo();"
             [style.width]="(a.end_time - a.start_time)*100/videoDuration + '%'"
             [style.border]="a.selected ? '2px solid green !important' : 'unset'"
             cdkDrag cdkDragLockAxis="x" cdkDragBoundary=".audio-overlay-block">
          <!--audio player for each audio-->
          <span class="text-nunito text-nowrap">
            {{a.text}}
          </span>
          <audio [autoplay]="false" [loop]="false" [src]="a.audio_file"
                 preload="auto"
                 (loadedmetadata)="reloadAudios(); loadOverlayWave(i);"
                 [volume]="a.volume" crossorigin="anonymous">
          </audio>
        </div>
      </div>

      <!--blurs-->
      <div class="w-100 mt-2 blur-overlay-block" [hidden]="selected_tab.value != 1">
        <div class="mt-1 audio-overlay-bar justify-content-center"
             (click)="selectBlur(i);"
             *ngFor="let b of blurs; let i = index;"
             [id]="'blur-overlay-' + i"
             [style.background-color]="'blanchedalmond'"
             [style.width]="(b.to - b.from)*100/videoDuration + '%'"
             [style.border]="b.selected ? '2px solid orange !important' : 'unset'"
             [cdkDragFreeDragPosition] = "{x: b.from/videoDuration * timeline_child_div_width, y:0}"
             (cdkDragEnded)="blurTimeChanged(i);"
             cdkDrag cdkDragLockAxis="x" cdkDragBoundary=".blur-overlay-block">
          <span class="text-nunito">
            Blur {{i+1}}
          </span>
        </div>
      </div>

      <!--texts-->
      <div class="w-100 mt-2 text-overlay-block" [hidden]="selected_tab.value != 2">
        <div class="mt-1 audio-overlay-bar justify-content-center"
             (click)="selectText(i);"
             *ngFor="let t of texts; let i = index;"
             [id]="'text-overlay-' + i"
             [style.background-color]="'lightskyblue'"
             [style.width]="(t.to - t.from)*100/videoDuration + '%'"
             [style.border]="t.selected ? '2px solid blue !important' : 'unset'"
             [cdkDragFreeDragPosition] = "{x: t.from/videoDuration * timeline_child_div_width, y:0}"
             (cdkDragEnded)="textTimeChanged(i);"
             cdkDrag cdkDragLockAxis="x" cdkDragBoundary=".text-overlay-block">
          <span class="text-nunito">
            {{t.text}}
          </span>
        </div>
      </div>

      <!--zooms-->
      <div class="w-100 mt-2 zoom-pan-block" [hidden]="selected_tab.value != 3">
        <div class="mt-1 audio-overlay-bar justify-content-center"
             (click)="selectZoomPan(i);"
             *ngFor="let z of zoomPans; let i = index;"
             [id]="'zoom-pan-' + i"
             [style.background-color]="'pink'"
             [style.width]="(z.data[1] - z.data[0])*100/videoDuration + '%'"
             [style.border]="z.selected ? '2px solid deeppink !important' : 'unset'"
             [cdkDragFreeDragPosition] = "{x: z.data[0]/videoDuration * timeline_child_div_width, y:0}"
             (cdkDragEnded)="zoomTimeChanged(i);"
             cdkDrag cdkDragLockAxis="x" cdkDragBoundary=".zoom-pan-block">
          <span class="text-nunito">
            Zoom {{i+1}}
          </span>
        </div>
      </div>

      <!--draw boxes-->
      <div class="w-100 mt-2 draw-box-block" [hidden]="selected_tab.value != 4">
        <div class="mt-1 audio-overlay-bar justify-content-center"
             (click)="selectDrawBox(i);"
             *ngFor="let d of drawBoxes; let i = index;"
             [id]="'draw-box-bar-' + i"
             [style.background-color]="'#D3C4FF'"
             [style.width]="(d.data[1] - d.data[0])*100/videoDuration + '%'"
             [style.border]="d.selected ? '2px solid #5E26FF !important' : 'unset'"
             [cdkDragFreeDragPosition] = "{x: d.data[0]/videoDuration * timeline_child_div_width, y:0}"
             (cdkDragEnded)="drawBoxTimeChanged(i);"
             cdkDrag cdkDragLockAxis="x" cdkDragBoundary=".draw-box-block">
          <span class="text-nunito">
            Box {{i+1}}
          </span>
        </div>
      </div>

      <!--image overlays-->
      <div class="w-100 mt-2 image-overlay-block" [hidden]="selected_tab.value != 4">
        <div class="mt-1 audio-overlay-bar justify-content-center"
             (click)="selectImage(i);"
             *ngFor="let im of images; let i = index;"
             [id]="'image-overlay-bar-' + i"
             [style.background-color]="'#DAF6FF'"
             [style.width]="(im.to - im.from)*100/videoDuration + '%'"
             [style.border]="im.selected ? '2px solid #2689A8 !important' : 'unset'"
             [cdkDragFreeDragPosition] = "{x: im.from/videoDuration * timeline_child_div_width, y:0}"
             (cdkDragEnded)="imageTimeChanged(i);"
             cdkDrag cdkDragLockAxis="x" cdkDragBoundary=".image-overlay-block">
          <span class="text-nunito">
            {{ getAttachedFilename(im.image_file) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
