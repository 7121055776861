<div class="dialog-bx">
  <div class="d-flex" style="justify-content: space-between;">
    <h2 mat-dialog-title translate class="fontStyle mb-0">
      Become a Creator
    </h2>
    <button mat-icon-button mat-dialog-close>
      <mat-icon style="font-size: 25px;">close</mat-icon>
    </button>
  </div>
  <div class="mt-2">
    <mat-card class="mat-elevation-z0">
      <mat-card-content class="content-text">
        <div class="fontNormal">
          <div class="fontNormal my-sm-2" translate>
            To create your own content, you need Creator rights.
          </div>
          <div *ngIf="maxCreators > 0" class="mt-sm-4" style="text-align-last: center;">
            {{numAvailable}}&nbsp;{{'von'|translate}}&nbsp;{{maxCreators}}&nbsp;{{'Available'|translate}}
          </div>
        </div>
        <div class="d-flex justify-content-center mt-4">
          <div class="w-75">
            <button (click)="submitCreator()"
                    mat-stroked-button
                    class="w-100 border-radius-20"
                    [matTooltip]="creatorButtonTooltip"
                    type="submit">
              <mat-icon class="iconStyle material-icons-outlined">upload</mat-icon>
              <span class="span-float fontNormal" translate>
                {{'Become a Creator'|translate}}
                </span>
            </button>
            <hr [attr.data-content]="'oder' | translate" class="hr-text">
            <button (click)="navbarService.openVideoRequestDialog();" mat-stroked-button class="w-100 border-radius-20" type="submit">
              <mat-icon class="iconStyle material-icons-outlined">maps_ugc</mat-icon>
              <span class="span-float fontNormal fontColor" translate>Request Clypp</span>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
