import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DataService} from '../services/data.service';
import {NavbarService} from '../services/navbar.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as audioMeter from 'src/app/scripts/volume-meter.js';
import {UntypedFormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from "../services/auth.service";
import {VideoView} from "../models/video/video.interface";
import {NgxWavesurferService} from 'ngx-wavesurfer';
import {ProgressSpinnerDialogComponent} from "../shared/progress-spinner-dialog/progress-spinner-dialog.component";
import {StillThereComponent} from "../shared/still-there/still-there.component";
import {environment} from '../../environments/environment';
import {FaceDetector, FilesetResolver, ObjectDetector} from '@mediapipe/tasks-vision';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CompletionPopupComponent} from "../completion-popup/completion-popup.component";
import VoicesWestEuropeGrouped from "../../assets/VoicesWestEuropeGrouped.json"
import {ConfirmDialogComponent} from "../shared/confirm-dialog/confirm-dialog.component";

interface Bar {
  start: number;
  end: number;
  active: boolean;
}

interface Blur {
  x: number;
  y: number;
  prev_x: number;
  prev_y: number;
  from: number;
  to: number;
  width: number;
  height: number;
  blur: number; // should be >= 1 and generally under 20: default should be 10
  selected: boolean;
}

interface Text {
  x: number;
  y: number;
  prev_x: number;
  prev_y: number;
  from: number;
  to: number;
  box: number; // 0 or 1
  boxcolor: string;
  fontcolor: string;
  text: string;
  fontsize: string; // small medium large
  selected: boolean;
}

interface Audio {
  id: number;
  start_time: number;
  end_time: number;
  volume: number;  // data is returned as string because backend is using "Decimal", example: "1.9"
  audio_file: string;
  selected: boolean;
  expanded: boolean;
  text: string;
  duration: number;
}

interface ImageOverlay {
  id: number;
  from: number;  // data is returned as string because backend is using "Decimal", example: "1.9"
  to: number;  // data is returned as string because backend is using "Decimal", example: "1.9"
  height: number;
  width: number;
  prev_x: number;
  prev_y: number;
  x_pos: number;
  y_pos: number;
  image_file: string;
  selected: boolean;
  changed: boolean;
}

interface ZoomPan {
  // Zoom pans contain start, end, x, y, z, pan_animation information
  data: [number, number, number, number, number, boolean];
  selected: boolean;
}

interface DrawBox {
  // Draw box contains [[start, end, x, y, w, h, color, alpha, thickness], [0, 10, 100, 200, red, 0.5, 10], […], …]
  data: [number, number, number, number, number, number, string, number, number|string];
  selected: boolean;
}

// used in playDelay
function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

@Component({
  selector: 'app-video-edit-page',
  templateUrl: './video-edit-page.component.html',
  styleUrls: ['./video-edit-page.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [NgxWavesurferService]
})
export class VideoEditPageComponent implements OnInit, OnChanges, OnDestroy {
  // for audio meter
  readonly WIDTH = 5;
  readonly HEIGHT = 40;

  videoURL: string;
  filmstrip_src: string = "assets/images/Topics_Cover_Image.png";

  @Output()
  videoTrimmed = new EventEmitter<{ trimmed: boolean, finalLink: string }>();
  // @ViewChild('slider') slider;
  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>;
  @ViewChild('timelineRef') timeline: ElementRef<HTMLDivElement>;
  // @ViewChild('valueInput') valueInput;
  // @ViewChild('valueSpan') valueSpan;
  // @ViewChild('valueBetweenStart') valueBetweenStart;
  // @ViewChild('valueBetweenEnd') valueBetweenEnd;
  @ViewChild('currentTimeSpan') currentTimeSpan;
  // @ViewChild('audioPlayer') audioPlayer;
  @ViewChild('meter') meter: ElementRef;
  // @ViewChild(MatAccordion) accordion: MatAccordion;
  // @ViewChildren(MatExpansionPanel) matExpansionPanelQueryList: QueryList<MatExpansionPanel>;
  // @ViewChildren('textExpansionPanel') textExpansionPanelQueryList: QueryList<MatExpansionPanel>;
  // @ViewChild('dragDiv', {read: ViewContainerRef}) dragDiv: ViewContainerRef;
  // @ViewChild('resizeDiv', {read: ViewContainerRef}) resizeDiv: ViewContainerRef;
  // @ViewChild('dragComponent') dragComponent: TextOverlayDragDropComponent;
  // @ViewChild('resizeComponent') resizeComponent: ShapeOverlayDivComponent;
  // @ViewChild('videoDynamic') videoDynamic;
  audio_vol: number = 1;
  noise_sup: boolean = false;
  player;
  videoDuration: number = 0;
  videoDurationString: string = "";
  script: string = "";  // to keep track of notes
  currentTempTime: number = 0;
  tiles: Bar[] = [];
  tiles_history: Bar[][] = [];
  texts: Text[] = [];
  blurs: Blur[] = [];
  audios: Audio[] = [];
  images: ImageOverlay[] = [];
  zoomPans: ZoomPan[] = [];
  drawBoxes: DrawBox[] = [];
  seekerValue = 0;
  videoPlayerClass: any;
  date: any;
  video_object: VideoView = null;
  video_id: string = "";
  disableAudio: boolean = false;
  isAudioRecordingMode: boolean = false;
  is_audio_recording: boolean = false;
  showCountdown = false;
  timeLeft: string = '3';
  mics: MediaDeviceInfo[] = [];
  mic_source: string = '';
  audioHigh = new Audio();
  audioLow = new Audio();
  fontUrl = '';
  videoUpscaleWidthFactor = 1;
  videoUpscaleHeightFactor = 1;
  video_state: string = "NO";
  playerLoaded: boolean = false;  // to enable/disable the process clypp button
  selected_tab = new UntypedFormControl(0);
  numberOfPics: number = 0;
  picIndex: any;
  @ViewChild('videoPlayer', {static: true}) private videoElRef: ElementRef;
  // @Output()
  // private finishedEditing = new EventEmitter<void>();
  private audioMeter: any;
  microphone_attached: boolean = false;
  font_size_dict: any;
  is_text_area_active: boolean = false;
  audio_start_time: number = 0;  // this is to store when audio recording was started
  audio_players: NodeListOf<HTMLAudioElement> = null;
  zoom_mode: string = "zoom_in";  // use to switch between timeline view
  selected_tile_index: number = -1;  // to keep track of which tile is selected, used in zooms tab
  scriptRows: number = 10;
  timeline_child_div_width: number = 0; // width in pixels to calculate audio overlays

  timeoutHandler: any = null;  // timeout handler to show are you still there popup, when the url has expired
  auto_save_interval: any = null;  // save all overlay images every 5 seconds
  is_user_editing_current_time: boolean = false;
  is_shift_pressed: boolean = false;  // when user wants to resize images
  confirmDialog: MatDialogRef<ConfirmDialogComponent> = null;  // variable to keep track of confirmation dialog

  // variables to keep track of overlay audios
  audio: MediaStream = undefined;
  audio_media_recorder_options: MediaRecorderOptions = {};
  audioMediaRecorder: MediaRecorder = undefined;
  audio_constraints: MediaStreamConstraints = {};
  face_and_object_detector: ObjectDetector | FaceDetector | null = null;
  face_and_object_progress: number = 0;  // this is used to show progress while detecting
  presetColorArray: string[] = [];

  paragraph_lines: string[] = [];  // to keep track of lines when creating audio overlays
  start_times: number[] = [];  // to keep track of lines when creating audio overlays

  constructor(private dialog: MatDialog,
              private navbarService: NavbarService,
              private route: ActivatedRoute,
              private translateService: TranslateService,
              public cd: ChangeDetectorRef,
              private dataService: DataService,
              private router: Router,
              private snackBar: MatSnackBar,
              public waveSurferService: NgxWavesurferService,
              private httpClient: HttpClient,
              public authService: AuthService) {
    // check script rows
    this.audio_constraints = {video: false, audio: {
      echoCancellation: true, noiseSuppression: true, autoGainControl: true, channelCount: 1,
        sampleRate: 48000, // CD quality is 44100
        sampleSize: 16
    }};
    this.audio_media_recorder_options = {
      audioBitsPerSecond: 128000,  // 128 kbps
    }

    // initiate preset colors
    if (localStorage.getItem('presetColorArray')){
      this.presetColorArray = JSON.parse(localStorage.getItem('presetColorArray'));
    } else {
      // first color is company color
      const company_color: string = this.authService.company ? this.authService.company.primary_color : '#3C6FBA';
      this.presetColorArray = [company_color, '#3C6FBA', '#6AB65E', '#F04935', '#FFC000'];
    }
  }

  get video(): HTMLVideoElement {
    return this.videoElRef.nativeElement;
  }

  get videoContainer(): HTMLElement {
    return this.videoElRef.nativeElement;
  }

  saveColor(event: string[]) {
    this.presetColorArray = event;
    localStorage.setItem('presetColorArray', JSON.stringify(event));
  }

  playDelay = async () => {
    this.showCountdown = true;
    this.timeLeft = '3';
    await delay(900);
    this.timeLeft = '2';
    await delay(900);
    this.timeLeft = '1';
    await delay(300);
    this.audioHigh.play().then();
    await delay(700);
    this.showCountdown = false;
  };

  ngOnInit(): void {
    // this.textBlurDiv = document.getElementById('text-blur-div');
    // load audio on init
    this.audioHigh.src = '../../assets/Mini_Button_01.wav';
    this.audioHigh.load();
    this.audioLow.src = '../../assets/Mini_Button_02.wav';
    this.audioLow.load();

    this.navbarService.showSideNav = false;
    this.navbarService.enableCreationMode();
    this.navbarService.hide();

    this.route.paramMap.subscribe(async (map) => {
      this.video_id = map.get('id');
    });

    this.dataService.getURL<VideoView>(`user/videos/${this.video_id}/`)
      .subscribe((res) => {
        this.video_object = res;
        this.videoURL = this.video_object.video_file;
        this.video_state = this.video_object.state;
        this.script = this.video_object.script;
        this.filmstrip_src = this.video_object.filmstrip_small;

        // update audio rec options as per project settings
        switch (this.video_object.project_settings) {
          // 720p case is already in constructor
          case '1080':
            this.audio_media_recorder_options.audioBitsPerSecond = 256000;
            break;
          case '1440':
            this.audio_media_recorder_options.audioBitsPerSecond = 320000;
            break;
        }
      });

    this.checkMics(); // do not wait for devices

    // after every 5 seconds, check if we have to save any image overlays
    this.auto_save_interval = setInterval(() => {
      this.updateImageOverlays();
    }, (5*1000));
  }

  ngAfterViewInit(): void {
    // find the inner height to determine number of rows needed
    // example: 459=> 4 rows, 715 => 11 rows, 976 => 19 rows
    if(window.innerHeight < 500){
      this.scriptRows = 4;
    }
    else if(window.innerHeight < 800){
      this.scriptRows = 11;
    }
    else if(window.innerHeight < 1000){
      this.scriptRows = 19;
    }
    else {
      this.scriptRows = 21;
    }
  }

  ngOnChanges(): void {
    this.cd.detectChanges();
  }

  async checkMics() {
    // check if we have mics, otherwise disable the audio input
    navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
      // clear the array, because this function may be called again
      this.mics = [];

      for (let device of deviceInfos) {
        if (device['kind'] == 'audioinput') {
          this.mics.push(device);
        }
      }

      // initiate the first variable
      if (this.mics.length) {
        this.mic_source = this.mics[0]['deviceId'];
        this.microphone_attached = true;
      } else {
        // if there is no mic
        this.microphone_attached = false;
      }
    }, (err) => {
      // failed to enumerate device
      console.error(err);
    });
  }

  onPlayerReady(event): void {
    if (this.playerLoaded) {
      // player is already loaded
      return;
    }
    this.player = this.videoPlayer.nativeElement;
    this.font_size_dict = new Map<string, number>();
    this.font_size_dict.set('small', `${this.player.offsetHeight / 20}px`);
    this.font_size_dict.set('medium', `${this.player.offsetHeight / 15}px`);
    this.font_size_dict.set('large', `${this.player.offsetHeight / 10}px`);

    if (this.player.clientWidth > this.player.clientHeight) {
      this.videoPlayerClass = {
        'width': '100%',
        'height': 'auto'
      }
    } else {
      this.videoPlayerClass = {
        'width': 'inherit',
        'height': '100%'
      }
    }

    // console.log(this.player.duration);
    // this.videoDuration = parseFloat(this.player.duration.toFixed(2));
    this.videoDuration = Math.round(this.player.duration * 100) / 100;  // this may also round down 10.021 => 10.02
    // console.log(this.videoDuration);

    this.videoDurationString = this.toHHMMSS(this.videoDuration);
    this.player.currentTime = 0;

    // initiate the grid tiles
    if (this.player.duration != Infinity) {
      this.tiles = [{
        active: true,
        start: 0,
        end: this.videoDuration
      }];
    }

    if (this.video_object.edit_parameters != null) {
      // trim bar
      if (this.video_object.edit_parameters['start'] != undefined) {
        this.restoreVideoTrimBarState();
      }

      //audio toggle
      if (this.video_object.edit_parameters['audio_vol'] != null) {
        this.audio_vol = this.video_object.edit_parameters['audio_vol'];
      }

      // if there was thumbnail time from before, it will be set to that value
      // it will be only changed if user selects a new thumbnail
    }

    // calculate the upscale factors
    const videoNativeHeight = this.player.videoHeight;
    const videoNativeWidth = this.player.videoWidth;
    const videoClientHeight = this.player.clientHeight;
    const videoClientWidth = this.player.clientWidth;
    this.videoUpscaleWidthFactor = parseFloat((videoNativeWidth / videoClientWidth).toFixed(2));
    this.videoUpscaleHeightFactor = parseFloat((videoNativeHeight / videoClientHeight).toFixed(2));

    // initiate audio overlays
    this.initAudios();

    // initiate image overlays
    this.dataService.getURL(`user/videos/${this.video_id}/images/`, {observe: 'body', responseType: 'json'})
      .subscribe((res: any[]) => {
        for(let i of res){
          this.images.push({
            id: i.id,
            from: parseFloat(i.start_time),
            to: parseFloat(i.end_time),
            height: i.height,
            width: i.width,
            x_pos: i.x_pos,
            y_pos: i.y_pos,
            prev_x: i.x_pos,
            prev_y: i.y_pos,
            image_file: i.image_file,
            selected: false,
            changed: false
          })
        }
        this.sortImages();
      });
  }

  initAudios() {
    this.audios = [];
    this.audio_players = null;
    this.dataService.getURL(`user/videos/${this.video_id}/audios/`, {observe: 'body', responseType: 'json'})
      .subscribe((res: any[]) => {
        for (let i of res) {
          const st = parseFloat(i.start_time);
          const et = parseFloat(i.end_time);
          this.audios.push({
            id: i.id,
            start_time: st,
            end_time: et,
            volume: parseFloat(i.volume),
            audio_file: i.audio_file,
            selected: false,
            expanded: false,
            text: i.text,
            duration: et - st,
          });
        }
        this.sortAudios();
      });
  }

  sortAudios() {
    // this.audios.sort((a, b) => a.start_time - b.start_time);
    // this.reloadAudios();
  }

  sortImages() {
    // todo: images are rendered in order of their ids
    this.images.sort((a, b) => a.from - b.from);
  }

  sortTexts() {
    this.texts.sort((a, b) => a.from - b.from);
  }

  sortBlurs() {
    this.blurs.sort((a, b) => a.from - b.from);
  }

  sortZoomPans() {
    // 0th element is the start time
    this.zoomPans.sort((a, b) => a.data[0] - b.data[0]);
  }

  sortDrawBoxes() {
    // 0th element is the start time
    this.drawBoxes.sort((a, b) => a.data[0] - b.data[0]);
  }

  dataLoaded(event) {
    if (this.playerLoaded) {
      // player is already loaded, return
      return;
    }
    this.playerLoaded = true;  // to enable/disable the process clypp button

    // initiate the height and width for the boundaries
    let style = document.createElement('style');
    style.innerHTML = `.text-blur-boundary {height: ${this.player.clientHeight}px; width: ${this.player.clientWidth}px;}`;
    document.getElementsByTagName('head')[0].appendChild(style);

    // find the width of timeline for audio overlays
    this.timeline_child_div_width = document.getElementById('timeline-child-div').offsetWidth;

    // initiate the timeline images, if there is no filmstrip
    if (this.video_object.filmstrip_small) {
      // ok
    } else {
      // load dynamic filmstrip
      this.getVideoThumbnails(this.videoURL);
      // delete the img tag
      document.getElementById('filmstrip-img').remove();
    }

    // create a font face for text boxes
    // todo: make use of ci_profile's font_file
    this.fontUrl = this.authService.company.font_file;
    let styles = `
          @font-face {
              font-family: 'companyFont';
              src: url("${this.fontUrl}") format("truetype");
          }
          `
    /*div#block div{
              font-family: 'font1', sans-serif !important;
          }*/
    const node = document.createElement('style');
    node.innerHTML = styles;
    document.body.appendChild(node);

    // initiate to original audio wave
    let ws = this.waveSurferService.create({
      container: '#initialAudioWaveForm',
      cursorColor: 'transparent',
      height: 30,
      barWidth: 1,
      barGap: 1,
      barHeight: 1,
      waveColor: "gray",
      // backgroundColor: 'aliceblue',
      responsive: true,
      removeMediaElementOnDestroy: true,
      partialRender: true,
    });
    ws.load(this.videoURL);

    // now that the player is ready, start a timeout
    this.restartStillThereTimeout();
  }

  // this method clears the previous timeout and starts a new one
  restartStillThereTimeout() {
    clearTimeout(this.timeoutHandler);
    // start the timer to ask for user if they are still there
    this.timeoutHandler = setTimeout(() => {
      // open the still-there component
      // save the data first, as the call may not reach us
      this.processVideo(true);
      const stillThereDialogRef: MatDialogRef<StillThereComponent> =
        this.dialog.open(StillThereComponent, {
          panelClass: 'transparent',
          disableClose: true,
        });
      stillThereDialogRef.afterClosed().subscribe(() => {
        // save the data and reload the video url
        // this.restartStillThereTimeout();
        location.reload();
      });
      // no need to clear timeout as processVideo() call has started a new one
      // clearTimeout(this.timeoutHandler);
    }, environment.timeoutDuration * 1000);
  }

  // when a user resizes the window, ask them to refresh the page
  onResize() {
    if (this.confirmDialog != null) {
      return;  // already opened
    }
    this.pauseVideo();
    const title: string = this.translateService.instant('Reload page?');
    const subtitle: string = this.translateService.instant('Please refresh the page to correctly display all contents.');
    this.confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: [title, subtitle],
    });
    this.confirmDialog.afterClosed().subscribe((res) => {
      if (res) {
        // save and reload
        this.saveAndReload();
        location.reload();
      }
      this.confirmDialog = null;
    });
  }

  saveAndReload() {
    this.restartStillThereTimeout();
    this.processVideo(true);
  }

  // user presses this button, then we load the model and detect faces
  blurFacesOrObjects(kind: 'person' | 'face') {
    // blur faces
    this.face_and_object_progress = 1;  // increase it so that the buttons are hidden
    this.initializePersonDetector(kind).then();
  }

  restoreVideoTrimBarState() {
    let start_array = this.video_object.edit_parameters['start'];
    let deleted_array = this.video_object.edit_parameters['delete_Array'];

    // splits
    if (start_array) {
      this.tiles = [];
      for (let i of start_array) {
        const tile_data: Bar = {
          active: true,
          start: i[0],
          end: i[1]
        }
        this.tiles.push(tile_data);
      }

      if (deleted_array) {
        for (let i of deleted_array) {
          const tile_data: Bar = {
            active: false,
            start: i[0],
            end: i[1]
          }
          this.tiles.push(tile_data);
        }
      }

      this.sortTiles();
    }

    // texts, blurs, noise, overlay, audio
    if (this.video_object.edit_parameters) {
      if (this.video_object.edit_parameters['draw_texts']) {
        for (let i of this.video_object.edit_parameters['draw_texts']) {
          this.texts.push({
            x: i['x'],
            y: i['y'],
            prev_x: i['x'],
            prev_y: i['y'],
            from: i['from'],
            to: i['to'],
            box: i['box'],
            fontcolor: i['fontcolor'],
            boxcolor: i['boxcolor'],
            text: i['text'],
            fontsize: i['fontsize'],
            selected: false
          });
        }
        this.sortTexts();
      }

      if (this.video_object.edit_parameters['blur_boxes']) {
        for (let i of this.video_object.edit_parameters['blur_boxes']) {
          this.blurs.push({
            x: i['x'],
            y: i['y'],
            prev_x: i['x'],
            prev_y: i['y'],
            from: i['from'],
            to: i['to'],
            width: i['width'],
            height: i['height'],
            blur: i['blur'] ? i['blur'] : 10,  // set blur if there, else set to 10
            selected: false
          });
        }
        this.sortBlurs();
      }

      // zooms
      if (this.video_object.edit_parameters['zoom_pans']) {
        for (let i of this.video_object.edit_parameters['zoom_pans']) {
          this.zoomPans.push({
            data: i,
            selected: false
          });
        }
        this.sortZoomPans();
      }

      // draw boxes
      if (this.video_object.edit_parameters['draw_boxes']) {
        for (let i of this.video_object.edit_parameters['draw_boxes']) {
          this.drawBoxes.push({
            data: i,
            selected: false
          });
        }
        this.sortDrawBoxes();
      }

      // noise suppression
      if (this.video_object.edit_parameters['noise_sup']) {
        this.noise_sup = true;
      }
    }
  }

  sortTiles() {
    this.tiles = this.tiles.sort((first, second) => 0 - (first.start > second.start ? -1 : 1));
  }

  toHHMMSS(seconds: number): string {
    let result = new Date(seconds * 1000).toISOString();
    if(this.videoDuration >= 3600) {
      // hh:mm:ss.ss mode
      result = result.slice(11, 22);
    }
    else{
      // mm:ss.ss mode
      result = result.slice(14, 22);
    }
    return result;
  }

  pauseVideo(): void {
    if(!this.player.paused){
      this.player.pause();
    }
    if (this.audio_players) {
      this.audio_players.forEach((element) => {
        if(!element.paused){
          element.pause();
        }
      })
    }
  }

  playVideo(): void {
    this.player.play();
    if (this.videoDuration == this.player.currentTime) {
      this.player.currentTime = 0;
      // this.audioPlayer.nativeElement.currentTime = 0;
    }
  }

  timeUpdated(): void {
    if (this.player == undefined) {
      // this function may be called before onPlayerReady function, so do nothing in that case
      return;
    }

    // this function need not to called when recording audio:
    this.updateCurrentTime();

    if (this.currentTempTime >= this.player.duration) {
      if (this.audioMediaRecorder) {
        this.stopAudioRecording();
      }
      // this.player.currentTime = 0;
      this.pauseVideo();
      return;
    }

    if (this.isAudioRecordingMode) {
      // do not skip sections when in audio recording mode
      return;
    }

    let current_time = this.player.currentTime;
    this.selected_tile_index = this.tiles.findIndex(tile => tile.start <= current_time && tile.end > current_time);
    let current_tile = this.tiles[this.selected_tile_index];

    let audios_to_play = this.audios.filter(audio => audio.start_time <= current_time && audio.end_time > current_time);
    let audios_to_pause = this.audios.filter(audio => audio.start_time > current_time || audio.end_time < current_time);

    // pause all audios which are out of bound
    audios_to_pause.forEach(audio => {
      let index = this.audios.indexOf(audio);
      let current_player = this.audio_players.item(index);
      current_player.currentTime = 0;
      current_player.pause();
    });

    if (current_tile.active) {
      // play this tile

      if (this.player.paused) {
        // seek all audios to correct time and pause them as well
        audios_to_play.forEach(audio => {
          let index = this.audios.indexOf(audio);
          let current_player = this.audio_players.item(index);
          current_player.currentTime = this.player.currentTime - audio.start_time;
          current_player.pause();
        });
      }
      else {
        // player is playing,
        // deselect all audios which are supposed to be paused
        audios_to_pause.map(e => e.selected = false);
        // select all audios which are supposed to be playing
        audios_to_play.map(e => e.selected = true);
        // play all audios which are supposed to be playing
        audios_to_play.forEach(audio=> {
          let index = this.audios.indexOf(audio);
          let current_player = this.audio_players.item(index);
          // this seeking causes jittery playback
          // current_player.currentTime = this.player.currentTime - audio.start_time;
          current_player.play();
        });
      }
    } else {
      // go to next tile only if player is playing and correct all overlay audios' playing time
      try {
        // update the current times to end of this tile or start of next tile
        if(!this.player.paused) {
          this.player.currentTime = current_tile.end + 0.1;
          if (this.player.currentTime == this.player.duration) {
            // video has ended, go to the start
            this.player.currentTime = 0;
            this.pauseVideo();
          }
        }
        // correct all those audio players which were supposed to be playing
        audios_to_play.forEach(audio => {
          // move to end of this tile
          let index = this.audios.indexOf(audio);
          this.audio_players[index].currentTime = this.player.currentTime - audio.start_time;
        })
      } catch (e) {
        console.error(e);
      }
    }
  }

  videoEnded() {
    // do nothing
    // when video ends, bring the player back to 0th position
    // console.log('videoEnded');
    // if (this.audioMediaRecorder) {
    //   do nothing
    // } else {
    //   this.player.currentTime = 0;
    //   this.currentTempTime = 0
    //   this.updateCurrentTime();
    //   this.pauseVideo();
    // }
  }

  backwardVideo(): void {
    this.currentTempTime -= 5;
    if (this.currentTempTime < 0) {
      this.player.currentTime = 0;
    } else {
      this.player.currentTime = this.currentTempTime;
    }
    this.pauseVideo();
  }

  forwardVideo(): void {
    this.currentTempTime += 5;
    if (this.currentTempTime > this.videoDuration) {
      this.player.currentTime = this.videoDuration;
    } else {
      this.player.currentTime = this.currentTempTime;
    }
    this.pauseVideo();
  }

  updateCurrentTime(): void {
    // update the timer
    this.currentTempTime = this.player.currentTime;
    this.currentTimeSpan.nativeElement.innerHTML =
      this.toHHMMSS(this.currentTempTime) + ' / ' + this.videoDurationString;
    this.seekerValue = this.currentTempTime * 100;
  }

  editCurrentTime(): void {
    if (this.isAudioRecordingMode) {
      // do not let user edit time in this case
      return;
    }
    this.pauseVideo();
    this.is_user_editing_current_time = true;
    this.textAreaFocusIn();
    // now focus on the input element
    setTimeout(() => {
      // it may take some time for the element to become visible
      const inputElement = document.getElementById('time-input') as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
        // inputElement.setSelectionRange(0, -1);
      }
    }, 100)
  }

  // user can manually set some time
  setCurrentTime(event) {
    if (this.isAudioRecordingMode) {
      // do not let user change time in this case
      this.textAreaFocusOut();
      return;
    }
    const time = event.target.value;
    const seconds = stringToSeconds(time);
    if (seconds > 0 && seconds <= this.videoDuration) {
      this.onChangeSeekerTime(seconds * 100);
    }
    this.textAreaFocusOut();
  }

  // save all image overlays, which are changed
  updateImageOverlays() {
    for (let i of this.images) {
      if (i.changed) {
        // put call, since we don't know what is changed, we send everything
        const formData = {
          start_time: i.from,
          end_time: i.to,
          height: Math.trunc(i.height),
          width: Math.trunc(i.width),
          x_pos: Math.trunc(i.x_pos),
          y_pos: Math.trunc(i.y_pos),
        }
        this.dataService.putURL(`user/images/${i.id}/`, formData,
          {observe: 'body', responseType: 'json'}).subscribe(() => {
            i.changed = false;
        });
      }
    }
  }

  processVideo(justUpdateEditParameters = false): void {
    // prepare the form
    let formBody = this.getEditParametersFormData();

    if (!formBody.start.length) {
      // there is nothing in start array, warn user!
      if(!justUpdateEditParameters){
        // only warn if I want to process video
        let message = this.translateService.instant("The timeline is empty");
        window.alert(message);
      }
      // do not send any put call!
      return;
    }

    // only update images when above data is ok
    this.updateImageOverlays();

    // if (draw_texts.length > 0) {
    //   formBody['draw_texts'] = draw_texts;
    // }
    // if (blur_boxes.length > 0) {
    //   formBody['blur_boxes'] = blur_boxes;
    // }

    let newFormBody: any;
    if (justUpdateEditParameters) {
      newFormBody = {
        'edit_parameters': formBody
      }
    } else {
      newFormBody = formBody;
    }

    if (this.script != this.video_object.script) {
      newFormBody['script'] = this.script;
    }

    // PR case has new video processing method
    if (justUpdateEditParameters) {
      // disable the button
      // const button = document.getElementById('save-changes-button') as HTMLButtonElement;
      // button.hidden = true;
      // in case of PR mode, send a PUT call with new edit parameters
      this.dataService.putURL(`user/videos/${this.video_id}/`, newFormBody, {
        observe: 'body',
        responseType: 'json'
      }).subscribe((res: VideoView) => {
        // now update the video url
        this.videoURL = res.video_file;
        this.video_state = res.state;
        // todo: clear the fields which are initiated on new video reload
      });
    } else {
      this.dataService.postURL<VideoView>(`user/videos/${this.video_id}/medias/`, newFormBody,
        {observe: 'body', responseType: 'json'}).subscribe((res) => {
        this.router.navigate(['create-video/' + this.video_id + '/review']);
      });
    }
  }

  // prepare the form data for the api calls
  getEditParametersFormData() {
    let trimmedArray: [number, number][] = [];
    let deletedArray: [number, number][] = [];
    let draw_texts = [];
    let blur_boxes = [];
    let zoom_pans = [];
    let draw_boxes = [];

    // start and end
    for (let tile of this.tiles) {
      if (tile.active) {
        // append to start array
        trimmedArray.push([tile.start, tile.end]);
      } else {
        // append to delete array
        deletedArray.push([tile.start, tile.end]);
      }
    }

    // blur boxes
    for (let i of this.blurs) {
      blur_boxes.push({
        x: i.x,
        y: i.y,
        from: i.from,
        to: i.to,
        height: i.height,
        width: i.width,
        blur: i.blur
      });
    }

    // text boxes
    for (let i of this.texts) {
      draw_texts.push({
        x: i.x,
        y: i.y,
        from: i.from,
        to: i.to,
        box: i.box ? 1 : 0,
        boxcolor: i.boxcolor,
        fontcolor: i.fontcolor,
        text: i.text,
        fontsize: i.fontsize,
      });
    }

    // zoom pans
    for (let i of this.zoomPans) {
      zoom_pans.push(i.data);
    }

    // draw boxes
    for (let i of this.drawBoxes) {
      draw_boxes.push(i.data);
    }

    let formBody = {
      start: trimmedArray,
      speed: 1,
      audio_vol: this.audio_vol,
      noise_sup: this.noise_sup,
      delete_Array: deletedArray,
      zoom_pans: zoom_pans,
      draw_texts: draw_texts,
      blur_boxes: blur_boxes,
      draw_boxes: draw_boxes
    }

    return formBody;
  }

  // this method is to make sure that noise reduction is turned off when audio vol is 0
  totalAudioVolumeChanged(){
    if(this.audio_vol==0){
      if(this.noise_sup){
        this.noise_sup = false;
      }
    }
  }

  // trim video
  splitVideo(): void {
    // sanity check
    let current_time = parseFloat(this.player.currentTime.toFixed(2));
    if (current_time <= 0 || this.player.currentTime >= this.player.duration) {
      return;
    }

    // save to stack
    this.tiles_history.push(JSON.parse(JSON.stringify(this.tiles)));

    // find the tile to split
    for (let i in this.tiles) {
      if (current_time > this.tiles[i].start && current_time < this.tiles[i].end) {
        // initiate
        let new_tile: Bar = {
          active: true,
          start: current_time,
          end: this.tiles[i].end,
        }

        // split
        this.tiles[i].end = current_time;
        // add new element
        this.tiles.push(new_tile);
        this.sortTiles();
        break;
      }
    }
  }

  // delete inactive tiles forever from the Backend
  deleteForever() {
    // prepare tiles
    let formBody = this.getEditParametersFormData();
    let message = "";
    // check if start array is present
    if (!formBody.start.length) {
      message = this.translateService.instant("The timeline is empty");
      this.snackBar.open(message, '', {duration: 2500});
      return;
    }
    // check if there is anything to delete
    if (!formBody.delete_Array.length) {
      message = this.translateService.instant("Nothing to delete");
      this.snackBar.open(message, '', {duration: 2500});
      return;
    }
    // confirm from user first
    message += this.translateService.instant("Gray elements in the timeline will be removed forever and cannot be reset.")
    message += '\n\n'
    message += this.translateService.instant("Would you like to continue?");
    if (window.confirm(message)) {
      // call the patch api
      const spinnerDialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
        // show spinner
        this.dialog.open(ProgressSpinnerDialogComponent, {
          panelClass: 'transparent',
          disableClose: true,
          data: "One moment please",
        });
      this.dataService.patchURL(`user/videos/${this.video_id}/medias/`, formBody, {observe: 'response'})
        .subscribe((res: any) => {
          // success, reload
          location.reload();
        }, (err: HttpErrorResponse) => {
          // failed, nothing is changed in BE
          console.error(err);
          spinnerDialogRef.close();
          this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'),
            '', {duration: 2500});
        });
    }
  }

  undoTileAction() {
    // take last state and set it to tiles
    if (this.tiles_history.length) {
      this.tiles = this.tiles_history.pop();
      this.selected_tile_index = 0;
    }
  }

  tileSelected(index: number): void {
    // select this tile
    this.selected_tile_index = index;
    // show delete/restore button based on data
    // update the player time if we are not recording audio
    if(!this.isAudioRecordingMode){
      this.player.currentTime = this.tiles[index].start;
    }
  }

  deleteSelectedSection() {
    this.tiles[this.selected_tile_index].active = false;
  }

  restoreSelectedSection() {
    this.tiles[this.selected_tile_index].active = true;
  }

  resetTrimmer() {
    let message = this.translateService.instant("Reset all trim-points?");
    if (window.confirm(message)) {
      // save to stack
      this.tiles_history.push(JSON.parse(JSON.stringify(this.tiles)));

      this.selected_tile_index = 0;

      // clear it!
      this.tiles = [{
        active: true,
        start: 0,
        end: this.videoDuration,
      }];
    }
  }

  // end trimmer
  // range input
  onChangeSeekerTime(time_in_centi_seconds: number): void {
    // 1 second = 100 centi_seconds
    // we're using this as to provide precise control to user, but not too precise control upto milliseconds
    this.pauseVideo();
    this.currentTempTime = time_in_centi_seconds / 100;
    this.player.currentTime = time_in_centi_seconds / 100;
    this.currentTimeSpan.nativeElement.innerHTML =
      this.toHHMMSS(this.currentTempTime) + ' / ' + this.videoDurationString;
  }

  // mute all audios, needed while overlay recording
  muteAllAudios(){
    if (this.audio_players) {
      this.audio_players.forEach((element) => {
        element.muted = true;
      })
    }
  }

  unmuteAllAudios(){
    if (this.audio_players) {
      this.audio_players.forEach((element) => {
        element.muted = false;
      })
    }
  }

  // audio overlay
  async startAudioRecording(): Promise<void> {
    this.pauseVideo();

    // initiate audio stream from mic
    this.audio_constraints.audio['deviceId'] = this.mic_source;  // update mic
    await navigator.mediaDevices.getUserMedia(this.audio_constraints).then((audioStream) => {
      this.audio = audioStream;
    }, (error) => {
      // if mic access is blocked
      console.error(error);
      this.audio = null;
      window.alert(this.translateService.instant("Access denied"));
      return;
    });

    // prepare blobs
    let audioMediaStream: MediaStream = null;
    audioMediaStream = new MediaStream([...this.audio.getTracks()]);
    this.audioMediaRecorder = new MediaRecorder(audioMediaStream, this.audio_media_recorder_options);

    // initiate stop action
    this.audioMediaRecorder.addEventListener('dataavailable', event => {
      if (event.data && event.data.size > 0) {
        // prepare blob
        const audioRecordedBlobs: Array<Blob> = [event.data];
        const blob = new Blob(audioRecordedBlobs, {type: 'audio/opus'});
        const file_name = `Audio ${new Date().toDateString()}.opus`;
        const file_object: File = new File([blob], file_name, {type: 'audio/opus'});
        this.uploadAudioFile(file_object);
        this.audioMediaRecorder = undefined;
      } else {
        window.alert(this.translateService.instant("Failed"));
      }
    });

    // show audio meter
    const audioContext = new AudioContext();
    const mediaStreamSource = audioContext.createMediaStreamSource(this.audio);
    this.audioMeter = audioMeter.createAudioMeter(audioContext);
    mediaStreamSource.connect(this.audioMeter);

    // start recording in 3 2 1
    this.is_audio_recording = true;
    this.player.muted = true;
    this.muteAllAudios();
    await this.playDelay();
    this.audioMediaRecorder.start();

    this.playVideo();
    this.audio_start_time = parseFloat(this.player.currentTime);
    if (!this.disableAudio) {
      this.onLevelChange();
    }
  }

  async stopAudioRecording(): Promise<void> {
    // stop the recorder
    this.is_audio_recording = false;
    this.audioMediaRecorder?.stop();

    // free the mic access
    if(this.audio){
      this.audio.getTracks().forEach(track => track.stop());
      this.audio = undefined;
    }

    // pause the video
    this.pauseVideo();
    this.player.muted = false;
    this.unmuteAllAudios();
    this.audioLow.play();

    // clear the audio meter
    if (!this.disableAudio) {
      this.meter.nativeElement
        .getContext('2d')
        .clearRect(0, 0, this.WIDTH, this.HEIGHT);
      this.audioMeter.shutdown();
    }

    this.isAudioRecordingMode = false;
  }

  onLevelChange = (time = 0) => {
    if (this.is_audio_recording) {
      const context = this.meter.nativeElement.getContext('2d');

      context.clearRect(0, 0, this.WIDTH, this.HEIGHT);
      if (!this.audioMeter.checkClipping()) {
        context.fillStyle = 'green';
      }
      context.fillRect(
        0,
        0,
        this.WIDTH,
        this.audioMeter.volume * this.HEIGHT * 1.4,
      );
      window.requestAnimationFrame(this.onLevelChange);
    }
  }

  overlayAudioRecordingMode(enable=true){
    this.isAudioRecordingMode = enable;
    this.pauseVideo();
  }

  // this is needed, otherwise query selector can not find audio players
  reloadAudios() {
    // re-initiate all audios from HTML
    this.audio_players = document.querySelectorAll("audio");
    // console.log('relaoded');
  }

  backRecording() {
    // save the edit parameters before going back
    this.processVideo(true);
    this.router.navigate(['create-video/' + this.video_id + '/record']);
  }

  // user is sliding the text bar
  blurTimeChanged(i: number){
    // i: index of the object
    this.selectBlur(i);
    // find the bar
    let blur_overlay_bar = document.getElementById(`blur-overlay-${i}`);
    // find it's translate data in pixels
    let transform_data = blur_overlay_bar.style.transform;  // translate3d(698px, 0px, 0px)
    // fetch the pixels from string
    let pixes_left = parseInt(transform_data.split('px')[0].split('(')[1]);
    // find the start time wrt pixel position
    let new_start_time = (pixes_left * this.videoDuration) / this.timeline_child_div_width;
    // fix the precision
    let new_start_time_two_decimal = parseFloat(new_start_time.toFixed(2));
    // update the audio object
    let slack = new_start_time_two_decimal - this.blurs[i].from;
    this.blurs[i].from = new_start_time_two_decimal;
    this.blurs[i].to = this.blurs[i].to + slack;
    // sort them
    this.player.currentTime = this.blurs[i].from;
    this.sortBlurs();
  }

  // user is sliding the text bar
  textTimeChanged(i: number){
    // i: index of the object
    this.selectText(i);
    // find the bar
    let text_overlay_bar = document.getElementById(`text-overlay-${i}`);
    // find it's translate data in pixels
    let transform_data = text_overlay_bar.style.transform;  // translate3d(698px, 0px, 0px)
    // fetch the pixels from string
    let pixes_left = parseInt(transform_data.split('px')[0].split('(')[1]);
    // find the start time wrt pixel position
    let new_start_time = (pixes_left * this.videoDuration) / this.timeline_child_div_width;
    // fix the precision
    let new_start_time_two_decimal = parseFloat(new_start_time.toFixed(2));
    // update the audio object
    let slack = new_start_time_two_decimal - this.texts[i].from;
    this.texts[i].from = new_start_time_two_decimal;
    this.texts[i].to = this.texts[i].to + slack;
    // sort them
    this.player.currentTime = this.texts[i].from;
    this.sortTexts();
  }

  // user is sliding the zoom bar
  zoomTimeChanged(i: number){
    // i: index of the object
    this.selectZoomPan(i);
    // find the bar
    let zoom_pan_bar = document.getElementById(`zoom-pan-${i}`);
    // find it's translate data in pixels
    let transform_data = zoom_pan_bar.style.transform;  // translate3d(698px, 0px, 0px)
    // fetch the pixels from string
    let pixes_left = parseInt(transform_data.split('px')[0].split('(')[1]);
    // find the start time wrt pixel position
    let new_start_time = (pixes_left * this.videoDuration) / this.timeline_child_div_width;
    // fix the precision
    let new_start_time_two_decimal = parseFloat(new_start_time.toFixed(2));
    // update the audio object
    let slack = new_start_time_two_decimal - this.zoomPans[i].data[0];
    this.zoomPans[i].data[0] = new_start_time_two_decimal;
    this.zoomPans[i].data[1] = this.zoomPans[i].data[1] + slack;
    // sort them
    this.player.currentTime = this.zoomPans[i].data[0];
    this.sortZoomPans();
  }

  // user is sliding the draw box bar
  drawBoxTimeChanged(i: number){
    // i: index of the object
    this.selectDrawBox(i);
    // find the bar
    let draw_box_bar = document.getElementById(`draw-box-bar-${i}`);
    // find it's translate data in pixels
    let transform_data = draw_box_bar.style.transform;  // translate3d(698px, 0px, 0px)
    // fetch the pixels from string
    let pixes_left = parseInt(transform_data.split('px')[0].split('(')[1]);
    // find the start time wrt pixel position
    let new_start_time = (pixes_left * this.videoDuration) / this.timeline_child_div_width;
    // fix the precision
    let new_start_time_two_decimal = parseFloat(new_start_time.toFixed(2));
    // update the box object
    let slack = new_start_time_two_decimal - this.drawBoxes[i].data[0];
    this.drawBoxes[i].data[0] = new_start_time_two_decimal;
    this.drawBoxes[i].data[1] = this.drawBoxes[i].data[1] + slack;
    // sort them
    this.player.currentTime = this.drawBoxes[i].data[0];
    this.sortDrawBoxes();
  }

  // user is sliding the audio bar
  audioTimeChanged(i: number){
    // i: index of the object
    this.pauseVideo();
    // find the bar
    let audio_overlay_bar = document.getElementById(`audio-overlay-${i}`);
    // find it's translate data in pixels
    let transform_data = audio_overlay_bar.style.transform;  // translate3d(698px, 0px, 0px)
    // fetch the pixels from string
    let pixes_left = parseInt(transform_data.split('px')[0].split('(')[1]);
    // find the start time wrt pixel position
    let new_start_time = (pixes_left * this.videoDuration) / this.timeline_child_div_width;
    // fix the precision
    let new_start_time_two_decimal = parseFloat(new_start_time.toFixed(2));
    // update the audio object
    let slack = new_start_time_two_decimal - this.audios[i].start_time;
    this.audios[i].start_time = new_start_time_two_decimal;
    this.audios[i].end_time = this.audios[i].end_time + slack;
    // send update call of start time
    this.updateAudioTimeInBE(this.audios[i].id, new_start_time_two_decimal);
  }

  // user is sliding the image overlay bar
  imageTimeChanged(i: number){
    // i: index of the object
    this.selectImage(i);
    // find the bar
    let image_overlay_bar = document.getElementById(`image-overlay-bar-${i}`);
    // find it's translate data in pixels
    let transform_data = image_overlay_bar.style.transform;  // translate3d(698px, 0px, 0px)
    // fetch the pixels from string
    let pixes_left = parseInt(transform_data.split('px')[0].split('(')[1]);
    // find the start time wrt pixel position
    let new_start_time = (pixes_left * this.videoDuration) / this.timeline_child_div_width;
    // fix the precision
    let new_start_time_two_decimal = parseFloat(new_start_time.toFixed(2));
    // update the audio object
    let slack = new_start_time_two_decimal - this.images[i].from;
    this.images[i].from = new_start_time_two_decimal;
    this.images[i].to = this.images[i].to + slack;
    this.images[i].changed = true;
    // sort them
    this.player.currentTime = this.images[i].from;
    this.sortImages();
  }

  audioVolumeChanged(event, i: number) {
    // update the player
    // console.log(event.value)
    // send update call
    this.dataService.patchURL(`user/audios/${this.audios[i].id}/`,
      {volume: event.value},
      {observe: 'body', responseType: 'json'}).subscribe(() => {
      // success
      // although processed file still exists, the state is changed to UP
      this.video_state = "UP";
    }, (err) => {
      console.error(err);
    });
  }

  selectAudio(i: number){
    // pause video first, so that remaining audios are not selected due to time update method
    this.pauseVideo();
    // go to tab 1
    this.selected_tab.setValue(0);
    // deselect all audios
    this.audios.map(e => e.selected = false);
    this.audios.map(e => e.expanded = false);
    // select this one
    this.audios[i].selected = true;
    this.audios[i].expanded = true;
    // go to that player time
    this.player.currentTime = this.audios[i].start_time;

    // bring that audio element into focus
    // document.getElementById(`audio-list-item-${i}`).scrollIntoView({behavior: 'smooth'});
  }

  selectImage(i: number){
    // go to tab
    this.selected_tab.setValue(4);
    // deselect all existing ones
    this.drawBoxes.map(element => element.selected = false);
    this.images.map(element => element.selected = false);

    this.images[i].selected = true;
    this.pauseVideo();
    // seek to proper time
    if (this.player.currentTime < this.images[i].from || this.player.currentTime > this.images[i].to) {
      this.player.currentTime = this.images[i].from;
    }
  }

  deleteAudio(id: number) {
    // id: id of audio being deleted
    this.dataService.deleteURL(`user/audios/${id}/`).subscribe(() => {
      // this.audio_players.item(i).remove();
      // reload all audios
      // although processed file still exists, the state is changed to UP
      this.video_state = "UP";
      setTimeout(() => {
        // reload after a while
        this.reloadAudios();
      }, 200)
    });
    // since above call is not blocking, next line will execute immediately and user would see an immediate response
    // do not bring this call into above block, otherwise deleteAllAudios will go into inf loop
    // remove from audios
    const index: number = this.audios.findIndex(x => x.id == id);
    this.audios.splice(index, 1);
  }

  deleteImage(i: number) {
    // i: index of image being deleted, not the id
    this.dataService.deleteURL(`user/images/${this.images[i].id}/`).subscribe(() => {
      this.video_state = "UP";
    });
    this.images.splice(i, 1);
  }

  // this method sends a post call to split the current audio into two parts
  splitAudio(a: Audio) {
    if (this.currentTempTime <= a.start_time) {
      // much before
      return;
    }
    if (this.currentTempTime >= a.end_time) {
      // much further
      return;
    }
    this.pauseVideo();
    const current_time = this.toHHMMSS(this.currentTempTime);
    let message: string = this.translateService.instant("Split the audio at t?", {t: current_time});
    if (window.confirm(message)) {
      // open the spinner
      const spinnerDialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });

      // send the post call to split an audio into two
      this.dataService.postURL(`user/audios/${a.id}/`, {
        "split_time": this.currentTempTime,
      }, {observe: 'body', responseType: 'json'}).subscribe((res: any[]) => {
        for (let i of res) {
          const st = parseFloat(i.start_time);
          const et = parseFloat(i.end_time);
          this.audios.push({
            id: i.id,
            start_time: st,
            end_time: et,
            volume: parseFloat(i.volume),
            audio_file: i.audio_file,
            selected: false,
            expanded: false,
            text: i.text,
            duration: et - st,
          })
        }
        this.deleteAudio(a.id);
        spinnerDialogRef.close();
      }, (error: HttpErrorResponse) => {
        console.error(error);
        spinnerDialogRef.close();
      })
    }
  }

  // this method creates a new audio OV from the video itself
  detachAudioFromVideo() {
    let message = this.translateService.instant("This detaches the original audio from the video and adds it as a new audio overlay.");
    message += '\n\n';
    message += this.translateService.instant("Would you like to continue?");
    if (window.confirm(message)) {
      const spinnerDialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });

      this.dataService.deleteURL(`user/videos/${this.video_id}/audios/`, {observe: 'body', responseType: 'json'})
        .subscribe((i: any) => {
          // success, but do not show snackbar as transcribing snackbar will come immediately
          const st = parseFloat(i.start_time);
          const et = parseFloat(i.end_time);
          this.audios.push({
            id: i.id,
            start_time: st,
            end_time: et,
            volume: parseFloat(i.volume),
            audio_file: i.audio_file,
            selected: false,
            expanded: false,
            text: i.text,
            duration: et - st,
          });
          // select the last audio
          this.video_state = "UP";
          this.selectAudio(this.audios.length - 1);
          // set the audio volume to zero
          this.audio_vol = 0;
          this.transcribeOverlayAudio(i.id);
        }, (err: HttpErrorResponse) => {
          console.error(err);
          this.snackBar.open('Failed to detach audio', '', {duration: 2000});
        }, () => {
          spinnerDialogRef.close();
        })
    }
  }

  // this method downloads audio in whatever format it is
  downloadAudio(a: Audio) {
    this.httpClient.get(a.audio_file, { responseType: 'blob' }).subscribe(blob => {
      const downloadLink = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      downloadLink.href = objectUrl;
      // revamp the file name, replace everything except characters
      let file_name = a.text.slice(0, 50).trim();
      file_name = file_name.replace(/[^a-zA-Z]/g, '_');
      file_name += '.mp3';
      downloadLink.download = file_name;
      // Trigger the download by simulating a click
      downloadLink.click();
      // Clean up by revoking the object URL after the download starts
      URL.revokeObjectURL(objectUrl);
    }, error => {
      console.error('Download failed:', error);
    });
  }

  // this method transcribes a particular ov audio, if company allows it
  transcribeOverlayAudio(id: number){
    if (!this.authService.company.is_transcription_service_enabled) {
      return;
    }
    // show the progress bar
    setTimeout(() => {
      // wait for a while as element may not be ready
      document.getElementById(`audio-controls-${id}`).classList.add('d-none');
      document.getElementById(`audio-spinner-${id}`).classList.add('d-flex');
    }, 300);

    // show snackbar before transcribing
    this.snackBar.open(this.translateService.instant('Transcribing'), '', {duration: 2000});

    this.dataService.getURL(`user/audios/${id}/`, {observe: 'body', responseType: 'text'})
      .subscribe((res: string) => {
        // find audio and update text
        this.audios.find(e => e.id == id).text = res;
        // show snackbar after it is complete
        this.snackBar.open(this.translateService.instant('Transcription completed'), '', {duration: 2000});
        // hide the spinner
        document.getElementById(`audio-controls-${id}`).classList.remove('d-none');
        document.getElementById(`audio-spinner-${id}`).classList.remove('d-flex');
      }, (e: HttpErrorResponse) => {
        console.error(e);
        // hide the spinner
        document.getElementById(`audio-controls-${id}`).classList.remove('d-none');
        document.getElementById(`audio-spinner-${id}`).classList.remove('d-flex');
      });
  }

  loadOverlayWave(i: number){
    // create wave for that audio
    let ws = this.waveSurferService.create({
      container: `#audio-overlay-${i}`,
      cursorColor: 'transparent',
      interact: false,
      height: 20,
      barWidth: 1,
      barGap: 1,
      barHeight: 1,
      waveColor: "gray",
      backgroundColor: 'lightcyan',
      responsive: true,
      removeMediaElementOnDestroy: true,
      partialRender: true,
      // normalize: true,
    });
    ws.load(this.audios[i].audio_file);
  }

  needHelp() {
    window.open('https://clypp.app/pages/view/8d1130a3-0186-4e03-ac75-8649359666a6', '_blank');
  }

  toggleTimelineZoom() {
    // make outer div scrollable
    const timeline_parent_div = document.getElementById('timeline-parent-div');
    // make inner div bigger
    const timeline_child_div = document.getElementById('timeline-child-div');
    // make wave objects longer or shorter
    const wave_objects = document.getElementsByTagName('wave');
    if (this.zoom_mode == 'zoom_in') {
      this.zoom_mode = 'zoom_out';
      timeline_parent_div.style.overflowX = 'scroll';

      // make it 10 times
      timeline_child_div.style.width = "1000%";
      this.filmstrip_src = this.video_object.filmstrip_large;
      // transform all wave tags
      for (let i = 0; i < wave_objects.length; i++) {
        wave_objects[i]['style'].transform = 'scaleX(10)';
      }
    } else {
      this.zoom_mode = 'zoom_in';
      timeline_child_div.style.removeProperty('width');
      timeline_parent_div.style.overflowX = 'hidden';
      this.filmstrip_src = this.video_object.filmstrip_small;
      // transform all wave tags
      for (let i = 0; i < wave_objects.length; i++) {
        wave_objects[i]['style'].transform = 'scaleX(1)';
      }
    }
  }

  fullscreen() {
    if (this.videoContainer.requestFullscreen) {
      this.videoContainer.requestFullscreen();
    } else if ((this.video as any).mozRequestFullScreen) {
      (this.videoContainer as any).mozRequestFullScreen();
    } else if ((this.video as any).webkitRequestFullscreen) {
      (this.videoContainer as any).webkitRequestFullscreen();
    } else if ((this.video as any).msRequestFullscreen) {
      (this.videoContainer as any).msRequestFullscreen();
    }
  }

  ngOnDestroy() {
    // this.waveSurfer.destroy();
    // this.audioOverwriteWave.destroy();
    clearTimeout(this.timeoutHandler);
    clearInterval(this.auto_save_interval);
  }

  addText(){
    let to_time = this.player.currentTime + 5;
    if (to_time > this.videoDuration)
      to_time = this.videoDuration;

    let box: number = 1;
    if (sessionStorage.getItem('box')){
      box = parseInt(sessionStorage.getItem('box'));
    }

    let fontcolor: string = '#000000';
    if (sessionStorage.getItem('fontcolor')){
      fontcolor = sessionStorage.getItem('fontcolor');
    }

    let boxcolor: string = '#ffffff';
    if (sessionStorage.getItem('boxcolor')){
      boxcolor = sessionStorage.getItem('boxcolor');
    }

    let fontsize: string = 'medium';
    if (sessionStorage.getItem('fontsize')){
      fontsize = sessionStorage.getItem('fontsize');
    }

    this.texts.push({
      box: box,
      fontcolor: fontcolor,
      boxcolor: boxcolor,
      text: `Text ${this.texts.length + 1}`,
      fontsize: fontsize, // small medium large
      x: 0,
      y: 0,
      prev_x: 0,
      prev_y: 0,
      from: parseFloat(this.player.currentTime.toFixed(2)),
      to: parseFloat(to_time.toFixed(2)),
      selected: false
    });
    this.selectText(this.texts.length - 1);
    this.sortTexts();
  }

  addBlur() {
    let to_time = this.player.currentTime + 5;
    if (to_time > this.videoDuration)
      to_time = this.videoDuration;

    this.blurs.push({
      x: 0,
      y: 0,
      prev_x: 0,
      prev_y: 0,
      from: parseFloat(this.player.currentTime.toFixed(2)),
      to: parseFloat(to_time.toFixed(2)),
      width: Math.floor(this.player.videoWidth / 4),
      height: Math.floor(this.player.videoHeight / 4),
      blur: 20,
      selected: false
    });
    this.selectBlur(this.blurs.length - 1);
    this.sortBlurs();
  }

  addDrawBox(){
    let to_time = this.player.currentTime + 5;
    if (to_time > this.videoDuration)
      to_time = this.videoDuration;

    let drawBoxColor = sessionStorage.getItem('drawBoxColor');
    if (drawBoxColor){
      // valid color, pass
    } else {
      // default color primary
      drawBoxColor = this.authService.company.primary_color;
    }

    const thickness = 10;  // pixels
    const alpha = 1;  // max 1

    // [start, end, x, y, w, h, color, alpha, thickness]
    this.drawBoxes.push({
      data: [
        parseFloat(this.player.currentTime.toFixed(2)),
        parseFloat(to_time.toFixed(2)),
        0, 0, 400, 300, drawBoxColor, alpha, thickness
      ],
      selected: false
    });
    this.selectDrawBox(this.drawBoxes.length - 1);
    this.sortDrawBoxes();
  }

  addZoomPan(){
    let to_time = this.player.currentTime + 5;
    if (to_time > this.videoDuration)
      to_time = this.videoDuration;

    this.zoomPans.push({
      data: [
        parseFloat(this.player.currentTime.toFixed(2)),
        parseFloat(to_time.toFixed(2)),
        0, 0, 2, true
      ],
      selected: false
    });
    this.selectZoomPan(this.zoomPans.length - 1);
    this.sortZoomPans();
  }

  selectDrawBox(index: number){
    this.selected_tab.setValue(4);
    // deselect all draw-boxes
    this.drawBoxes.map(element => element.selected = false);
    // deselect all images
    this.images.map(element => element.selected = false);
    // select current one
    this.drawBoxes[index].selected = true;
    this.pauseVideo();
    // seek to proper time
    if (this.player.currentTime < this.drawBoxes[index].data[0] || this.player.currentTime > this.drawBoxes[index].data[1]) {
      this.player.currentTime = this.drawBoxes[index].data[0];  // start
    }
  }

  selectZoomPan(index: number){
    this.selected_tab.setValue(3);
    // deselect all zooms
    this.zoomPans.map(element => element.selected = false);
    // select current one
    this.zoomPans[index].selected = true;
    this.pauseVideo();
    // seek to proper time
    if (this.player.currentTime < this.zoomPans[index].data[0] || this.player.currentTime > this.zoomPans[index].data[1]) {
      this.player.currentTime = this.zoomPans[index].data[0];  // start
    }
  }

  selectText(index: number) {
    // deselect existing one
    this.selected_tab.setValue(2);

    let currently_selected_blur_index = this.texts.findIndex(element => element.selected == true);
    if (currently_selected_blur_index > -1) {
      this.texts[currently_selected_blur_index].selected = false;
    }

    this.texts[index].selected = true;
    this.pauseVideo();
    // seek to proper time
    if (this.player.currentTime < this.texts[index].from || this.player.currentTime > this.texts[index].to) {
      this.player.currentTime = this.texts[index].from;
    }
  }

  selectBlur(index: number) {
    // deselect existing one
    this.selected_tab.setValue(1);

    let currently_selected_blur_index = this.blurs.findIndex(element => element.selected == true);
    if (currently_selected_blur_index > -1) {
      this.blurs[currently_selected_blur_index].selected = false;
    }

    this.blurs[index].selected = true;
    this.pauseVideo();
    // seek to proper time
    if (this.player.currentTime < this.blurs[index].from || this.player.currentTime > this.blurs[index].to) {
      this.player.currentTime = this.blurs[index].from;
    }

    // scroll into view of that blur
    // document.getElementById(`blur-expansion-panel-${index}`).scrollIntoView({behavior: 'smooth'});
  }

  getVideoThumbnails = async (src: any) => {
    const vid: HTMLVideoElement = document.createElement('video');
    vid.src = src;
    vid.muted = true;
    vid.preload = 'auto';
    vid.load();

    this.numberOfPics = Math.ceil(this.timeline_child_div_width / 102);
    let timePicTaken = this.videoDuration / this.numberOfPics;

    let ended: boolean = false;
    let time: number = 0;
    let index = 0;

    // here event
    vid.addEventListener('timeupdate', (load: any) => {
      if (vid.currentTime > 0 && ended === false) {
        createVideoThumbnail();
      }
      loadTime();
    });

    vid.addEventListener('loadedmetadata', (load: any) => {
      loadTime();
    });

    vid.addEventListener('ended', (load: any) => {
      ended = true;
    }, false);

    const loadTime = async () => {
      if (ended === false) {
        if (time > vid.duration) {
          vid.currentTime = vid.duration;
          ended = true;
        } else {
          vid.currentTime = time;
          time += timePicTaken;
          this.picIndex = index++;
        }
      }
    };

    const createVideoThumbnail: any = async () => {
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      const ctx: CanvasRenderingContext2D = canvas.getContext('2d', {alpha: false});
      canvas.width = 102;
      canvas.height = 60;
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);
      this.timeline.nativeElement.appendChild(canvas);
      if (index == this.numberOfPics) {
        this.timeline.nativeElement.style.justifyContent = 'space-between'
      }
    };

    vid.remove();
  }

  zoomBoxDropped(index: number){
    let element = document.getElementById('zoom-box-'+index);
    let elementRect = element.getBoundingClientRect();
    let parent = document.getElementById('text-blur-div');
    let parentRect = parent.getBoundingClientRect();

    // find the new position of the dropped box
    let new_x = elementRect.x - parentRect.x;
    let new_y = elementRect.y - parentRect.y;

    // save relative position
    this.zoomPans[index].data[2] = parseInt((new_x * this.videoUpscaleWidthFactor).toFixed());  // x
    this.zoomPans[index].data[3] = parseInt((new_y * this.videoUpscaleHeightFactor).toFixed());  // y
  }

  drawBoxDropped(index: number){
    let element = document.getElementById('draw-box-'+index);
    let elementRect = element.getBoundingClientRect();
    let parent = document.getElementById('text-blur-div');
    let parentRect = parent.getBoundingClientRect();

    // find the new position of the dropped box
    let new_x = elementRect.x - parentRect.x;
    let new_y = elementRect.y - parentRect.y;

    // save relative position
    this.drawBoxes[index].data[2] = parseInt((new_x * this.videoUpscaleWidthFactor).toFixed());  // x
    this.drawBoxes[index].data[3] = parseInt((new_y * this.videoUpscaleHeightFactor).toFixed());  // y
  }

  textBoxDropped(index: number) {
    // find the new position of the dropped box
    let current_box = document.getElementById('text-box-' + index);

    // parse the translation data and make it int
    // translate3d(355px, 177px, 0px)
    let translation_data = current_box.style.transform.split('(')[1];
    let x_y_arrays = translation_data.split('px, ');
    let x_moved = parseInt(x_y_arrays[0]); // parseInt returns an integer from either string or a number
    let y_moved = parseInt(x_y_arrays[1]);

    // update the array
    // toFixed makes float an integer-string, parseInt makes and integer-string to integer
    this.texts[index].x = parseInt((this.texts[index].prev_x + (x_moved * this.videoUpscaleWidthFactor)).toFixed());
    this.texts[index].y = parseInt((this.texts[index].prev_y + (y_moved * this.videoUpscaleHeightFactor)).toFixed());
  }

  blurBoxDropped(index: number) {
    // find the new position of the dropped box
    let current_box = document.getElementById('blur-box-' + index);

    // parse the translation data and make it int
    // translate3d(355px, 177px, 0px)
    let translation_data = current_box.style.transform.split('(')[1];
    let x_y_arrays = translation_data.split('px, ');
    let x_moved = parseInt(x_y_arrays[0]);
    let y_moved = parseInt(x_y_arrays[1]);

    // update the array
    // toFixed makes float an integer-string, parseInt makes and integer-string to integer
    this.blurs[index].x = parseInt((this.blurs[index].prev_x + (x_moved * this.videoUpscaleWidthFactor)).toFixed());
    this.blurs[index].y = parseInt((this.blurs[index].prev_y + (y_moved * this.videoUpscaleHeightFactor)).toFixed());
  }

  imageOverlayDropped(index: number) {
    // find the new position of the dropped box
    let current_box = document.getElementById('image-overlay-' + index);

    // parse the translation data and make it int
    // translate3d(355px, 177px, 0px)
    let translation_data = current_box.style.transform.split('(')[1];
    let x_y_arrays = translation_data.split('px, ');
    let x_moved = parseInt(x_y_arrays[0]);
    let y_moved = parseInt(x_y_arrays[1]);

    // update the array
    // toFixed makes float an integer-string, parseInt makes and integer-string to integer
    this.images[index].x_pos = this.images[index].prev_x + (x_moved * this.videoUpscaleWidthFactor);
    this.images[index].y_pos = this.images[index].prev_y + (y_moved * this.videoUpscaleHeightFactor);
    this.images[index].changed = true;
  }

  blurBoxResized(event, index: number){
    let x_current = event.pointerPosition.x + 12;
    let y_current = event.pointerPosition.y + 12;

    // find the video player. This will be used to standardize the data by calculating offsets
    const video_player = document.getElementById('text-blur-div');
    const video_player_rectangle = video_player.getBoundingClientRect()
    const video_player_x = video_player_rectangle.x;
    const video_player_y = video_player_rectangle.y;

    // ensure that x and y do not exceed the video itself (because we added 12px above)
    if (x_current > video_player_rectangle.width + video_player_x) {
      x_current = Math.floor(video_player_rectangle.width + video_player_x);
    }
    if (y_current > video_player_rectangle.height + video_player_y) {
      y_current = Math.floor(video_player_rectangle.height + video_player_y);
    }

    // find the position of the existing box. This will be used to adjust width and height
    let box_offset_x = this.blurs[index].x / this.videoUpscaleWidthFactor;
    let box_offset_y = this.blurs[index].y / this.videoUpscaleHeightFactor;

    let new_offset_x = x_current - video_player_x;
    let new_offset_y = y_current - video_player_y;

    // if new offset is greater than the old one, then just update the height or width
    if(new_offset_x > box_offset_x){
      if((new_offset_x - box_offset_x) > 40){
        // a box must be 40px wide
        // toFixed makes float an integer-string, parseInt makes and integer-string to integer
        this.blurs[index].width = parseInt(((new_offset_x - box_offset_x) * this.videoUpscaleWidthFactor).toFixed());
      }
    }
    // currently, we ignore the else case, so user needs to move the block first

    if(new_offset_y > box_offset_y){
      if((new_offset_y - box_offset_y) > 25){
        // a box must be 25px tall
        // toFixed makes float an integer-string, parseInt makes and integer-string to integer
       this.blurs[index].height = parseInt(((new_offset_y - box_offset_y) * this.videoUpscaleHeightFactor).toFixed());
      }
    }
    // currently, we ignore the else case, so user needs to move the block first

    // block the transform of arrow, otherwise arrow will fly away
    let resize_icon = document.getElementById('resize-icon-' + index);
    resize_icon.style.transform = 'none';
  }

  ImageOverlayResized(event, index: number){
    let x_current = event.pointerPosition.x + 12;
    let y_current = event.pointerPosition.y + 12;

    // find the video player. This will be used to standardize the data by calculating offsets
    const video_player = document.getElementById('text-blur-div');
    const video_player_rectangle = video_player.getBoundingClientRect()
    const video_player_x = video_player_rectangle.x;
    const video_player_y = video_player_rectangle.y;

    // ensure that x and y do not exceed the video itself (because we added 12px above)
    if (x_current > video_player_rectangle.width + video_player_x) {
      x_current = Math.floor(video_player_rectangle.width + video_player_x);
    }
    if (y_current > video_player_rectangle.height + video_player_y) {
      y_current = Math.floor(video_player_rectangle.height + video_player_y);
    }

    // find the position of the existing box. This will be used to adjust width and height
    let box_offset_x = this.images[index].x_pos / this.videoUpscaleWidthFactor;
    let box_offset_y = this.images[index].y_pos / this.videoUpscaleHeightFactor;

    let new_offset_x = x_current - video_player_x;
    let new_offset_y = y_current - video_player_y;

    // if shift is pressed, then lock aspect ratio
    if (this.is_shift_pressed) {
      // find the current image's aspect ratio
      const aspectRatio = this.images[index].width / this.images[index].height;

      if (aspectRatio > 1) {
        // width is more, auto change height
        // if new offset is greater than the old one, then just update the height or width
        if (new_offset_x > box_offset_x) {
          if ((new_offset_x - box_offset_x) > 50) {
            // a box must be 50px wide
            // toFixed makes float an integer-string, parseInt makes and integer-string to integer
            this.images[index].width = parseInt(((new_offset_x - box_offset_x) * this.videoUpscaleWidthFactor).toFixed());
          }
        }
        // now auto update height
        this.images[index].height = this.images[index].width / aspectRatio;
      } else {
        // height is more, auto update width
        if (new_offset_y > box_offset_y) {
          if ((new_offset_y - box_offset_y) > 50) {
            // a box must be 50px tall
            // toFixed makes float an integer-string, parseInt makes and integer-string to integer
            this.images[index].height = parseInt(((new_offset_y - box_offset_y) * this.videoUpscaleHeightFactor).toFixed());
          }
        }
        // now auto update width
        this.images[index].width = this.images[index].height * aspectRatio;
      }
    } else {
      // shift is not pressed, free the aspect ratio
      // if new offset is greater than the old one, then just update the height or width
      if (new_offset_x > box_offset_x) {
        if ((new_offset_x - box_offset_x) > 50) {
          // a box must be 50px wide
          // toFixed makes float an integer-string, parseInt makes and integer-string to integer
          this.images[index].width = (new_offset_x - box_offset_x) * this.videoUpscaleWidthFactor;
        }
      }
      // currently, we ignore the else case, so user needs to move the block first

      if (new_offset_y > box_offset_y) {
        if ((new_offset_y - box_offset_y) > 50) {
          // a box must be 25px tall
          // toFixed makes float an integer-string, parseInt makes and integer-string to integer
          this.images[index].height = (new_offset_y - box_offset_y) * this.videoUpscaleHeightFactor;
        }
      }
    }

    // block the transform of arrow, otherwise arrow will fly away
    let resize_icon = document.getElementById('im-ov-resize-icon-' + index);
    resize_icon.style.transform = 'none';
    this.images[index].changed = true;
  }

  drawBoxResized(event, index: number){
    let x_current = event.pointerPosition.x + 12;
    let y_current = event.pointerPosition.y + 12;

    // find the video player. This will be used to standardize the data by calculating offsets
    const video_player = document.getElementById('text-blur-div');
    const video_player_rectangle = video_player.getBoundingClientRect()
    const video_player_x = video_player_rectangle.x;
    const video_player_y = video_player_rectangle.y;

    // ensure that x and y do not exceed the video itself (because we added 12px above)
    if (x_current > video_player_rectangle.width + video_player_x) {
      x_current = Math.floor(video_player_rectangle.width + video_player_x);
    }
    if (y_current > video_player_rectangle.height + video_player_y) {
      y_current = Math.floor(video_player_rectangle.height + video_player_y);
    }

    // find the position of the existing box. This will be used to adjust width and height
    let box_offset_x = this.drawBoxes[index].data[2] / this.videoUpscaleWidthFactor;
    let box_offset_y = this.drawBoxes[index].data[3] / this.videoUpscaleHeightFactor;

    let new_offset_x = x_current - video_player_x;
    let new_offset_y = y_current - video_player_y;

    // if new offset is greater than the old one, then just update the height or width
    if(new_offset_x > box_offset_x){
      if((new_offset_x - box_offset_x) > 40){
        // a box must be 40px wide
        // toFixed makes float an integer-string, parseInt makes and integer-string to integer
        this.drawBoxes[index].data[4] = parseInt(((new_offset_x - box_offset_x) * this.videoUpscaleWidthFactor).toFixed());
      }
    }
    // currently, we ignore the else case, so user needs to move the block first

    if(new_offset_y > box_offset_y){
      if((new_offset_y - box_offset_y) > 25){
        // a box must be 25px tall
        // toFixed makes float an integer-string, parseInt makes and integer-string to integer
       this.drawBoxes[index].data[5] = parseInt(((new_offset_y - box_offset_y) * this.videoUpscaleHeightFactor).toFixed());
      }
    }
    // currently, we ignore the else case, so user needs to move the block first

    // block the transform of arrow, otherwise arrow will fly away
    let resize_icon = document.getElementById('draw-box-resize-icon-' + index);
    resize_icon.style.transform = 'none';
  }

  deleteBlur(index: number) {
    this.blurs.splice(index, 1);
  }

  deleteZoomPan(index: number) {
    this.zoomPans.splice(index, 1);
  }

  deleteDrawBox(index: number) {
    this.drawBoxes.splice(index, 1);
  }

  deleteText(index: number) {
    this.texts.splice(index, 1);
  }

  // onResize() {
  //   // reset the height and width for the boundaries on window resize
  //   let style = document.createElement('style');
  //   style.innerHTML = `.text-blur-boundary {height: ${this.player.clientHeight}px; width: ${this.player.clientWidth}px;}`;
  //   document.getElementsByTagName('head')[0].appendChild(style);
  // }

  textAreaFocusIn(){
    // this will stop the keyboard event listener
    this.is_text_area_active = true;
  }

  textAreaFocusOut(){
    // this will resume the keyboard event listener
    this.is_text_area_active = false;
    // below is needed if user is editing the time
    this.is_user_editing_current_time = false
  }

  // this method accepts an input in a prompt and creates TTS out of it, and then adds a new audio overlay
  addTextToSpeech(){
    this.pauseVideo();
    this.textAreaFocusIn();
    const dialogRef = this.dialog.open(TtsDialog, {
      minWidth: '350px',
      width: '50vw',
      disableClose: false,
      data: {
        duration: this.videoDuration,
        script: this.script, // video script is passed so that user could take reference
        text: '',
        show_text_input: true,
        start_time: this.player.currentTime,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // result contains text and voice_name
      if (result) {
        // show spinner
        const spinnerDialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
          this.dialog.open(ProgressSpinnerDialogComponent, {
            panelClass: 'transparent',
            disableClose: true,
          });

        // now split each line, only if user has said so
        if (result.split_sentences) {
          let paragraph: string = result.text;
          // https://stackoverflow.com/a/25736082
          paragraph = paragraph.replace(/(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<=\.|\?)\s/gm, '\n');
          this.paragraph_lines = paragraph.split('\n');
        } else {
          this.paragraph_lines = [result.text];
        }
        // trim input
        this.paragraph_lines.reduce(note => note.trim());
        // clear empty lines
        this.paragraph_lines = this.paragraph_lines.filter(e => e != '');

        let formData = {
          text: result.text,  // irrelevant
          translate: result.translate,
          voice_name: result.voice_name,
          to_lang: result.to_lang,
          start_time: result.start_time,  // irrelevant
          base_duration: this.videoDuration.toFixed(2),
          speed: result.speed
        };
        this.start_times = [result.start_time];
        this.createAudioOverlays(formData, spinnerDialogRef);
      }
      // result or no result, this will start the keyboard event listener
      this.textAreaFocusOut();
    });
  }

  // open tts dialog and apply that operation on all audios
  updateAllAudios() {
    this.pauseVideo();
    this.textAreaFocusIn();

    // inform user first
    const warning = this.translateService.instant(
      "Your existing audio file would be deleted and a new one in selected voice would be created."
    );

    // ask for a specific language
    const dialogRef = this.dialog.open(TtsDialog, {
      minWidth: '350px',
      width: '50vw',
      disableClose: false,
      data: {
        duration: this.videoDuration,
        script: warning, // send warning in script
        text: 'sample',
        show_text_input: false,
        start_time: 0  // irrelevant
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.textAreaFocusOut();
      // result contains text and voice_name
      if (result) {
        // id of audios to be deleted after-wards
        const ids: number[] = [];

        // show spinner
        const spinnerDialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
          this.dialog.open(ProgressSpinnerDialogComponent, {
            panelClass: 'transparent',
            disableClose: true,
          });
        spinnerDialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            // true: all are done, delete all previous audios
            for (let id of ids) {
              this.deleteAudio(id);
            }
          } // else, false, some error occurred, do not delete prev audios
        })

        this.paragraph_lines = [];
        this.start_times = [];

        // first sort them
        this.audios.sort((a, b) => a.start_time - b.start_time);
        // prepare a copy
        for (let a of this.audios) {
          this.paragraph_lines.push(a.text);
          this.start_times.push(a.start_time);
          ids.push(a.id);
        }

        const formData = {
          text: '',  // irrelevant
          translate: result.translate,
          voice_name: result.voice_name,
          to_lang: result.to_lang,
          start_time: 0,  // irrelevant
          base_duration: this.videoDuration.toFixed(2),
          speed: result.speed
        };

        this.createAudioOverlays(formData, spinnerDialogRef);
      }
    });
  }

  // open tts dialog and send a patch call, then set video volume to 0
  convertToAIVoice(){
    this.pauseVideo();
    this.textAreaFocusIn();

    let warning = this.translateService.instant(
      "Convert the original audio to a synthetic voice in the language of your choice."
    );
    warning += '\n';
    warning += this.translateService.instant('This does not affect existing audio overlays.')
    const dialogRef = this.dialog.open(TtsDialog, {
      minWidth: '350px',
      width: '50vw',
      disableClose: false,
      data: {
        duration: this.videoDuration,
        script: warning, // send warning in script
        text: 'sample',
        show_text_input: false,
        start_time: 0  // irrelevant
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.textAreaFocusOut();
      // result contains text and voice_name
      if (result) {
        // show spinner
        const spinnerDialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
          this.dialog.open(ProgressSpinnerDialogComponent, {
            panelClass: 'transparent',
            disableClose: true,
          });

        const formData = {
          translate: result.translate,
          voice_name: result.voice_name,
          to_lang: result.to_lang,
          base_duration: this.videoDuration.toFixed(2),
          speed: result.speed
        };

        // send the call
        this.dataService.patchURL(`user/videos/${this.video_id}/audios/`, formData,
          {observe: 'response'}).subscribe(
          (res: any) => {
            spinnerDialogRef.close();
            this.audio_vol = 0;
            // initiate audio overlays, as there should be a lot of new ones
            this.initAudios();
            // no need to reload audios
          }, (err: HttpErrorResponse) => {
            spinnerDialogRef.close();
            console.error(err);
            this.snackBar.open(this.translateService.instant('Ein Fehler ist aufgetreten'),
              '', {duration: 2500});
          });
      }
    });
  }

  editTextToSpeech(audio: Audio){
    // pass its data to TTS dialog and then delete the current audio
    this.pauseVideo();
    this.textAreaFocusIn();

    const warning = this.translateService.instant(
      "Your existing audio file would be deleted and a new one in selected voice would be created."
    );
    const dialogRef = this.dialog.open(TtsDialog, {
      minWidth: '350px',
      width: '50vw',
      disableClose: false,
      data: {
        duration: this.videoDuration,
        script: warning, // send warning in script
        text: audio.text,
        show_text_input: true,
        start_time: audio.start_time
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // result contains text and voice_name
      if (result) {
        // show spinner
        const spinnerDialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
          this.dialog.open(ProgressSpinnerDialogComponent, {
            panelClass: 'transparent',
            disableClose: true,
          });

        spinnerDialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            // delete current audio overlay
            this.deleteAudio(audio.id);
          }
        })

        // now split each line, only if user has said so
        if (result.split_sentences) {
          let paragraph: string = result.text;
          // https://stackoverflow.com/a/25736082
          paragraph = paragraph.replace(/(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<=\.|\?)\s/gm, '\n');
          this.paragraph_lines = paragraph.split('\n');
        } else {
          this.paragraph_lines = [result.text];
        }
        // trim input
        this.paragraph_lines.reduce(note => note.trim());
        // clear empty lines
        this.paragraph_lines = this.paragraph_lines.filter(e => e != '');

        let formData = {
          text: result.text,  // irrelevant
          translate: result.translate,
          voice_name: result.voice_name,
          to_lang: result.to_lang,
          start_time: result.start_time,  // irrelevant
          base_duration: this.videoDuration.toFixed(2),
          speed: result.speed
        };
        this.start_times = [result.start_time];
        this.createAudioOverlays(formData, spinnerDialogRef);
      }

      // result or no result, this will start the keyboard event listener
      this.textAreaFocusOut();
      }
    );
  }

  // another recursive function to create notes until the array is empty
  createAudioOverlays(formData: any, spinnerDialogRef: MatDialogRef<ProgressSpinnerDialogComponent>) {
    const text: string = this.paragraph_lines.shift();
    const start_time: number = this.start_times.shift();

    if (text == undefined) {
      // all are done
      spinnerDialogRef.close(true);
      // TTS adds audio and deletes the processed file
      this.video_state = "UP";
      return;
    }
    formData.text = text;
    formData.start_time = start_time;  // must not be undefined

    // send the data
    this.dataService.putURL(`user/videos/${this.video_id}/audios/`, formData,
      {observe: 'body', responseType: 'json'}).subscribe(
      (res: any) => {
        const st = parseFloat(res.start_time);
        const et = parseFloat(res.end_time);
        this.audios.push({
          id: res.id,
          start_time: st,
          end_time: et,
          volume: parseFloat(res.volume),
          audio_file: res.audio_file,
          selected: false,
          expanded: false,
          text: res.text,
          duration: et - st,
        });
        // update end time
        this.start_times.push(parseFloat(res.end_time) + 0.2);  // add a 200ms gap b/w each overlay
        // create subsequent audio overlays
        this.createAudioOverlays(formData, spinnerDialogRef);
      }, (err) => {
        window.alert(err.error);
        spinnerDialogRef.close(false);
      });
  }

  // this method accepts wav, mp3 or opus files and adds it as a new audio overlay
  uploadAudioFile(audio_file: File = null){
    let formData = new FormData();
    if (audio_file) {
      // user is recording an audio
      formData.append('start_time', this.audio_start_time.toFixed(2));
    } else {
      formData.append('start_time', this.player.currentTime);
      audio_file = document.querySelector('input').files[0];
      if (audio_file.size > 20000000) {
        // 20 MB
        let message = this.translateService.instant("Please select a file under X MB", {
          X: 20
        })
        window.alert(message);
        return;
      }
    }
    formData.append('overlay_audio', audio_file);
    formData.append('volume', '1');
    formData.append('base_duration', this.videoDuration.toFixed(2));

    const spinnerDialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });

    // reset the value of file input to add same file again
    document.querySelector('input').value = '';

    this.dataService.postURL(`user/videos/${this.video_id}/audios/`, formData,
      {observe: 'body', responseType: 'json'}).subscribe(
      (res: any) => {
        const st = parseFloat(res.start_time);
        const et = parseFloat(res.end_time);
        this.audios.push({
          id: res.id,
          start_time: st,
          end_time: et,
          volume: parseFloat(res.volume),
          audio_file: res.audio_file,
          selected: false,
          expanded: false,
          text: res.text,
          duration: et - st,
        });
        // add audio operation deletes the processed file
        this.video_state = "UP";
        // now transcribe that one
        this.transcribeOverlayAudio(res.id);
      }, (err) => {
        window.alert(err.error);
      }, () => {
        spinnerDialogRef.close();
      });
  }

  // shape selected from the dropdown
  shapeSelected(path: string) {
    // download file and upload it
    this.httpClient.get(path, {responseType: 'blob'}).subscribe((res: Blob) => {
      const file_name = path.split('/').pop()
      const file_object = new File([res], file_name, {type: res.type});
      this.uploadImageFile(file_object);
    });
  }

  // this method uploads an image file
  uploadImageFile(image_file: File = null){
    let formData = new FormData();
    if (image_file) {
      // user is selecting existing file
    } else {
      const image_input_element = document.getElementById('image-upload') as HTMLInputElement;
      image_file = image_input_element.files[0];
      if (image_file.size > 2000000) {
        // 2 MB
        let message = this.translateService.instant("Please select a file under X MB", {
          X: 2
        });
        window.alert(message);
        return;
      }
    }
    formData.append('image_file', image_file);
    formData.append('start_time', this.player.currentTime.toFixed(2));
    if (this.player.currentTime + 5 > this.videoDuration) {
      formData.append('end_time', this.videoDuration.toFixed(2));
    } else {
      formData.append('end_time', (this.player.currentTime + 5).toFixed(2));
    }

    formData.append('height', '300');
    formData.append('width', '300');
    formData.append('x_pos', '0');
    formData.append('y_pos', '0');
    formData.append('base_duration', this.videoDuration.toFixed(2));

    const spinnerDialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });

    // reset the value of file input to add same file again
    document.querySelector('input').value = '';

    this.dataService.postURL(`user/videos/${this.video_id}/images/`, formData,
      {observe: 'body', responseType: 'json'}).subscribe(
      (i: any) => {
        this.images.push({
          id: i.id,
          from: parseFloat(i.start_time),
          to: parseFloat(i.end_time),
          height: i.height,
          width: i.width,
          x_pos: i.x_pos,
          y_pos: i.y_pos,
          prev_x: i.x_pos,
          prev_y: i.y_pos,
          image_file: i.image_file,
          selected: false,
          changed: true  // because we're changing height/width below
        });
        // add image operation changes the video state to UP
        this.video_state = "UP";
        // now select last one
        this.selectImage(this.images.length - 1);
        // now correct the aspect ratio
        this.resetImageOverlayAspectRatio(this.images.length - 1);
      }, (err) => {
        window.alert(err.error);
      }, () => {
        spinnerDialogRef.close();
      });
  }

  // reset the aspect ratio of any image overlay
  resetImageOverlayAspectRatio(index: number) {
    // to maintain aspect ratio, we need to load the image
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      // now change the actual one's height width
      if (aspectRatio > 1) {
        // width is more, change height
        this.images[index].height = this.images[index].width / aspectRatio;
      } else {
        // height is more, change width
        this.images[index].width = aspectRatio * this.images[index].height;
      }
      img.remove();
    }
    img.src = this.images[index].image_file;
  }

  // load the face detect model and then start inference on the entire video
  async initializePersonDetector(kind: string): Promise<void> {
    // kind: faces, persons, etc.
    // load it
    try {
      // labels for object detector
      // https://storage.googleapis.com/mediapipe-tasks/object_detector/labelmap.txt
      const vision = await FilesetResolver.forVisionTasks('https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm');
      switch (kind) {
        case 'person':
          this.face_and_object_detector = await ObjectDetector.createFromOptions(vision, {
            baseOptions: {
              modelAssetPath: 'https://storage.googleapis.com/mediapipe-models/object_detector/efficientdet_lite0/float16/1/efficientdet_lite0.tflite',
              delegate: 'GPU'
            },
            scoreThreshold: 0.5,
            categoryAllowlist: ['person'],
            runningMode: 'IMAGE'
          });
          break;
        case 'face':
          this.face_and_object_detector = await FaceDetector.createFromOptions(vision, {
            baseOptions: {
              modelAssetPath: 'https://storage.googleapis.com/mediapipe-models/face_detector/blaze_face_short_range/float16/1/blaze_face_short_range.tflite',
              delegate: 'GPU'
            },
            minDetectionConfidence: 0.6,
            minSuppressionThreshold: 0.3,
            runningMode: 'IMAGE'
          });
          break;
      }
    } catch (error) {
      console.error('Failed to initialize face detector:', error);
      this.snackBar.open('Failed to load model', '', {duration: 2000});
      // enable buttons again
      this.face_and_object_progress = 0;
    }

    if (this.face_and_object_detector) {
      // now detect faces
      this.captureFramesAndDetectFaces();
    }
  }

  // create a new video object and then capture frames and then perform inference
  captureFramesAndDetectFaces() {
    // show the progress bar
    // const progress_bar = document.getElementById('face-blur-progress-bar');
    // progress_bar.classList.add('d-flex');
    const video: HTMLVideoElement = document.createElement('video');
    const interval: number = 0.5;
    video.crossOrigin = 'anonymous';
    video.src = this.videoURL;
    video.muted = true;
    video.preload = 'auto';
    video.load();

    let time = 0;

    const increaseTime = async () => {
      time += interval;  // perform inference every second
      this.face_and_object_progress = time / this.videoDuration * 100;  // update the progress bar
      if (time < this.videoDuration + interval) {
        // we need to perform inference until the last second
        video.currentTime = time;
      }
      else {
        // video is ended
        // progress_bar.classList.remove('d-flex');
        video.remove();
        this.sortBlurs();
        if (this.face_and_object_detector) {
          this.face_and_object_detector.close();
          this.face_and_object_detector = null;
        }
        // show a snackbar
        this.snackBar.open(this.translateService.instant('Erfolgreich'), '', {duration: 2000});
        // enable the blur button and hide the detecting button
        this.face_and_object_progress = 0;
      }
    };

    // create a canvas element in a blocking manner
    const createCanvas = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d', { alpha: false });
      canvas.width = this.player.videoWidth;
      canvas.height = this.player.videoHeight;
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      // Detect faces in the captured frame
      this.detectFaces(canvas, video.currentTime, interval/2);
      // remove the canvas afterward
      canvas.remove();
    };

    // once the video time updates, perform prediction
    video.addEventListener('timeupdate', () => {
      createCanvas();
      increaseTime().then();  // increase the time
    });

    // once the metadata is loaded, start the loop from 1st interval
    video.addEventListener('loadedmetadata', () => {
      // update the time to interval/2, so that inference would be performed starting from the first frame
      time = interval / 2
      video.currentTime = time;  // this will now call the timeupdate handler
    });
  };

  detectFaces(canvas: HTMLCanvasElement, currentTime: number, slack: number = 0.5) {
    try {
      const detections = this.face_and_object_detector.detect(canvas).detections;
      // console.log('Face detections:', detections, detections.length);

      if (currentTime + slack > this.videoDuration) {
        currentTime = this.videoDuration - slack;
      }

      for (let detection of detections) {
        this.blurs.push({
          x: detection.boundingBox.originX,
          y: detection.boundingBox.originY,
          prev_x: detection.boundingBox.originX,
          prev_y: detection.boundingBox.originY,
          from: currentTime - slack,
          to: currentTime + slack,
          width: detection.boundingBox.width,
          height: detection.boundingBox.height,
          blur: 30,  // set a stronger blur
          selected: false
        });
      }
    } catch (error) {
      console.error('Failed to detect faces:', error);
      // may come here if the detector object is closed early
    }
  }

  deleteAllBlurs() {
    const message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      this.blurs = [];
    }
  }

  deleteAllAudios() {
    const message = this.translateService.instant("Bist du sicher?");
    if (window.confirm(message)) {
      // find all ids, as delete method also removes the element from the array, hence we need a temporary copy of ids
      const ids: number[] = this.audios.map(e => e.id);
      for (let id of ids) {
        this.deleteAudio(id);
      }
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
    if (!(this.is_text_area_active || this.isAudioRecordingMode)) {
      switch (event.key) {
        case ' ':
          event.preventDefault();
          if (this.player.paused) {
            this.playVideo();
          } else {
            this.pauseVideo();
          }
          break;
        case '1':
          this.selected_tab.setValue(0);  // go to first tab
          break;
        case '2':
          this.selected_tab.setValue(1);  // go to second tab
          break;
        case '3':
          this.selected_tab.setValue(2);  // go to third tab
          break;
        case '4':
          this.selected_tab.setValue(3);  // go to fourth tab
          break;
        case '5':
          this.selected_tab.setValue(4);  // go to fifth tab
          break;
        case 'b':
        case 'v':
          this.addBlur();
          break;
        case 'c':
          this.splitVideo();
          break;
        case 'd':
          this.deleteSelectedSection();
          break;
        case 'f':
          this.fullscreen();
          break;
        case 'o':
          this.resetTrimmer();
          break;
        case 'r':
          this.restoreSelectedSection();
          break;
        case 's':
          this.addDrawBox();
          break;
        case 't':
          this.addText();
          break;
        case 'z':
          this.addZoomPan();
          break;
        case 'ArrowRight':
          this.forwardVideo();
          break;
        case 'ArrowLeft':
          this.backwardVideo();
          break;
        // case "Esc": // IE/Edge specific value
        // case 'Escape':
        //   this.restoreSelectedSection();
        //   break;
        case 'u':
          this.undoTileAction();
          break;
        case 'Shift':
          this.is_shift_pressed = true;
          break;
      }
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyUpdEvent(event: KeyboardEvent) {
    if (event.key == 'Shift') {
      this.is_shift_pressed = false;
    }
  }

  checkTextBoxTimes(t: Text | Blur | ImageOverlay, event, start: boolean) {
    const text_input: string = event.target.value;
    let new_time: number = NaN;
    if (text_input.includes(':')) {
      // user entered a time event, now check how many separators are there
      const blocks = text_input.split(':');
      if (blocks.length == 2) {
        // mm:ss.x format
        new_time = parseInt(blocks[0]) * 60;
        new_time += parseFloat(blocks[1]);
      } else if (blocks.length == 3) {
        // hh:mm:ss.x format
        new_time = parseInt(blocks[0]) * 3600;
        new_time += parseInt(blocks[1]) * 60;
        new_time += parseFloat(blocks[2]);
      }
    } else if (parseFloat(text_input)) {
      // user entered a simple number or something else
      new_time = parseFloat(text_input);
    }

    if (isNaN(new_time)) {
      // invalid value, bring original value back
      new_time = start ? t.from : t.to;
    }

    if (new_time < 0) {
      new_time = 0;
    } else if (new_time > this.videoDuration) {
      new_time = this.videoDuration;
    }

    event.target.value = this.toHHMMSS(new_time);
    if (start) {
      // user is changing the start time, else end time
      t.from = new_time;
      if (t.to < t.from) {
        t.to = Math.min(t.from + 5, this.videoDuration);
      }
    } else {
      t.to = new_time;
      if (t.to < t.from) {
        t.from = Math.max(t.to - 5, 0);
      }
    }
  }

  updateAudioTimeInBE(id: number, time: number) {
    this.dataService.patchURL(`user/audios/${id}/`,
      {start_time: time, base_duration: this.videoDuration},
      {observe: 'body', responseType: 'json'}).subscribe(() => {
      // success
      // although processed file still exists, the state is changed to UP
      this.video_state = "UP";
      this.sortAudios();
    }, (err) => {
      console.error(err);
    });
  }

  checkAudioTimes(a: Audio, event){
    const text_input: string = event.target.value;
    let new_start_time: number = a.start_time;
    if (text_input.includes(':')) {
      // user entered a time event, now check how many separators are there
      const blocks = text_input.split(':');
      if (blocks.length == 2) {
        // mm:ss.x format
        new_start_time = parseInt(blocks[0]) * 60;
        new_start_time += parseFloat(blocks[1]);
      } else if (blocks.length == 3) {
        // hh:mm:ss.x format
        new_start_time = parseInt(blocks[0]) * 3600;
        new_start_time += parseInt(blocks[1]) * 60;
        new_start_time += parseFloat(blocks[2]);
      }
    } else if (parseFloat(text_input)) {
      // user entered a simple number or something else
      new_start_time = parseFloat(text_input);
    } else {
      new_start_time = NaN;
    }

    if (isNaN(new_start_time)) {
      // invalid value
      event.target.value = this.toHHMMSS(a.start_time);
      return;
    } else if (new_start_time == a.start_time) {
      // same value
      return;
    } else {
      // valid new value
      a.start_time = new_start_time;
    }

    // coming here would mean that the start time has changed
    if (a.end_time - a.start_time != a.duration) {
      // user made the audio bigger or smaller, fix end time
      a.end_time = a.duration + a.start_time;
    }

    if (a.end_time >= this.videoDuration) {
      a.end_time = this.videoDuration;
      a.start_time = a.end_time - a.duration;
    }

    if (a.start_time <= 0) {
      // bring it to front
      a.start_time = 0;
      a.end_time = a.duration;
    }

    // update the display
    event.target.value = this.toHHMMSS(a.start_time);

    // update the BE
    this.updateAudioTimeInBE(a.id, a.start_time);
  }

  // this method checks for both zoom pan or drawbox, as they store start and end time in the first two positions
  checkZoomPanTimes(z: ZoomPan | DrawBox, event, start){
    const text_input: string = event.target.value;
    let new_time: number = NaN;
    if (text_input.includes(':')) {
      // user entered a time event, now check how many separators are there
      const blocks = text_input.split(':');
      if (blocks.length == 2) {
        // mm:ss.x format
        new_time = parseInt(blocks[0]) * 60;
        new_time += parseFloat(blocks[1]);
      } else if (blocks.length == 3) {
        // hh:mm:ss.x format
        new_time = parseInt(blocks[0]) * 3600;
        new_time += parseInt(blocks[1]) * 60;
        new_time += parseFloat(blocks[2]);
      }
    } else if (parseFloat(text_input)) {
      // user entered a simple number or something else
      new_time = parseFloat(text_input);
    }

    if (isNaN(new_time)) {
      // invalid value, bring original value back
      new_time = start ? z.data[0] : z.data[1];
    }

    if (new_time < 0) {
      new_time = 0;
    } else if (new_time > this.videoDuration) {
      new_time = this.videoDuration;
    }

    event.target.value = this.toHHMMSS(new_time);
    if (start) {
      // user is changing the start time, else end time
      z.data[0] = new_time;
      if (z.data[1] < z.data[0]) {
        z.data[1] = Math.min(z.data[0] + 5, this.videoDuration);
      }
    } else {
      z.data[1] = new_time;
      if (z.data[1] < z.data[0]) {
        z.data[0] = Math.max(z.data[1] - 5, 0);
      }
    }
  }

  getAttachedFilename(url: string) {
    const attachFileName = url.split('/').pop();
    return attachFileName.split("?")[0];
  }

  protected readonly sessionStorage = sessionStorage;
}


// there are a lot of options in the json file. This interface only makes use of required ones
interface VoiceGroup {
  LocaleName: string;
  voices: Voice[];
}

interface Voice {
  ShortName: string;
  DisplayName: string;
  Gender: string;
}

// this is a text to speech dialog
// it asks for text and voice and returns the data to calling component
@Component({
  selector: 'tts-dialog',
  templateUrl: 'tts-dialog.html',
})
export class TtsDialog implements OnInit {
  // passed in data: duration, script, text, show_text_input
  show_text_input: boolean = true;

  // below 5 are returned as a dict
  text: string = "";
  translate: boolean = false;
  to_lang: string = "de-DE";
  voice_name: string = 'alloy';
  speed: number = 1;
  start_time: number = 0;
  duration: number = 0;
  split_sentences: boolean = false;

  // used to render html
  MAX_LENGTH: number = 500;
  script_lines: string[] = [];
  languages: [string, string][] = [];

  all_languages: VoiceGroup[] = VoicesWestEuropeGrouped;
  filteredLanguages = [...this.all_languages];
  selectedVoice: Voice = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    public dialogRef: MatDialogRef<TtsDialog>,
    private dialog: MatDialog,
  ) {
    // the json file VoicesWestEuropeGrouped is now in memory
    // console.log(VoicesWestEurope)
    // data object contains video script so that user could copy it
    // 10 minute limit applies by Azure
    // https://learn.microsoft.com/en-us/azure/ai-services/speech-service/speech-services-quotas-and-limits
    const script: string = data['script'];
    this.duration = data['duration'];
    this.MAX_LENGTH = this.duration * 2 * 10;  // 120 words per minute => 2 words per second, 10 characters per word
    this.MAX_LENGTH = Math.ceil(this.MAX_LENGTH);
    this.MAX_LENGTH = Math.min(this.MAX_LENGTH, 4096);  // max 4096 characters as described below
    // https://platform.openai.com/docs/api-reference/audio/createSpeech#audio-createspeech-input
    this.text = data['text'];
    this.show_text_input = data['show_text_input'];
    this.start_time = data['start_time'];
    this.script_lines = script.split('\n');
    this.script_lines.reduce(e => e.trim());

    // initiate languages from auth service
    for (let i of this.authService.languages.entries()) {
      this.languages.push(i);
    }
  }

  ngOnInit() {
    if (localStorage.getItem('voice_name')) {
      this.voice_name = localStorage.getItem('voice_name');
      // set the selectedvoice from the actual array
      this.selectedVoice = [].concat(...this.all_languages.map(group => group.voices))
      .find(voice => voice.ShortName === this.voice_name);
    }
    if (localStorage.getItem('to_lang')) {
      this.to_lang = localStorage.getItem('to_lang');
    }
  }

  filterLocales(event) {
    const query: string = event.target.value;
    const lowerQuery = query.toLowerCase();
    this.filteredLanguages = this.all_languages.map(group => ({
      ...group,
      voices: group.voices.filter(voice =>
        voice.DisplayName.toLowerCase().includes(lowerQuery) ||
        voice.Gender.toLowerCase().includes(lowerQuery) ||
        group.LocaleName.toLowerCase().includes(lowerQuery)
      )
    })).filter(group => group.voices.length > 0);
  }

  selectVoice(voice: Voice) {
    this.selectedVoice = voice;
    this.voice_name = voice.ShortName;
  }

  displayVoice(voice: Voice): string {
    return voice ? voice.DisplayName : '';
  }

  // check if the text is empty, close the dialog and send back data
  onSubmit(): void {
    // save data in local storage for further loading
    localStorage.setItem('voice_name', this.voice_name);
    localStorage.setItem('to_lang', this.to_lang);

    // clean speed
    if (this.speed) {
      if (this.speed < 0.5) {
        this.speed = 0.5;
      } else if (this.speed > 2) {
        this.speed = 2;
      }
    } else {
      this.speed = 1;
    }

    // user can input negatives too
    if (this.start_time < 0) {
      this.start_time = 0;
    }

    this.dialogRef.close({
      text: this.text,
      voice_name: this.voice_name,
      translate: this.translate,
      to_lang: this.to_lang,
      speed: this.speed,
      start_time: this.start_time,
      split_sentences: this.split_sentences
    });
  }

  // use AI api to shorten the text
  shortenText() {
    // open the popup
    this.dialog.open(CompletionPopupComponent, {
      width: '70vw',
      minWidth: '400px',
      maxWidth: '700px',
      maxHeight: '90vh',
      autoFocus: false,
      disableClose: false,
      hasBackdrop: true,
      data: {
        system: "You will be provided with a text. ",  // todo translate
        user: "Make this input more concise",
        assistant: this.text,
        auto_close: true,
        file_search: null,
      }
    }).afterClosed().subscribe((res: string) => {
      if (res) {
        this.text = res;
      }
    });
  }

  // add line to the end, if not already
  appendLine(script_line: string) {
    if (this.text.endsWith(script_line)) {
      // avoid double click
      return;
    }
    if (this.text.length >= this.MAX_LENGTH) {
      return;
    }
    if (this.text.length != 0) {
      // add a new line
      this.text += '\n';
    }
    this.text += script_line;
    document.getElementById('text-area').focus();
  }

  checkAudioTimes(event) {
    const text_input: string = event.target.value;
    let new_start_time: number = 0;
    if (text_input.includes(':')) {
      // user entered a time event, now check how many separators are there
      const blocks = text_input.split(':');
      if (blocks.length == 2) {
        // mm:ss.x format
        new_start_time = parseInt(blocks[0]) * 60;
        new_start_time += parseFloat(blocks[1]);
      } else if (blocks.length == 3) {
        // hh:mm:ss.x format
        new_start_time = parseInt(blocks[0]) * 3600;
        new_start_time += parseInt(blocks[1]) * 60;
        new_start_time += parseFloat(blocks[2]);
      }
    } else if (parseFloat(text_input)) {
      // user entered a simple number or something else
      new_start_time = parseFloat(text_input);
    } else {
      new_start_time = NaN;
    }

    if (isNaN(new_start_time)) {
      // invalid value, do not change start_time
    } else {
      // valid new value
      this.start_time = new_start_time;
    }

    if (this.start_time > this.duration) {
      this.start_time = this.duration;
    } else if (this.start_time < 0) {
      // bring it to front
      this.start_time = 0;
    }

    // update the display
    event.target.value = this.toHHMMSS();
  }

  toHHMMSS(): string {
    let result = new Date(this.start_time * 1000).toISOString();
    if (this.start_time >= 3600) {
      // hh:mm:ss.ss mode
      result = result.slice(11, 22);
    } else {
      // mm:ss.ss mode
      result = result.slice(14, 22);
    }
    return result;
  }
}

function stringToSeconds(innerText: string): number {
  // expects 00:00:00.00 or 00:00.00 like string
  let time_in_seconds = 0;
  try {
    // first check if there is a dot or colon
    if (innerText.length == 5) {
      // mm:ss case
      const minutes = parseInt(innerText.split(':')[0]);
      const seconds = parseInt(innerText.split(':')[1]);
      time_in_seconds = minutes * 60 + seconds;
    }
    else if (innerText.length == 8) {
      // mm:ss.ss
      const milliseconds = parseInt(innerText.split('.')[1]);
      const minutes_and_seconds = innerText.split('.')[0];
      const minutes = parseInt(minutes_and_seconds.split(':')[0]);
      const seconds = parseInt(minutes_and_seconds.split(':')[1]);
      time_in_seconds = minutes * 60 + seconds + milliseconds / 100;
    } else {
      // hh:mm:ss.ss
      const milliseconds = parseInt(innerText.split('.')[1]);
      const hours_minutes_and_seconds = innerText.split('.')[0];
      const time_strings = hours_minutes_and_seconds.split(':')
      const hours = parseInt(time_strings[0]);
      const minutes = parseInt(time_strings[1]);
      const seconds = parseInt(time_strings[2]);
      time_in_seconds = hours * 3600 + minutes * 60 + seconds + milliseconds / 100;
    }
  } catch {
    // do nothing
  }
  // will come here in case of correct time
  return time_in_seconds;
}
