<div class="d-flex justify-content-between align-items-center">
  <h2 class="fontStyle m-0">
    {{ "Delete keyword" | translate }}:&nbsp;{{sourceTag.name}}
  </h2>
  <button [mat-dialog-close]="undefined" aria-label="close" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content class="mt-3" *ngIf="n_videos + n_topics > 0">
  <mat-card-subtitle class="text-nunito" translate>
    Before deletion, you have the possibility to move all Clypps and Pages from this keyword to another.
  </mat-card-subtitle>

  <mat-form-field appearance="outline" class="d-block mt-3">
    <mat-label>{{ '_MOVE_CLYPPS'|translate:{'n_videos': n_videos, 'n_topics': n_topics} }}</mat-label>
    <input [formControl]="tag_form_control" [matAutocomplete]="auto" matInput placeholder="{{'Name'|translate}}"
           type="text">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
      <mat-option (click)="onItemSelect(option)" (onSelectionChange)="onItemSelect(option)"
                  *ngFor="let option of filteredOptions | async" [title]="option.name" [value]="option.id">
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

<div class="justify-content-end" mat-dialog-actions>
  <!--disabled if same tag is selected, otherwise always enabled-->
  <button (click)="deleteOrReassignTag();"
          [disabled]="destination_tag_id == sourceTag.id"
          cdkFocusInitial
          class="align-self-center border-radius-20"
          id="deleteButton" mat-stroked-button>
    <span *ngIf="!destination_tag_id" translate>Löschen</span>
    <span *ngIf="destination_tag_id">
      {{"Re-assign"|translate}}&nbsp;&&nbsp;{{"Löschen"|translate}}
    </span>
  </button>
</div>
