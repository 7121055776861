<!--in case of auto close, do not show prompts-->
<ng-container *ngIf="data.auto_close">
  <h2 class="fontStyle" mat-dialog-title translate>One moment please</h2>
</ng-container>

<!--in case of manual case, let user edit data-->
<ng-container *ngIf="!data.auto_close">
  <div class="d-flex justify-content-between">
    <h2 class="fontStyle" mat-dialog-title translate>Ask Clypp AI</h2>
    <button *ngIf="data.file_search == null" class="border-radius-20 lightgray-border mr-2 mt-1"
            mat-stroked-button
            matTooltip="pdf, pptx (beta)" matTooltipPosition="right"
            style="height: min-content">
      <label class="mb-0 d-flex align-items-center" for="search-file-upload">
        <mat-icon class="mr-1 material-icons-outlined">file_upload</mat-icon>
        <span translate>Upload additional information</span>
      </label>
      <!-- https://learn.microsoft.com/en-us/azure/ai-services/openai/how-to/file-search?tabs=python -->
      <!-- https://platform.openai.com/docs/assistants/tools/file-search/supported-files -->
      <input (change)="attachFile($event)"
             accept=".doc,.docx,.pdf,.pptx,.jpg,.png,.gif" hidden id="search-file-upload"
             type="file"/>
    </button>

    <button (click)="data.file_search=null; data.system='';" *ngIf="data.file_search"
            class="border-radius-20 mr-2 mt-1 lightgray-border overflow-hidden"
            mat-stroked-button style="height: min-content">
      <!-- in case of clearing the file, reset system prompt too -->
      <mat-icon>close</mat-icon>
      <span>
        {{ data.file_search.name }}
      </span>
    </button>
  </div>

  <mat-dialog-content>
    <mat-form-field appearance="outline" class="w-100" hideRequiredMarker>
      <textarea [(ngModel)]="data.user"
                autofocus cdkFocusInitial
                autocomplete="off"
                id="user-input"
                style="font-size: large;"
                matInput
                placeholder="{{'How to add a personalized signature in Outlook?' | translate}}"
                required
                rows="2">
      </textarea>
    </mat-form-field>

    <!-- ask user if they optionally want to use title and previous section info-->
    <div *ngIf="data.assistant" class="d-flex align-items-center mb-3">
      <mat-slide-toggle [(ngModel)]="include_assistant_message" [disabled]="data.file_search != null">
        <span translate>Include information from previous section</span>
      </mat-slide-toggle>
    </div>

    <div class="d-flex flex-wrap mb-4">
      <button [matMenuTriggerFor]="languageMenu" class="border-radius-20 lightgray-border mt-1 mr-2" mat-stroked-button>
        <mat-icon class="mr-1">translate</mat-icon>
        {{ current_language ? current_language : ('Language' | translate) }}
      </button>

      <button [matMenuTriggerFor]="limitMenu" class="border-radius-20 lightgray-border mr-2 mt-1" mat-stroked-button>
        <mat-icon class="mr-1">format_list_numbered</mat-icon>
        {{ current_limit ? current_limit : 'Limit' }}
      </button>

      <button [matMenuTriggerFor]="audienceMenu" class="border-radius-20 lightgray-border mr-2 mt-1" mat-stroked-button>
        <mat-icon class="mr-1">people_outline</mat-icon>
        {{ current_audience ? current_audience : ('Audience' | translate) }}
      </button>

      <mat-menu #languageMenu="matMenu">
        <button (click)="current_language = item;" *ngFor="let item of languages" mat-menu-item>
          {{ item }}
        </button>
      </mat-menu>

      <mat-menu #limitMenu="matMenu">
        <button (click)="current_limit=item;" *ngFor="let item of limits" mat-menu-item>
          {{ item }}
        </button>
      </mat-menu>

      <mat-menu #audienceMenu="matMenu">
        <button (click)="current_audience=item" *ngFor="let item of audience" mat-menu-item>
          {{ item }}
        </button>
      </mat-menu>
    </div>

    <div class="d-flex w-100 justify-content-center">
      <button (click)="getResult();" [disabled]="show_spinner"
              class="border-radius-20 w-100 mb-3"
              color="primary"
              mat-stroked-button
              style="height: 40px;">
        <mat-icon class="material-icons-outlined mr-2">auto_awesome</mat-icon>
        <span translate>Create with Clypp AI</span>
      </button>
    </div>
  </mat-dialog-content>

</ng-container>

<mat-progress-bar *ngIf="show_spinner" class="w-100" mode="indeterminate">
</mat-progress-bar>
