import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Subtitle, VideoView } from "../models/video/video.interface";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../services/auth.service";
import { TopicCard, TopicView } from '../models/video/video.interface';
@Component({
  selector: 'page-popup-internal',
  templateUrl: './page-popup-internal.component.html',
  styleUrls: ['./page-popup-internal.component.scss']
})
export class PagePopupInternalComponent implements OnInit {
  title: string = "";
  message: string = undefined;  // message to display in case of an issue
  currentSectionIndex: number = 0;
  topic: TopicView = null;
  topicId: string;
  
  constructor(public dialogRef: MatDialogRef<PagePopupInternalComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: TopicView) {
  }
  ngOnInit(): void {
    this.title = this.data.title;

    this.topicId = this.data.id
     
    // initiate topic source
    if (this.data.sections.length > 0) {

    } else {
      this.message = this.translateService.instant('This Page is still processing');
    }
    
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
