import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-progress-spinner-dialog',
  templateUrl: './progress-spinner-dialog.component.html',
  styleUrls: ['./progress-spinner-dialog.component.scss']
})
export class ProgressSpinnerDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    if (this.data) {
      // ok
    } else {
      // undefined
      this.data = "Bitte warten...";
    }
  }

  ngOnInit(): void {
  }

}
