
<div >
  <mat-card-title  class="d-flex align-items-center col-lg-6 col-md-12 col-sm-12 col-12 pt-4 mx-3">
    <!-- <div class="avatar-fab">
      <img [src]="authService.currentUser?.profile_pic" alt="profile"
        onerror="this.onerror=null; this.src='https://avatar-service-platform.personio.de/'" />
    </div> &nbsp; -->
    <span class="fontStyle">
      {{userName}}  </span>&nbsp;
      <button class="back border-radius-20 " mat-stroked-button (click)="back()">
        <mat-icon class="mr-1 ">arrow_back</mat-icon>
        <span class="text-nunito font-size-16" translate>Back</span></button>
   
  </mat-card-title>
  <span class="text-nunito text-gray mx-3 px-3" translate>Explore all content published by
    <span>{{userName}}</span> in this workspace</span>
  <div *ngIf="mixed_user_content.length" class="border-radius-10 m-3 px-3 bg-primary-555">
    <div class="d-flex justify-content-between align-items-center">
      <h2 class="fontStyle d-flex align-items-center m-0">
        <mat-icon class="material-icons-outlined mr-2" color="primary">grade</mat-icon>
        {{'Newest Content'|translate}}
      </h2>
    </div>

    <div class="d-flex justify-content-start left-right-div" id="mixed-user">
      <ng-container *ngFor="let card of mixed_user_content">
        <app-video-card *ngIf="card.type == 'video'" [backendUrl]="backendUrl" [videoObject]="card.data" style="margin-right: 2rem; max-width: 320px;">
        </app-video-card>

        <app-topic-card *ngIf="card.type == 'topic'" [topic_object]="card.data" routeUrl="pages/edit/"
          style="max-width: 320px; margin-right: 20px;">
        </app-topic-card>
      </ng-container>

      <!--button 1 with absolute position to the left-->
      <button (click)="scrollToLeftRight('mixed-user',true);" class="left-right-button left-scroll-button" mat-button>
        <mat-icon class="text-white">
          chevron_left
        </mat-icon>
      </button>
      <!--button 2 with absolute position to the left-->
      <button (click)="scrollToLeftRight('mixed-user');" class="left-right-button right-scroll-button" mat-button>
        <mat-icon class="text-white">
          chevron_right
        </mat-icon>
      </button>
    </div>
  </div>
  <div class="row justify-content-between mx-3 px-3">

    <div class="d-flex justify-content-start align-items-center">
      <h2 class="fontStyle d-flex align-items-center m-0">
        <mat-icon class="material-icons-outlined mr-2" color="primary">video_library</mat-icon>
        {{'Discover all from'|translate}} {{userName}}
      </h2>
    </div>
    <div class="d-flex justify-content-start flex-wrap">
      <mat-form-field appearance="outline">
        <mat-label translate>Search for…</mat-label>
        <input (keyup)="applySearchFilter();" [(ngModel)]="searchText" autocomplete="off" matInput
          placeholder="{{'Clypp or Page' | translate}}">
      </mat-form-field>&nbsp;
      <mat-form-field appearance="outline"  matTooltipPosition="above">
        <mat-select (selectionChange)="applySearchFilter();" [(ngModel)]="sort_option">
          <mat-option  value="Newest">{{'Newest'|translate}}</mat-option>
          <mat-option value="Last Edited">{{'Last Edited'|translate}}</mat-option>
          <mat-option value="Title">{{'Title'|translate}}</mat-option>
          <mat-option value="Most Viewed">{{'Most Viewed'|translate}}</mat-option>
        </mat-select>
      </mat-form-field>&nbsp;
      <mat-form-field appearance="outline" matTooltipPosition="above">
        <mat-select (selectionChange)="applySearchFilter();" [(ngModel)]="filter_option">
          <mat-option  value="All">{{'All'|translate}}</mat-option>
          <mat-option value="Featured">{{'Featured'|translate}}</mat-option>
          <mat-option value="External">{{'External'|translate}}</mat-option>

        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <mat-tab-group class="left-right-div mx-3 px-3">

    <mat-tab label="Clypps">

      <ng-template mat-tab-label>
        <mat-icon class="material-icons-outlined mr-2"
          mat-list-icon>play_circle</mat-icon>{{'Clypps'|translate}}&nbsp;{{filteredVideos.length}}

      </ng-template>
      <div>
        <div class="d-flex justify-content-center" style="flex-flow: wrap;">
          <!--video card grid-->

          <app-video-card *ngFor="let video of filteredVideos" [backendUrl]="backendUrl" [videoObject]="video">
          </app-video-card>
          <div *ngIf="filteredVideos.length == 0 " class="d-flex justify-content-center align-items-center"
            style="height: 300px;">
            <h3>
              {{'No results. Search in other categories or'|translate}}
              <u (click)="navbarService.openVideoRequestDialog();" class="pointer">
                {{'ask your colleagues'|translate}}
              </u>
            </h3>
          </div>
        </div>

      </div>
    </mat-tab>
    <mat-tab label="Pages">
      <ng-template mat-tab-label>
        <mat-icon class="material-icons-outlined mr-2"
          mat-list-icon>sticky_note_2</mat-icon>{{'Pages'|translate}}&nbsp;{{filteredTopics.length}}
      </ng-template>
      <div>
        <div class="d-flex justify-content-center" style="flex-flow: wrap;">
          <!--video card grid-->

          <app-topic-card *ngFor="let topic of filteredTopics;" [topic_object]="topic" routeUrl="pages/edit/"
            style="max-width: 320px; margin-right: 20px;">
          </app-topic-card>
        </div>
        <div *ngIf="filteredTopics.length == 0 "
          class="d-flex justify-content-center align-items-center" style="height: 300px;">
          <h3>
            {{'No results. Search in other categories or'|translate}}
            <u (click)="navbarService.openVideoRequestDialog();" class="pointer">
              {{'ask your colleagues'|translate}}
            </u>
          </h3>
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>
</div>