<div class="d-flex justify-content-between"
     style="place-items: center; height: 75px;">
  <!--height is given to match mat form field-->

  <!--video title-->
  <h3 (click)="toggleTitleVisibility();" *ngIf="!is_title_editor_visible"
      [ngClass]="can_change_uploader_title_restrictions ? 'cursor-pointer' : ''"
      class="text-nunito font-medium line-height-initial"
      mat-dialog-title>
    {{video_title}}
    <mat-icon *ngIf="can_change_uploader_title_restrictions"
              class="material-icons-outlined ml-1"
              matSuffix style="vertical-align: bottom;">edit
    </mat-icon>
  </h3>

  <!--title input field-->
  <mat-form-field (clickOutside)="updateTitle(true);" *ngIf="is_title_editor_visible"
                  appearance="outline"
                  class="w-100">
    <input (keydown.enter)="updateTitle(true);"
           (keydown.escape)="updateTitle(false);"
           [(ngModel)]="video_title" autocapitalize="on" autocomplete="off"
           class="text-nunito line-height-initial" matInput maxlength="100" required style="font-size: 16px;"
           type="text">
  </mat-form-field>

  <div class="d-flex">
    <!--open in new tab-->
    <a mat-icon-button class="mr-2" target="_blank" [href]="'/view/'+videoRes.id"
       [disabled]="videoRes.is_draft"
       matTooltip="{{'View Clypp'|translate}}" matTooltipPosition="below">
      <mat-icon>open_in_new</mat-icon>
    </a>
    <!--download-->
    <button [disabled]="disable_download_button"
            [matMenuTriggerFor]="downloadMenu" class="mr-2" color="primary" mat-icon-button>
      <mat-icon [matTooltip]="download_button_tooltip">
        save_alt
      </mat-icon>
    </button>
    <!--close simply-->
    <button (click)="closeDialog()" cdkFocusInitial mat-dialog-close mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>

    <mat-menu #downloadMenu="matMenu">
      <button (click)="downloadFile();" mat-menu-item>
        MP4
      </button>
      <a [disabled]="!can_see_statistics"
         [href]="download_history_uri"
         [matTooltip]="can_see_statistics ? '' : 'Upgrade to use this feature' | translate"
         download="{{videoRes.id}}_history.csv"
         mat-menu-item
         matTooltipPosition="left">
        CSV
      </a>
      <button (click)="downloadZip()" mat-menu-item>ZIP</button>
    </mat-menu>
  </div>
</div>


<mat-tab-group mat-align-tabs="start" preserveContent>

  <!-- View and manage video -->
  <mat-tab label="{{'Details'|translate}}">
    <div class="d-flex flex-column">
      <!--video-->
      <div class="m-3 d-flex justify-content-center">
        <video [poster]="videoRes.thumbnail" [src]="vidPath" controls controlsList="nodownload"
               class="video-player"
               crossorigin="anonymous" preload="none">
          <track *ngIf="vttPath" [src]="vttPath" default kind="subtitles"/>
        </video>
      </div>

      <!--message-->
      <div [hidden]="videoRes.is_draft">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label translate>Message to the creator (optional)</mat-label>
          <textarea [(ngModel)]="message" matInput placeholder="{{'Dear Clypp user'|translate}}…" rows="3"></textarea>
        </mat-form-field>
      </div>
    </div>
  </mat-tab>


  <!-- See stats -->
  <mat-tab [disabled]="!can_see_statistics" label="{{'Statistics'|translate}}">
    <div *ngIf="history_data" class="m-0" mat-dialog-content>
      <!--      m-0 class is needed to avoid horizontal scroll-->
      <div class="d-flex justify-content-around align-items-center mt-2" style="flex-flow: wrap;">

        <mat-card-subtitle class="d-flex align-items-center mr-3 text-nunito">
          <mat-icon class="mr-1 material-icons-outlined"
                    matTooltip="{{'Erstellt am'|translate}}"
                    matTooltipPosition="above">event
          </mat-icon>
          {{videoRes.added_on | date: 'dd.MM.yyyy HH:mm'}}
        </mat-card-subtitle>

        <mat-card-subtitle class="d-flex align-items-center mr-3 text-nunito"
                           matTooltip="{{history_data.n_unique_internal_viewers}}&nbsp;{{'unique viewers (incl. author)'|translate}}"
                           matTooltipPosition="above">
          <mat-icon class="mr-1 material-icons-outlined">visibility</mat-icon>
          {{history_data.n_internal_views}}&nbsp;{{'Internal views'|translate}}
        </mat-card-subtitle>

        <mat-card-subtitle class="d-flex align-items-center mr-3 text-nunito">
          <mat-icon class="mr-1 material-icons-outlined">public</mat-icon>
          {{history_data.n_external_views}}&nbsp;{{'External views'|translate}}
        </mat-card-subtitle>

        <mat-form-field appearance="outline" style="margin-top: -10px;">
          <mat-label translate>Search for…</mat-label>
          <input #filterUserInput (keyup)="filterUser($event);"
                 matInput
                 maxlength="50"
                 placeholder="{{'Name or email'|translate}}">
        </mat-form-field>
      </div>

      <!-- table -->
      <table [dataSource]="history_table_data_source" class="w-100 mat-elevation-z1"
             mat-table>

        <!--FN LN-->
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell translate>Name</th>
          <td *matCellDef="let element" mat-cell>
            <!--            show tooltip on name only-->
            <span matTooltip="{{element.viewer.user.email}}" matTooltipPosition="left">
              {{element.viewer.user.first_name}}&nbsp;{{element.viewer.user.last_name}}
            </span>
            <!--            show tooltip if user belongs to another company-->
            <a *ngIf="company_id!=element.viewer.user.userprofile.company" href="/public/{{element.viewer.user.userprofile.company}}" mat-icon-button
               matTooltip="{{'Externe Organisation'|translate}}"
               matTooltipPosition="right" target="_blank">
              <mat-icon>public</mat-icon>
            </a>
          </td>
        </ng-container>

        <!--times_viewed-->
        <ng-container matColumnDef="times_viewed">
          <th *matHeaderCellDef mat-header-cell translate>Ansichten</th>
          <td *matCellDef="let element" mat-cell>
            {{element.times_viewed}}
          </td>
        </ng-container>

        <!--viewed_till-->
        <ng-container matColumnDef="viewed_till">
          <th *matHeaderCellDef mat-header-cell translate>% watched</th>
          <td *matCellDef="let element" mat-cell>
            <!-- value must be between 0 and 100-->
            {{minimum_function((element.viewed_till * 100 / videoRes.duration), 100) | number: '1.0-0' }}&nbsp;%
          </td>
        </ng-container>

        <!--viewed_on-->
        <ng-container matColumnDef="viewed_on">
          <th *matHeaderCellDef mat-header-cell translate>Last Viewed</th>
          <td *matCellDef="let element" mat-cell>
            {{element.viewed_on | date}}
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayed_columns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayed_columns" mat-row></tr>

        <tr *matNoDataRow class="mat-row">
          <td class="mat-cell" colspan="4">
            {{'No users found for'|translate}}&nbsp;"{{filterUserInput.value}}"
          </td>
        </tr>
      </table>
    </div>
  </mat-tab>
</mat-tab-group>

<mat-dialog-actions align="center" class="mb-0">
  <!--manage who can access the video, quality manager only-->
  <button (click)="openRestrictionViewPopup();"
          [disabled]="!can_change_uploader_title_restrictions" color="primary"
          mat-stroked-button
          matTooltip="{{can_change_dept_team_tooltip}}"
          matTooltipPosition="above">
    <mat-icon class="mr-1 material-icons-outlined">lock</mat-icon>
    <span translate>Manage access</span>
  </button>

  <!--make draft-->
  <button (click)="actionOnBtn('make draft')"
          *ngIf="!videoRes.is_draft"
          class="red-border"
          mat-stroked-button
          matTooltip="{{'This Clypp will be saved as a draft. The creator can edit & re-publish the Clypp anytime.' | translate}}"
          matTooltipPosition="above">
    <mat-icon class="mr-1">close</mat-icon>
    <span translate>Save as draft</span>
  </button>

  <!--make internal-->
  <button (click)="actionOnBtn('make internal')"
          *ngIf="videoRes.is_external" mat-stroked-button
          matTooltip="{{'This reverts the visibility of this Clypp to inside the workspace only'|translate}}"
          matTooltipPosition="above">
    <mat-icon class="mr-1">public_off</mat-icon>
    <span translate>Deactivate external sharing</span>
  </button>

  <!--release -->
  <button (click)="actionOnBtn('release from review')"
          *ngIf="videoRes.is_held_for_review"
          [disabled]="videoRes.is_draft" mat-stroked-button
          matTooltip="{{'Video sofort freigeben'|translate}}"
          matTooltipPosition="above">
    <mat-icon class="mr-1">done_all</mat-icon>
    <span translate>Freigeben</span>
  </button>


  <!--hold for review-->
  <button (click)="actionOnBtn('hold for review')"
          *ngIf="!videoRes.is_held_for_review"
          class="red-border" mat-stroked-button
          matTooltip="{{'Managers can still see and approve this Clypp'|translate}}"
          matTooltipPosition="above">
    <mat-icon class="mr-1">remove_done</mat-icon>
    <span translate>Revoke approval</span>
  </button>

  <!--feature-->
  <button (click)="actionOnBtn('feature')" *ngIf="!videoRes.is_featured"
          [disabled]="videoRes.is_held_for_review || videoRes.is_draft"
          mat-stroked-button
          matTooltip="{{'Add to Featured Clypps'|translate}}"
          matTooltipPosition="above">
    <mat-icon class="mr-1">star_border</mat-icon>
    <span translate>Hervorheben</span>
  </button>

  <!--un feature-->
  <button (click)="actionOnBtn('un feature')" *ngIf="videoRes.is_featured"
          class="red-border" mat-stroked-button
          matTooltip="{{'Remove from Featured Clypps'|translate}}"
          matTooltipPosition="above">
    <mat-icon class="mr-1">star</mat-icon>
    <span translate>Nicht hervorheben</span>
  </button>
</mat-dialog-actions>
