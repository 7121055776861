import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatTreeModule } from '@angular/material/tree';
import { MatSliderModule } from '@angular/material/slider';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgxWavesurferModule } from 'ngx-wavesurfer';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitializer } from './app.initializer';
import { DndDirective } from './directives/dnd.directive';
import { FluidHeightDirective } from './directives/fluid-height.directive';
import { DisableAutofillDirective } from './directives/disable-autofill.directive';
import { OnAppearDirective } from './directives/on-appear.directive';
import { DelayedInputDirective } from './directives/delayed-input.directive';
import { DateInterceptor } from './interceptors/date.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { LoginComponent } from './login/login.component';
import { ImagePipe } from './pipes/image.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { ControlsComponent } from './quickwin-creation/controls/controls.component';
import { ExportDialogComponent } from './quickwin-creation/export-dialog/export-dialog.component';
import { MatStepperFooterComponent } from './quickwin-creation/mat-stepper-footer/mat-stepper-footer.component';
import { NotesComponent } from './quickwin-creation/notes/notes.component';
import { TagsComponent } from './quickwin-creation/tags/tags.component';
import { VideoTrimmerComponent } from './quickwin-creation/video-trimmer/video-trimmer.component';
import { WebcamViewerComponent } from './quickwin-creation/webcam-viewer/webcam-viewer.component';
import { QuickwinOverviewComponent } from './quickwin-overview/quickwin-overview.component';
import { QuickwinViewComponent } from './quickwin-view/quickwin-view.component';
import { AuthService } from './services/auth.service';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { ProgressSpinnerDialogComponent } from './shared/progress-spinner-dialog/progress-spinner-dialog.component';
import { TimerComponent } from './shared/timer/timer.component';
import { FileSaverModule } from 'ngx-filesaver';
import { SignUpComponent } from './sign-up/sign-up.component';
import { HistoryPageComponent } from './history-page/history-page.component';
import { UserProfileComponent } from './shared/user-profile/user-profile.component';
import { VideoUploadViewerComponent } from './quickwin-creation/video-upload-viewer/video-upload-viewer.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PublicVideoViewComponent } from './public-video-view/public-video-view.component';
import { MyVideosComponent } from './my-videos/my-videos.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserManagementComponent } from './dashboard/user-management/user-management.component';
import { VideoManagementComponent } from './dashboard/video-management/video-management.component';
import { GlobalAdministrationComponent } from './dashboard/global-administration/global-administration.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { SearchedContentComponent } from './searched-content/searched-content.component';
import { FavoriteVideoComponent } from './favorite-video/favorite-video.component';
import { CompanyWiseVideoComponent } from './company-wise-video/company-wise-video.component';
import { InternalVideoComponent } from './internal-video/internal-video.component';
import { VideoCardComponent } from './video-card/video-card.component';
import { VideoCardHistoryComponent } from './video-card-history/video-card-history.component';
import { PaginatorIntlService } from './CustomPaginator';
import { PlaylistsComponent } from './playlists/playlists.component';
import { CreatePlaylistComponent } from './playlists/create-playlist/create-playlist.component';
import { AddVideosComponent } from './playlists/add-videos/add-videos.component';
import { AddToPlaylistComponent } from './playlists/add-to-playlist/add-to-playlist.component';
import { CommentSectionComponent } from './comment-section/comment-section.component';
import { ViewAllCommentsComponent } from './view-all-comments/view-all-comments.component';
import { VideoCreationViewComponent } from './quickwin-creation/video-creation-view/video-creation-view.component';
import { PlaylistViewComponent } from './playlist-view/playlist-view.component';
import { VideoCreationModalPopupComponent } from './video-creation-modal-popup/video-creation-modal-popup.component';
import { WcScBothViewerComponent } from './quickwin-creation/wc-sc-both-viewer/wc-sc-both-viewer.component';
import { RestrictedSelectionDialogComponent } from './quickwin-creation/restricted-selection-dialog/restricted-selection-dialog.component';
import { PasswordDialogComponent } from './shared/password-dialog/password-dialog.component';
import { SetCreatorDialogComponent } from './shared/set-creator-dialog/set-creator-dialog.component';
import { VideoCardPlaylistComponent } from './video-card-playlist/video-card-playlist.component';
import { MyVideosVideoPopupComponent } from './my-videos-video-popup/my-videos-video-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { UserDetailsDialogComponent } from './shared/user-details-dialog/user-details-dialog.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import { GroupsComponent } from './groups/groups.component';
import { GroupsViewComponent } from './groups/groups-view/groups-view.component';
import { VideoCreationOverallComponent } from './video-creation-overall/video-creation-overall.component';
import { ScreencastCreationComponent } from './quickwin-creation/screencast-creation/screencast-creation.component';
import { ScreencastWebcamCreationComponent } from './quickwin-creation/screencast-webcam-creation/screencast-webcam-creation.component';
import { WebcamCreationComponent } from './quickwin-creation/webcam-creation/webcam-creation.component';
import { UploadCreationComponent } from './quickwin-creation/upload-creation/upload-creation.component';
import { UserProfileErrorDetailsDialogComponent } from './shared/user-profile-error-details-dialog/user-profile-error-details-dialog.component';
import { CreatePlaylistMyVidoesComponent } from './playlists/create-playlist-my-vidoes/create-playlist-my-vidoes.component';
import { MyVideosAddVideosPlaylistComponent } from './playlists/my-videos-add-videos-playlist/my-videos-add-videos-playlist.component';
import { FontPickerModule } from 'ngx-font-picker';
import { FONT_PICKER_CONFIG } from 'ngx-font-picker';
import { FontPickerConfigInterface } from 'ngx-font-picker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QuillModule } from 'ngx-quill';
import { AngularDraggableModule } from 'angular2-draggable';
import { TranslateService } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TeamDeptGroupsViewComponent } from './groups/team-dept-groups-view/team-dept-groups-view.component';
import { CompanyManagementComponent } from './dashboard/global-administration/company-management/company-management.component';
import { DeptTeamComponent } from './dashboard/global-administration/dept-team/dept-team.component';
import { LocationPositionComponent } from './dashboard/global-administration/location-position/location-position.component';
import { TreeDataService } from './dashboard/global-administration/dept-team/dept-team.component';
import { TranscriptPopupComponent } from './shared/transcript-popup/transcript-popup.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ColorPickerModule } from 'ngx-color-picker';
import { ThumbnailPopupComponent } from './shared/thumbnail-popup/thumbnail-popup.component';
import { TooltipListPipe } from './pipes/tooltip-list.pipe';
import { CompanyRegistrationComponent } from './company-registration/company-registration.component';
import { MembersListPopupComponent } from './shared/members-list-popup/members-list-popup.component';
import { vidDurationFormat } from './services/utility.service';
import { LoadUserCsvComponent } from './shared/load-user-csv/load-user-csv.component';
import { TextOverlayDragDropComponent } from './text-overlay-drag-drop/text-overlay-drag-drop.component';
import { VideoRequestDialogComponent } from './shared/video-request-dialog/video-request-dialog.component';
import { VideoRequestDashboardComponent } from './video-request-dashboard/video-request-dashboard.component';
import { ManageUserDialogComponent } from './manage-user-dialog/manage-user-dialog.component';
import { ShapeOverlayDivComponent } from './quickwin-creation/shape-overlay-div/shape-overlay-div.component';
import { ScRecordDialogComponent } from './sc-record-dialog/sc-record-dialog.component';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MyActivitiesComponent } from './my-activities/my-activities.component';
import { ClickoutsideDirective } from './directives/clickoutside.directive';
import { FriendOrganisationViewComponent } from './friend-organisation-view/friend-organisation-view.component';
import { VideoManagementPopupComponent } from './dashboard/video-management/video-managment-popup/video-management-popup.component';
import { TagsViewComponent } from './tags-view/tags-view.component';
import { AllowedIpAddressesComponent } from './dashboard/global-administration/allowed-ip-addresses/allowed-ip-addresses.component';
import { PartnerOrganizationsComponent } from './dashboard/global-administration/partner-organizations/partner-organizations.component';
import { FeBannersComponent } from './shared/fe-banners/fe-banners.component';
import { FeedbackDialogComponent } from './shared/feedback-dialog/feedback-dialog.component';
import { AddTranslationsComponent } from './add-translations/add-translations.component';
import {MatBadgeModule} from "@angular/material/badge";
import { WatermarkPreviewComponent } from './dashboard/global-administration/company-management/watermark-preview/watermark-preview.component';
import {TtsDialog, VideoEditPageComponent} from './video-edit-page/video-edit-page.component';
import {QRCodeModule} from 'angularx-qrcode';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import {CdkListboxModule} from "@angular/cdk/listbox";
import { VideoEmbedComponent } from './video-embed/video-embed.component';
import { RestrictionDialogComponent } from './restriction-dialog/restriction-dialog.component';
import { VideoMetaPageComponent } from './video-meta-page/video-meta-page.component';
import { PlaylistManagementComponent } from './dashboard/playlist-management/playlist-management.component';
import localeDe from '@angular/common/locales/de';
import { GroupsManagementComponent } from './dashboard/groups-management/groups-management.component';
import { PositionManagementComponent } from './dashboard/global-administration/position-management/position-management.component';
import { AnalyticsDashboardComponent } from './dashboard/analytics-dashboard/analytics-dashboard.component';
import { VideoProfileComponent } from './dashboard/global-administration/company-management/video-profile/video-profile.component';
import { NgChartsModule } from 'ng2-charts';
import { UserViewComponent } from './user-view/user-view.component';
import { UserContentDialogComponent } from './shared/user-content-dialog/user-content-dialog.component';
import { CombineTagDialogComponent } from './shared/combine-tag-dialog/combine-tag-dialog.component';
import { ClyppsPageComponent } from './clypps-page/clypps-page.component';
import { PlaylistViewallPageComponent } from './playlist-viewall-page/playlist-viewall-page.component';
import { CarouselSliderComponent } from './carousel-slider/carousel-slider.component';
import { EditTopicsComponent } from './edit-topics/edit-topics.component';
import { TopicsOverviewComponent } from './topics-overview/topics-overview.component';
import { ViewTopicsComponent } from './view-topics/view-topics.component';
import { StillThereComponent } from './shared/still-there/still-there.component';
import { SelectVideoPopupComponent } from './select-video-popup/select-video-popup.component';
import { MyLibrariesComponent } from './my-libraries/my-libraries.component';
import { TopicCardComponent } from './topic-card/topic-card.component';
import { ChapterCreationPopupComponent } from './chapter-creation-popup/chapter-creation-popup.component';
import { SharePopupComponent } from './shared/share-popup/share-popup.component';
import { TopicPageManagementComponent } from './dashboard/topic-page-management/topic-page-management.component';
import { ContentDashboardComponent } from './dashboard/content-dashboard/content-dashboard.component';
import { TopicBottomSheetComponent } from './shared/topic-bottom-sheet/topic-bottom-sheet.component';
import { SelectOnedrivePopupComponent } from './select-onedrive-popup/select-onedrive-popup.component';
import { PdfViewerPopupComponent } from './pdf-viewer-popup/pdf-viewer-popup.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { UploaderChangeDialogComponent } from './dashboard/uploader-change-dialog/uploader-change-dialog.component';
import { TopicViewAllComponent } from './topic-view-all/topic-view-all.component';
import { TopicTranslationPopupComponent } from './edit-topics/topic-translation-popup/topic-translation-popup.component';
import { UploadClyppsPopupComponent } from './dashboard/video-management/upload-clypps-popup/upload-clypps-popup.component';
import { CompletionPopupComponent } from './completion-popup/completion-popup.component';
import { PagePopupInternalComponent } from './page-popup/page-popup-internal.component';
import 'vidstack/player';
import 'vidstack/player/ui';
import 'vidstack/icons';
import {MatDatepickerModule} from "@angular/material/datepicker";
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
// the second parameter 'de' is optional
// now, the app can use German locale data
registerLocaleData(localeDe, 'de');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};
// const appearance: MatFormFieldDefaultOptions = {
//   appearance: 'standard'
// };

const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
  // Change this to your Google API key
  apiKey: 'AIzaSyDuMuSBTucBhb78x-6JnLzAhT8vd_rTh_0',
};

export function jwtOptionsFactory(): {
  tokenGetter: () => string;
  allowedDomains: string[];
} {
  return {
    tokenGetter: () => {
      return localStorage.getItem('access_token');
    },
    allowedDomains: ['localhost:4000', 'dev.zesavi.com'],
  };
}

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        DndDirective,
        FluidHeightDirective,
        DelayedInputDirective,
        DisableAutofillDirective,
        TimerComponent,
        AlertDialogComponent,
        MatStepperFooterComponent,
        ProgressSpinnerDialogComponent,
        TagsComponent,
        ExportDialogComponent,
        LoginComponent,
        OnAppearDirective,
        NotesComponent,
        SafePipe,
        ImagePipe,
        ControlsComponent,
        WebcamViewerComponent,
        VideoTrimmerComponent,
        QuickwinOverviewComponent,
        QuickwinViewComponent,
        SignUpComponent,
        HistoryPageComponent,
        UserProfileComponent,
        VideoUploadViewerComponent,
        ForgotPasswordComponent,
        PublicVideoViewComponent,
        MyVideosComponent,
        DashboardComponent,
        UserManagementComponent,
        VideoManagementComponent,
        GlobalAdministrationComponent,
        SearchedContentComponent,
        FavoriteVideoComponent,
        CompanyWiseVideoComponent,
        InternalVideoComponent,
        VideoCardComponent,
        VideoCardHistoryComponent,
        CommentSectionComponent,
        ViewAllCommentsComponent,
        PlaylistsComponent,
        CreatePlaylistComponent,
        AddVideosComponent,
        AddToPlaylistComponent,
        VideoCreationViewComponent,
        PlaylistViewComponent,
        VideoCreationModalPopupComponent,
        WcScBothViewerComponent,
        RestrictedSelectionDialogComponent,
        PasswordDialogComponent,
        SetCreatorDialogComponent,
        UserDetailsDialogComponent,
        VideoCardPlaylistComponent,
        MyVideosVideoPopupComponent,
        GroupsComponent,
        GroupsViewComponent,
        VideoCreationOverallComponent,
        ScreencastCreationComponent,
        ScreencastWebcamCreationComponent,
        WebcamCreationComponent,
        UploadCreationComponent,
        UserProfileErrorDetailsDialogComponent,
        CreatePlaylistMyVidoesComponent,
        MyVideosAddVideosPlaylistComponent,
        TeamDeptGroupsViewComponent,
        CompanyManagementComponent,
        DeptTeamComponent,
        LocationPositionComponent,
        TranscriptPopupComponent,
        ThumbnailPopupComponent,
        TooltipListPipe,
        CompanyRegistrationComponent,
        MembersListPopupComponent,
        vidDurationFormat,
        LoadUserCsvComponent,
        TextOverlayDragDropComponent,
        VideoRequestDialogComponent,
        VideoRequestDashboardComponent,
        ManageUserDialogComponent,
        ShapeOverlayDivComponent,
        ScRecordDialogComponent,
        MyActivitiesComponent,
        ClickoutsideDirective,
        FriendOrganisationViewComponent,
        TagsViewComponent,
        AllowedIpAddressesComponent,
        PartnerOrganizationsComponent,
        VideoManagementPopupComponent,
        FeBannersComponent,
        FeedbackDialogComponent,
        AddTranslationsComponent,
        WatermarkPreviewComponent,
        VideoEditPageComponent,
        ImageCarouselComponent,
        TtsDialog,
        VideoEmbedComponent,
        RestrictionDialogComponent,
        VideoMetaPageComponent,
        PlaylistManagementComponent,
        GroupsManagementComponent,
        PositionManagementComponent,
        AnalyticsDashboardComponent,
        VideoProfileComponent,
        UserViewComponent,
        UserContentDialogComponent,
        CombineTagDialogComponent,
        ClyppsPageComponent,
        PlaylistViewallPageComponent,
        TopicCardComponent,
        CarouselSliderComponent,
        EditTopicsComponent,
        TopicsOverviewComponent,
        ViewTopicsComponent,
        StillThereComponent,
        SelectVideoPopupComponent,
        MyLibrariesComponent,
        ChapterCreationPopupComponent,
        SharePopupComponent,
        TopicPageManagementComponent,
        ContentDashboardComponent,
        TopicBottomSheetComponent,
        SelectOnedrivePopupComponent,
        PdfViewerPopupComponent,
        UploaderChangeDialogComponent,
        TopicViewAllComponent,
        TopicTranslationPopupComponent,
        UploadClyppsPopupComponent,
        CompletionPopupComponent,
        ConfirmDialogComponent,
        PagePopupInternalComponent
    ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    PdfViewerModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatButtonModule,
    NgChartsModule,
    MatIconModule,
    MatToolbarModule,
    DragDropModule,
    NgSelectModule,
    MatDividerModule,
    MatStepperModule,
    MatSliderModule,
    MatTreeModule,
    PickerModule,
    ColorPickerModule,
    MatBottomSheetModule,
    MaterialFileInputModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    FormsModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatCardModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    MatTooltipModule,
    InfiniteScrollModule,
    MatCheckboxModule,
    MatGridListModule,
    MatSnackBarModule,
    AngularDraggableModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory
      }
    }),
    MatSidenavModule,
    MatListModule,
    MatRippleModule,
    MatMenuModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    FileSaverModule,
    MatRadioModule,
    MatProgressBarModule,
    MatTabsModule,
    MatSlideToggleModule,
    FontPickerModule,
    TranslateModule.forRoot(),
    QuillModule.forRoot(),
    MatBadgeModule,
    NgxWavesurferModule,
    QRCodeModule,
    CdkListboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
    providers: [
        DatePipe,
        // CookieService,
        TreeDataService,
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'standard' }
        },
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false }
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService] },
        // {
        //     provide: MatPaginatorIntl, deps: [TranslateService],
        //     useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
        //   }
        {
            provide: MatPaginatorIntl,
            useFactory: (translate) => {
                const service = new PaginatorIntlService();
                service.injectTranslateService(translate);
                return service;
            },
            deps: [TranslateService]
        },
        // { provide: MatPaginatorIntl, useClass: CustomPaginator },
        {
            provide: FONT_PICKER_CONFIG,
            useValue: DEFAULT_FONT_PICKER_CONFIG
        },
    ],
    bootstrap: [AppComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {}
