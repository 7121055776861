<div class="d-flex justify-content-sm-between">
  <h3 class="text-nunito">{{ title }}</h3>
  <button (click)="closeDialog()" mat-icon-button>
    <mat-icon style="scale: 1.3;">close</mat-icon>
  </button>
</div><!-- updated height of div as %age not working in dashboard video review popup-->

<!--Video div contains just the player-->
<div class="video-div">
  <!--Show message if needed-->
  <mat-card-title *ngIf="message" class="text-danger text-nunito p-2">
    {{message}}
  </mat-card-title>

  <!--Load embed Page-->
  <app-view-topics [isPopupMode]="true" [topicId]="topicId"></app-view-topics>
</div>