<div class="d-flex w-100 justify-content-end">
  <!--navigation-->
  <div class="navigation-content">
    <nav class="h-auto">
      <a (click)="scrollTo(link.id);"
         *ngFor="let link of section_links"
         [style.color]="link.active ? 'black' : 'gray'"
         class="navigation-link p-3 text-nunito d-flex align-items-center">
         <mat-icon class="material-icons-outlined mr-2">{{link.icon}}</mat-icon>
        {{link.name}}
      </a>
    </nav>
  </div>

  <div class="company-form-content p-3">
    <section class="p-3" id="section0" data-attr="attach_money">
      <!-- Subscription -->
      <div class="d-flex align-items-center" style="flex-flow: wrap;">
        <h2 class="fontstyle m-0" translate>Subscription</h2>
        <button (click)="redirectToBilling();"
                *ngIf="authService.company?.stripe_customer_id"
                [disabled]="!authService.userDetails?.is_global_manager"
                class="ml-4 border-radius-20" mat-stroked-button>
          <mat-icon mat-list-icon>euro</mat-icon>
          {{'Manage Subscription' | translate}}
        </button>

        <button (click)="buyCredits();" class="ml-4 border-radius-20"
                [disabled]="!authService.userDetails?.is_global_manager"
                mat-stroked-button>
          <mat-icon class="material-icons-outlined" mat-list-icon>
            shopping_bag
          </mat-icon>
          {{'Buy Credits'|translate}}
        </button>
      </div>
      <hr>

      <!--analytics-->
      <div class="d-flex" style="flex-wrap: wrap;">
        <div class="simple-card-global-admin m-2">
          <h4 class="text-nunito m-0" translate>
            Clypp license
          </h4>
          <h3 *ngIf="authService.company?.max_upload_video_height == '1440'"
              class="font-weight-bolder m-0 text-nunito"
              style="color: darkgoldenrod;">
            BUSINESS
          </h3>
          <h3 *ngIf="authService.company?.max_upload_video_height == '1080'"
              class="font-weight-bolder m-0 text-nunito"
              style="color: mediumblue;">
            PRO
          </h3>
          <h3 *ngIf="authService.company?.max_upload_video_height == '720'" class="font-weight-bolder m-0 text-nunito"
              style="color: black;">
            FREE
          </h3>
        </div>

        <div class="simple-card-global-admin m-2">
          <h4 class="text-nunito m-0" translate>
            Renews on
          </h4>
          <h3 class="text-nunito m-0 font-weight-bolder text-danger">
            {{authService.company?.valid_till | date: 'dd MMM y'}}
          </h3>
        </div>

        <div class="simple-card-global-admin m-2">
          <h4 class="text-nunito m-0" translate>
            Clypp Credits
          </h4>
          <a class="text-nunito m-0 font-weight-bolder"
             style="font-size: 16px; line-height: 28px;"
             href="{{'_CLYPPCREDITS_URL'|translate}}" target="_blank">
            <!--it has the style of H3-->
            {{authService.company?.n_ai_credits}}
          </a>
        </div>

        <div class="simple-card-global-admin m-2">
          <h4 class="text-nunito m-0" translate>
            Total Clypps
          </h4>
          <h3 class="text-nunito m-0 font-weight-bolder">
            {{n_videos}}
            <span *ngIf="authService.company?.max_video_count">
              /&nbsp;{{authService.company?.max_video_count}}
            </span>
          </h3>
        </div>

        <div class="simple-card-global-admin m-2">
          <h4 class="text-nunito m-0" translate>
            Total users
          </h4>
          <h3 class="text-nunito m-0 font-weight-bolder">
            {{n_users}}
            <span *ngIf="authService.company?.max_allowed_employees_count">
              /&nbsp;{{authService.company?.max_allowed_employees_count}}
            </span>
          </h3>
        </div>

        <div class="simple-card-global-admin m-2">
          <h4 class="text-nunito m-0" translate>
            Total Creators
          </h4>
          <h3 class="text-nunito m-0 font-weight-bolder">
            {{n_creators}}
            <span *ngIf="authService.company?.max_creators">
              /&nbsp;{{authService.company?.max_creators}}
            </span>
          </h3>
        </div>
      </div>
    </section>


    <section class="p-3 mt-5" id="section1" data-attr="location_city">
      <dept-team></dept-team>
    </section>


    <section class="p-3 mt-5" id="section6" data-attr="person">
      <app-position-management></app-position-management>
    </section>

    <section class="p-3 mt-5" id="section3" data-attr="location_on">
      <location-position></location-position>
    </section>

    <section class="p-3 mt-5" id="section2" data-attr="group">
      <groups-management></groups-management>
    </section>

    <section class="p-3 mt-5" id="section4" data-attr="tag">
      <!-- Tags -->
      <div class="m-3 tag-mgt">
        <!--title, button and search-->
        <div class="d-flex justify-content-between align-items-center" style="flex-flow: wrap;">
          <div class="d-flex align-items-center">
            <h2 class="fontstyle m-0" translate>Keywords</h2>
            <button (click)="addTag();" class="ml-4 border-radius-20" mat-stroked-button>
              <mat-icon mat-icon>add</mat-icon>
              <span translate>Add keyword</span>
            </button>
          </div>

          <mat-form-field appearance="outline" class="">
            <mat-label translate>Search for…</mat-label>
            <input (click)="stopToggle($event)" (focus)="stopToggle($event)" (keyup)="applyFilter($event)"
                   autocomplete="off" matInput placeholder="{{'Keyword' | translate}}">
          </mat-form-field>
        </div>
        <hr>

        <div class="tag-table">
          <table #tagTableSort="matSort" [dataSource]="tagsTable" mat-table matSort>
            <ng-container matColumnDef="name">
              <th *matHeaderCellDef mat-header-cell mat-sort-header translate>Keyword</th>
              <td *matCellDef="let element" mat-cell class="cursor-pointer"
                  (click)="renameTag(element);"
                  matTooltip="{{'Bearbeiten'|translate}}" matTooltipPosition="left">
                {{element.name}}
              </td>
            </ng-container>

            <ng-container matColumnDef="featured">
              <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'Featured on Homepage' | translate}}</th>
              <td *matCellDef="let element" mat-cell>
                <button (click)="starTags(element)"
                        [matTooltip]="element.featured ? translateService.instant('Hervorgehoben') : translateService.instant('Click to feature on Homepage')"
                        class="px-1 ml-3" mat-icon-button matTooltipPosition="right">
                  <mat-icon [ngClass]="{'material-icons-outlined' : !element.featured , 'material-icons' : element.featured}">
                    grade
                  </mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th *matHeaderCellDef mat-header-cell style="width: 160px;"></th>
              <td *matCellDef="let element" mat-cell>
                <a [href]="'/tag/'+element.id" aria-label="open tag in new tab" class="px-1" mat-icon-button
                   target="_blank">
                  <mat-icon>open_in_new</mat-icon>
                </a>
                <button (click)="deleteTag(element)" class="px-1" mat-icon-button
                        matTooltip="{{'Delete keyword'| translate}}"
                        matTooltipPosition="right">
                  <mat-icon mat-list-icon>delete_outline</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr *matHeaderRowDef="['name', 'featured', 'action']" mat-header-row></tr>
            <tr *matRowDef="let row; columns: ['name', 'featured', 'action'];" mat-row></tr>
          </table>
          <mat-paginator pageSize="10" class="fontNormal"></mat-paginator>
          <div *ngIf="showSpinner" class="d-flex justify-content-center">
            <mat-spinner></mat-spinner>
          </div>
        </div>
        <!--if user is not authorised, let them know-->
        <h2 *ngIf="!authorised" class="text-nunito m-4" translate>
          You need Video Management rights to edit this
        </h2>
      </div>
    </section>
  </div>
</div>
