import { Component, ElementRef, OnInit, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';
import { MiniDetails, TagWiseContent, VideoCard, VideoHistoryCard, VideoView, TopicCard, TopicView } from '../models/video/video.interface';
import { PlaylistCard } from '../models/playlist/playlist';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { MyVideosVideoPopupComponent } from "../my-videos-video-popup/my-videos-video-popup.component";
import { PagePopupInternalComponent } from "../page-popup/page-popup-internal.component"
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Friend } from "../dashboard/global-administration/partner-organizations/partner-organizations.component";
import { UserActivitiesOverview, RecentCreator } from "../models/activity.interface";
import { ProgressSpinnerDialogComponent } from "../shared/progress-spinner-dialog/progress-spinner-dialog.component";
import { NavbarService } from "../services/navbar.service";
import { Chart } from 'chart.js';
import { FeedbackDialogComponent } from '../shared/feedback-dialog/feedback-dialog.component';

interface MixedCard {
  type: 'video' | 'topic' | 'playlist';
  data: VideoCard | PlaylistCard | TopicCard;
}
@Component({
  selector: 'internal-video',
  templateUrl: './internal-video.component.html',
  styleUrls: ['./internal-video.component.scss']
})
export class InternalVideoComponent implements OnInit, OnDestroy {
  cols: number;
  video_search_results: VideoCard[] = [];
  latestvideos: VideoCard[] = [];
  gutterSize: number;
  subscription: Subscription;
  backendUrl: string;
  pageSize: number = 10;
  searchQuery: any = "";
  sortValue: string = 'added_on';  // added_on
  page_title: string = "";
  query_construct: string = 'user/videos/';
  options = { observe: 'body', responseType: 'json' };
  chart_button_toggle: string = 'all';
  all_videos_case: boolean = true;  // to keep track of tag-case or all-videos-case
  workspaceData: any;
  recent_users: RecentCreator[] = [];
  myData: any;
  tags: MiniDetails[] = [];
  page: number = 1;
  maxPages: number = 1;
  showSpinner: boolean = false;
  partner_companies: Friend[] = [];
  greetings_text: string = "";
  active_days_label: string[] = [];  // ['Mo', 'Tu'…] last seven days, including today
  seven_days_label: string[] = [];  // labels when user was active
  userActivityResp: UserActivitiesOverview = null;
  is_chart_ready: boolean = false;
  increasePercentage: number = 0;
  history_videos: VideoHistoryCard[] = [];
  featured_videos: VideoCard[] = [];
  @ViewChild('scroll') scroll: ElementRef;
  userContentCase: boolean = false;
  chart1: any;
  chart2: any;
  all_topics: TopicCard[] = [];
  usertopics: TopicCard[] = [];
  mixed_tags_content: MixedCard[] = [];
  mixed_featured_content: MixedCard[] = [];
  search_type: string = "Clypps";
  smallSc: boolean = false;
  searchText: string = '';
  sort_option: string = "";
  filter_option: string = "";
  userName: string = "";
  homeSearch: boolean = false;
  topics_search_results: TopicCard[] = [];
  searchPageLength: number = 0;
  searchLength: number = 0;
  pageSearch: number = 1;
  maxPagesSearch: number = 0;
  searchKey: string = '';
  n_topic_results: number = 0;
  n_video_results: number = 0;
  n_results: number = 0;
  userId: number
  constructor(private dataService: DataService,
    private translateService: TranslateService,
    private navbarService: NavbarService,
    public authService: AuthService,
    private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.backendUrl = environment.backendURL;
    this.navbarService.getUserProfileDetails();

    // get main page results
    this.getInternalVideos();
    this.getPageData();
    this.getSearchResults();
    // this.clearOldVideoRecord();

    // get features videos and history
    this.getAllBlockData();

    // load tags and partnerships
    this.getFeaturedTags();
    const interval = setInterval(() => {

      // load after some time, as auth service may not be ready
      this.getPartnerships();
      // load after some time, as json file may not be ready
      this.getUserActivityDetails();

      clearInterval(interval);
    }, 400);

    // find hour of the day to show greetings
    const d = new Date();
    const hour = d.getHours();
    if (hour <= 11) {
      this.greetings_text = this.translateService.instant("Good morning");
    } else if (hour <= 16) {
      this.greetings_text = this.translateService.instant("Good afternoon");
    } else {
      this.greetings_text = this.translateService.instant("Good evening");
    }
    this.resize(window.innerWidth);

  }
  getPageData() {
    let get_url = `user/topics/?search_query=${this.searchQuery}&page=${this.pageSearch}`;
    const data_service_sub: Subscription = this.dataService.getURL<any>(get_url, this.options)
      .subscribe((res) => {
        this.n_topic_results = res['length'];
      })
  }

  loadCharts() {
    Chart.defaults.font.family = "nunito";
    Chart.defaults.font.size = 16;
    const canvas1 = document.getElementById('canvas1') as HTMLCanvasElement;
    const canvas2 = document.getElementById('canvas2') as HTMLCanvasElement;

    this.chart1 = new Chart(canvas1, {
      type: 'doughnut',
      data: {
        labels: [
          'Clypps',
          'Playlists',
          this.translateService.instant('Pages'),
        ],
        datasets: [
          {
            data: [
              this.userActivityResp.n_all_videos,
              this.userActivityResp.n_all_playlists,
              this.userActivityResp.n_all_topics
            ],
            backgroundColor: ['#5B93FF', '#FF8F6B', '#FFD66B'],
          },
        ]
      },
      options: {
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false
          }
        },
        plugins: {
          legend: {
            display: true,
            onClick: null,
            position: 'right',
            labels: {
              usePointStyle: true,
              color: 'black',
            },
          },
          tooltip: {
            enabled: true
          }
        },
        aspectRatio: 1.9,
        cutout: '80%',

        maintainAspectRatio: true
      },
    });

    this.chart2 = new Chart(canvas2, {
      type: 'doughnut',
      data: {
        labels: [
          'Clypps',
          'Playlists',
          this.translateService.instant('Pages'),
        ],
        datasets: [
          {
            data: [
              this.userActivityResp.n_my_videos,
              this.userActivityResp.n_my_playlists,
              this.userActivityResp.n_my_topics
            ],
            backgroundColor: ['#5B93FF', '#FF8F6B', '#FFD66B'],
          },
        ]
      },
      options: {
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false
          }
        },
        plugins: {
          legend: {
            display: true,
            onClick: null,
            position: 'right',
            labels: {
              usePointStyle: true,
              color: 'black',
            },
          },
          tooltip: {
            enabled: true
          }
        },
        aspectRatio: 1.9,
        cutout: '80%',
        responsive: true,
        maintainAspectRatio: true
      },
    });

    this.is_chart_ready = true;
  }
  backToInternal($event) {
    this.userContentCase = false;
  }
  // naviagate to user content page
  navigateTouserContentCase(user_id:number) {
    this.userId = user_id;
    this.userContentCase = true;
  }
  onChartChange() {
    const chart = (this.chart_button_toggle === 'mine') ? this.chart2 : this.chart1;
    chart.options.animation.duration = 2000;
    chart.reset();
    chart.update();
  }
  clearOldVideoRecord() {
    this.all_videos_case = true;
    this.page = 1;
    this.maxPages = 1;
    this.pageSearch = 1;
    this.searchLength = 0;
    this.maxPagesSearch = 0;
    this.mixed_tags_content = [];
    this.video_search_results = []
    this.topics_search_results = [];
    this.n_topic_results = 0;
    this.n_results = 0;
    this.n_video_results = 0;
  }
  getSearchResults() {
    this.showSpinner = true;
    if (this.pageSearch == 1) {
      this.video_search_results = [];
      this.topics_search_results = [];
    }
    let get_url = `user/videos/?search_query=${this.searchQuery}&page=${this.pageSearch}`;
    switch (this.search_type) {
      case 'Clypps':
        // get url is unaffected
        break;
      case 'Pages':
        get_url = `user/topics/?search_query=${this.searchQuery}&page=${this.pageSearch}`;
        break;
    }
    const data_service_sub: Subscription = this.dataService.getURL<any>(get_url, {
      observe: 'body',
      responseType: 'json'
    })
      .subscribe((res) => {
        if (this.search_type == 'Pages') {
          this.topics_search_results.push(...res.data);
          this.n_topic_results = res['length'];
          this.n_results = res.length;

        } else {
          this.video_search_results.push(...res.data);
          this.n_video_results = res['length'];
          this.n_results = res.length;
        }
        this.maxPagesSearch = Math.ceil(res.length / res.pageSize);
        this.searchLength = res.length;
        this.showSpinner = false;
        if (this.pageSearch == 1) {
          if (this.maxPages > 1) {
            this.onScrollDown();
          }
        }
      },
        (err) => {
          this.showSpinner = false;
        }
      );

  }
  onScrollDown() {
    this.pageSearch = this.pageSearch + 1;
    if (this.pageSearch <= this.maxPagesSearch) {
      this.getSearchResults();
    }
  }
  applyFilter(): void {
    if (this.searchQuery !== '') {
      this.searchKey = this.searchQuery
      this.clearOldVideoRecord();
      this.homeSearch = true;
      this.query_construct = 'user/videos/';
      this.search_type = "Clypps";
      this.n_results = 0;
      this.n_topic_results = 0;
      this.n_video_results = 0;
      this.getInternalVideos();
      this.getPageData();
      this.getSearchResults();
    }
    else {
      if (this.homeSearch) {
        this.back();
      }
    }
  }

  chartInterval() {
    const interval = setInterval(() => {
      // load after 1 sec, as html may not be ready
      this.loadCharts();
      clearInterval(interval);
    }, 500);
  }
  partnerSelected(partner: Friend) {
    this.clearOldVideoRecord();
    this.searchQuery = '';
    this.page_title = this.translateService.instant("All content");
    this.page_title += " ";
    this.page_title += this.translateService.instant("of");
    this.page_title += " ";
    this.page_title += partner.sharing_company.name;
    this.query_construct = `user/friends/${partner.id}/`;
    this.getInternalVideos();
  }

  getInternalVideos(): void {
    //if (this.page <= this.maxPages && !this.showSpinner && this.all_videos_case) {
    this.all_videos_case = true;
    this.showSpinner = true;
    let queryConstruct = `${this.query_construct}?sort_by=${this.sortValue}&page=${this.page}&search_query=${this.searchQuery}`;
    this.dataService.getURL(queryConstruct, this.options).subscribe((resp: any) => {
      this.maxPages = Math.ceil(resp.length / resp.pageSize);
      for (let i of resp['data'] as VideoCard[]) {
        this.mixed_tags_content.push({ type: "video", data: i });
      }

      //this.videos.push(...resp['data']);
      this.showSpinner = false;
      // this.page = resp.pageIndex + 1;

      // load more videos if we are on page 1
      // if (this.page == 2) {
      //   this.getInternalVideos();
      // }
      // if (this.homeSearch)
      //   this.searchLength = resp.length;
    }), (err) => {
      console.error(err);
      this.showSpinner = false;
    }

  }
  resize(innerWidth) {
    let cw = 320; // card width
    let gs = 20;  // gutter size
    let sn = 50; // small side nav width = 50 approx
    this.cols = Math.floor((innerWidth - sn) / (cw + gs));
  }
  displayPage(page_id) {
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });
    this.dataService.getURL<TopicView>(`user/topics/${page_id}/`, this.options).subscribe((res: TopicView) => {
      dialogRef.close();
      this.dialog.open(PagePopupInternalComponent, {

        height: '90%',
        disableClose: false,
        data: res,
        panelClass: 'my-panel',
      });
    }, (err) => {
      dialogRef.close();
      window.alert(err.error);
    });
  }
  playVideo(video_id) {
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
      });
    this.dataService.getURL<VideoView>(`user/videos/${video_id}/`, this.options).subscribe((res: VideoView) => {
      dialogRef.close();
      this.dialog.open(MyVideosVideoPopupComponent, {
        // width: '1120px',
        height: '90%',
        disableClose: false,
        data: res,
        panelClass: 'my-panel',
      });
    }, (err) => {
      dialogRef.close();
      window.alert(err.error);
    });
  }
  openFeedbackDialog() {
    this.dialog.open(FeedbackDialogComponent, {
      width: "40%",
      minWidth: "400px",
      autoFocus: false,
      disableClose: false,
      hasBackdrop: true
    });
  }
  openAccountPage() {
    window.open(`user_profile`)
  }
  clyppPublicPage() {
    // take user to public page of Clypp
    window.open("https://clypp.app/public/537", "_blank");
  }

  openHelpPage() {
    window.open("https://clypp.app/pages/view/8d1130a3-0186-4e03-ac75-8649359666a6", '_blank');
  }

  openNewsPage() {
    window.open("https://clypp.app/pages/view/4bfbe70d-c5b6-4cf6-a738-e51f94fd7cad", '_blank');
  }
  getFeaturedTags(): void {
    this.dataService.getURL('user/featured-tags/').subscribe((data: any) => {
      this.tags = data;
    });
  }

  getPartnerships(): void {
    // todo: load partner companies only if enabled
    if (this.authService.company?.is_partner_content_shown) {
      this.dataService.getURL('user/friends/', this.options)
        .subscribe((res: Friend[]) => {
          this.partner_companies = res;
        }, (err) => {
          console.error(err);
          // window.alert(err.error);
          this.showSpinner = false;
        });

    }
  }
  ngAfterViewInit(): void {
    if (window.innerWidth <= 900) {
      this.smallSc = true;
    }
    else {
      this.smallSc = false;
    }
  }
  tagSelected(tag: MiniDetails) {

    this.page_title = this.translateService.instant("All content");
    this.page_title += " ";
    this.page_title += this.translateService.instant("on");
    this.page_title += " ";
    this.page_title += tag.name;

    this.all_videos_case = false;
    // load the videos:
    let tagUrl = `user/tags/${tag.id}/`;
    this.mixed_tags_content = [];
    this.showSpinner = true;
    this.dataService.getURL(tagUrl, this.options).subscribe((resp: TagWiseContent) => {

      for (let i of resp.videos as VideoCard[]) {
        this.mixed_tags_content.push({ type: "video", data: i });
      }

      for (let i of resp.topics as TopicCard[]) {
        this.mixed_tags_content.push({ type: "topic", data: i });
      }

      this.showSpinner = false;
    }, (err) => {
      console.error(err);
      // window.alert(err.error);
      this.showSpinner = false;
    });
  }

  getUserActivityDetails() {
    this.dataService.getURL('user/activities/overview/', this.options)
      .subscribe((res: UserActivitiesOverview) => {
        this.userActivityResp = res;
        this.recent_users = this.userActivityResp.recent_creators;
        const initial = res.n_14_days - res.n_7_days;
        const final = res.n_7_days;
        // formula: (final-initial)/initial
        if (initial != 0) {
          this.increasePercentage = Math.round(((final - initial) / initial) * 100);
        } // else, do not show

        // prepare last 7 active days
        for (let i of this.userActivityResp.active_date_7_days) {
          this.active_days_label.push(new Date(i).toDateString().slice(0, 2));
        }
        let i = 6;
        let seven_days: Date[] = [];
        while (i >= 0) {
          const today = new Date();
          seven_days.push(new Date(today.setDate(today.getDate() - i)));
          i--;
        }
        for (let i of seven_days) {
          this.seven_days_label.push(i.toDateString().slice(0, 2));
        }

        // not undefined
        this.chartInterval();


      }, (err) => {
        window.alert(err.error.detail);
      });
  }

  getAllBlockData() {
    const featuredVideos = this.dataService.getURL('user/videos/?is_featured=true&sort_by=added_on', this.options);
    const continueWatching = this.dataService.getURL('user/videos/history/?page=1', this.options);
    const newestClypp = this.dataService.getURL('user/videos/?sort_by=added_on&page=1', this.options);
    const newestTopics = this.dataService.getURL('user/topics/?page=1&sort_by=added_on', this.options);
    const featuredPlaylist = this.dataService.getURL('user/playlists/?page=1&is_featured=true', this.options);
    const featuredTopics = this.dataService.getURL('user/topics/?page=1&is_featured=true', this.options);
    forkJoin([featuredVideos, continueWatching, newestClypp, newestTopics, featuredPlaylist, featuredTopics]).subscribe(
      (responses) => {
        this.featured_videos = responses[0]['data'] as VideoCard[];
        this.history_videos = responses[1]['data'] as VideoHistoryCard[];
        this.latestvideos = responses[2]['data'] as VideoCard[];
        this.all_topics = responses[3]['data'] as TopicCard[];
        // load the mixed cards
        for (let i of responses[0]['data'] as VideoCard[]) {
          this.mixed_featured_content.push({ type: "video", data: i });
        }
        for (let i of responses[4]['data'] as PlaylistCard[]) {
          this.mixed_featured_content.push({ type: "playlist", data: i });
        }
        for (let i of responses[5]['data'] as TopicCard[]) {
          this.mixed_featured_content.push({ type: "topic", data: i });
        }

        // now sort them
        this.mixed_featured_content.sort((a, b) =>
          new Date(b.data.added_on).getTime() - new Date(a.data.added_on).getTime());
      },
      (error) => {
        console.error(error);
      }
    );
  }

  scrollToLeftRight(id: string, left: boolean = false) {
    const element = document.getElementById(id);
    // element has 2+x children, first and last children are buttons
    // scroll distance = width of each child * number of children visible
    const child_width = element.firstElementChild.clientWidth;
    const viewport_width = element.clientWidth;
    const number_of_visible_children = Math.max(Math.floor(viewport_width / child_width), 1);
    // above number may become 0 for very large zoom levels, hence using max

    let scroll_distance = (child_width + 20) * number_of_visible_children;
    // margin-right is added
    if (left) {
      scroll_distance = -scroll_distance;
    }

    element.scrollBy({
      behavior: "smooth",
      left: scroll_distance,
      top: 0
    });
  }

  //scrolled to the top of the view
  scrollTop() {
    this.scroll.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
  onScroll() {
    document.getElementsByClassName('header')[0].classList.add("mat-elevation-z2")
  }
  back() {
    this.userContentCase = false;
    this.page_title = ''
    this.searchQuery = '';
    this.homeSearch = false;
    this.filter_option = '';
    this.sort_option = '';
    this.searchText = '';
    this.chartInterval();
    this.clearOldVideoRecord();
    this.getInternalVideos();

  }
  searchTypeChanged() {
    this.pageSearch = 1;
    this.maxPagesSearch = 0;
    this.getSearchResults()
  }
  ngOnDestroy() {
    this.chart1.destroy();
    this.chart2.destroy();
  }
  clearInput() {
    // clear search query if any,
    // else go back if in search case
    if (this.searchQuery) {
      this.searchKey = this.searchQuery;
      this.searchQuery = '';
    }
    else {
      if (this.userContentCase) {
        this.back();
      }
    }
  }

}
