import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import { AuthService } from '../services/auth.service';
import { inIframe } from "../login/login.component";
import { DataService } from "../services/data.service";
import { TranslateService } from '@ngx-translate/core';
import { environment } from "../../environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { MiniDetails, Topic } from "../models/video/video.interface";
import { SetCreatorDialogComponent } from '../shared/set-creator-dialog/set-creator-dialog.component';
import { GroupOverview } from "../groups/groups-view/groups-view.component";
import { HttpErrorResponse } from "@angular/common/http";
import { VideoRequestDialogComponent } from "../shared/video-request-dialog/video-request-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  visible: boolean;
  creationMode: boolean;
  locale: string = 'en-US';
  locale_options: any = { year: 'numeric', month: 'short', day: 'numeric' };
  showSideNav = false;

  constructor(private authService: AuthService,
    private router: Router,
    private dataService: DataService,
    private translateService: TranslateService,
    private dialog: MatDialog) {
    this.visible = true;
    this.creationMode = false;
  }

  hide(): void {
    this.visible = false;
  }

  enableCreationMode(): void {
    this.creationMode = true;
    this.showSideNav = false;
  }

  disableCreationMode(): void {
    this.creationMode = false;
    this.showSideNav = true;
  }

  getUserProfileDetails() {
    if (!this.authService.currentUser?.is_profile_completed) {
      this.router.navigate(['user_profile']);
    }
  }

  createPRVideo() {
    if (!this.authService.userDetails.is_creator) {
      this.setCreatorPopup();
      return;
    }
    this.locale = this.authService.userDetails.email_language;
    const date: Date = new Date();
    let title: string = 'New Clypp';
    this.translateService.get('_RECORDING_TITLE', {
      val_1: date.toLocaleDateString(this.locale, this.locale_options),
      val_2: date.toLocaleTimeString(this.locale, { hour: '2-digit', minute: '2-digit' })
    }).subscribe((res: string) => {
      title = res;
    });

    let postData = {
      title: title,
      type: 'PR',
      desc: "",
      script: ""
    }

    this.dataService.postURL<string>(`user/videos/`, postData, { observe: 'body', responseType: 'json' })
      .subscribe((res) => {
        this.dialog.closeAll();
        if (res['reason']) {
          window.alert(res['reason']);
        } else {
          let record_url = 'create-video/' + res['id'] + '/record';
          if (inIframe() || this.router.url.includes('/internal')) {
            // we are in iframe, check trello case
            // In trello app, we do not recommend user recording a video from app/browser iframe itself
            // instead, we use token from trello and log user into a new tab
            let trello_token: string = localStorage.getItem('trello_token');
            if (trello_token) {
              // user is clicking on record button from trello dashboard, take them to direct backend login
              // also delete the video
              this.dataService.postURL(`user/videos/${res['id']}/`, { 'action': 'delete' }).subscribe();
              let login_with_trello_url = environment.backendURL + '/trello/login/' + trello_token + '/';
              window.open(login_with_trello_url);
            } else {
              // take user to record page of that video
              window.open(record_url, '_blank');
            }
          } else {
            // user is not in iframe
            this.router.navigate([record_url]);
          }
        }
      });
  }

  checkTeamsBrowser() {
    if (navigator.userAgent.indexOf('Teams') > -1 || this.checkTeamsBrowser()) {
      return true;
    }
    else {
      return false;
    }
  }

  // for creating new topic
  createTopic() {
    if (!this.authService.userDetails.is_creator) {
      // creator popup
      this.setCreatorPopup();
      return;
    }

    this.locale = this.authService.userDetails.email_language;
    const date: Date = new Date();
    let title: string = 'New Topic';

    this.translateService.get('_PAGE_TITLE', {
      val_1: date.toLocaleDateString(this.locale, this.locale_options),
      val_2: date.toLocaleTimeString(this.locale, { hour: '2-digit', minute: '2-digit' })
    }).subscribe((res: string) => {
      title = res;
    });
    const postData = {
      title: title
    }
    this.dataService.postURL<Topic>(`user/topics/`, postData, { observe: 'body', responseType: 'json' })
      .subscribe((resp: Topic) => {
        // create a section first, then go to topic
        this.dataService.postURL(`user/topics/${resp.id}/`, { content: "" }).subscribe((res: any) => {
          // do nothing, the section is successfully created
        }, (err: HttpErrorResponse) => {
          // failed to create section but topic was created
          console.error(err);
        }, () => {
          // in all cases, just go to topic edit page
          if (this.router.url.includes('/pages/edit/') || inIframe() || this.checkTeamsBrowser()) {
            // refresh the page
            window.open(`pages/edit/${resp.id}`, '_blank')
          } else {
            // just update url
            this.router.navigate(['pages', 'edit', resp.id]);
          }
        });
      }, (err: HttpErrorResponse) => {
        console.error(err);
        window.alert(err.error);
      });
  }

  setCreatorPopup() {
    // open creator popup
    this.dialog.open(SetCreatorDialogComponent, {
      width: "615px",
      maxWidth: "86vw",
      disableClose: false,
      panelClass: 'my-dialog'
    });
  }

  async createTag(name: string): Promise<MiniDetails> {
    const local_subscription = this.dataService.postURL<MiniDetails>('user/tags/',
      { name: name },
      { observe: 'body', responseType: 'json' });
    const new_tag = await local_subscription.toPromise()
    this.authService.tag_data.push(new_tag);
    return new_tag;
  }

  addPlaylist() {
    this.locale = this.authService.userDetails.email_language;
    const date: Date = new Date();
    let title: string = 'New Playlist';
    this.translateService.get('_PLAYLIST_TITLE', {
      val_1: date.toLocaleDateString(this.locale, this.locale_options),
      val_2: date.toLocaleTimeString(this.locale, { hour: '2-digit', minute: '2-digit' })
    }).subscribe((res: string) => {
      title = res;
    });
    // const playlist_name: string = `Playlist ${this.all_playlists.length + 1}`;
    const form_body: any = { title: title, is_draft: true };
    this.dataService.postURL(`user/playlists/`, form_body).subscribe((res: any) => {
      if (res['reason']) {
        window.alert(res['reason']);
      } else {
        if (this.router.url.includes('/createPlaylistMyVideos/') || inIframe() || this.checkTeamsBrowser()) {
          // refresh the page
          window.open(`createPlaylistMyVideos/${res.id}`, '_blank')
        } else {
          // just update url
          this.router.navigate(['createPlaylistMyVideos', res.id]);
        }
      }
    });
  }

  addGroup() {
    if (!this.authService.userDetails.is_creator) {
      this.setCreatorPopup();
      return;
    }

    const prompt_message = this.translateService.instant('Please provide a name:');
    let input = window.prompt(prompt_message);
    if (input) {
      input = input.trim();
      if (input) {
        // valid input
        const body = { title: input };
        const options: any = { observe: 'body', responseType: 'json' };
        this.dataService.postURL(`user/groups/`, body, options).subscribe((res: GroupOverview) => {
          if (res['response'] == 'failure') {
            window.alert(res['reason']);
          } else {
            // go to that group
            this.authService.checklist_data.push({
              id: res.id,
              name: res.title,
              string: this.translateService.instant("Group"),
              type: 'group'
            });
            this.router.navigate(['groups', res.id]);
          }
        });
      }
    }
  };

  openVideoRequestDialog() {
    this.dialog.open(VideoRequestDialogComponent, {
      minWidth: "400px",
      width: '50%',
      disableClose: true,
      autoFocus: false,
    });
  }
}
