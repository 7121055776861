import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';
import { PasswordDialogComponent } from '../password-dialog/password-dialog.component';
import { UserProfileErrorDetailsDialogComponent } from '../user-profile-error-details-dialog/user-profile-error-details-dialog.component';
import { NavbarService } from '../../services/navbar.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from '@ngx-translate/core';
import {MiniDetails} from "../../models/video/video.interface";
import {ProgressSpinnerDialogComponent} from "../progress-spinner-dialog/progress-spinner-dialog.component";

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})

export class UserProfileComponent implements OnInit {

  readonly maxSize = 5000000;
  is_profile_completed = false;
  locations: any[] = [];
  languages: [string, string][] = [];
  departments: MiniDetails[] = [];
  teams: MiniDetails[] = [];
  initial_teams_list: MiniDetails[] = [];
  rolesResp: MiniDetails[] = [];
  locationResp: MiniDetails[] = [];
  profile_pic_url: string = "";

  team_id: number = 0;

  disable_vanilla_registration: boolean = false;
  clypp_privacy_policy_url: string = "";
  custom_privacy_policy_text: string = "";
  custom_privacy_policy_url: string = "";
  dept_alias: string = "Department";
  team_alias: string = "Team";

  regForm = this.formBuilder.group({
    user: [{ value: '', disabled: true }],
    first_name: [{ value: '' }, Validators.required],
    last_name: [{ value: '' }, Validators.required],
    email: [{ value: '', disabled: true }],
    company: [{ value: '', disabled: true }],
    location: ['', Validators.required],
    department: ['', Validators.required],
    team: ['', Validators.required],
    user_role: ['', Validators.required],
    is_profile_completed: [{ value: null, disabled: true }],
    is_marketing_accepted: [false],
    is_privacy_accepted: [false, Validators.requiredTrue],
    is_creator: [''],
    video_liked:[false],
    video_unliked:[false],
    video_commented:[false],
    video_shared:[false],
    video_public_playlist:[false],
    video_approved:[false],
    video_featured:[false],
    video_un_featured:[false],
    video_processed:[false],
    video_transcribed:[false],
    dept_video_requested:[false],
    team_video_requested:[false],
    group_added:[false],
    group_removed:[false],
    group_video_added:[false],
    group_invitation_accepted:[false],
    group_invitation_rejected:[false],

    profile_edited:[false],

    video_request_fulfilled:[false],
  });

  profile_edited: boolean = false;

  // video_request_fulfilled: boolean = false;

  dept_dd_width : any = 0;
  team_dd_width : any = 0;
  loc_dd_width : any = 0;

  initial_form: any = null;

  constructor(private formBuilder: UntypedFormBuilder,
    public dataService: DataService,
    public authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private translateService:TranslateService,
    private navbarService: NavbarService,
    private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    if (this.authService.company.name) {
      // good, the company data is ready, prepare the data
      this.prepareRegForm();
    } else {
      // wait for a second and prepare data
      const timeout = setTimeout(() => {
        this.prepareRegForm();
        clearTimeout(timeout);
      }, 1000);
    }

    // now we have data in langCtrl
    this.loadLanguages();

    // on language change, correct the text
    this.translateService.onLangChange.subscribe(() => {
      this.getPrivacyLinkAndTranslations();
    })
  }

  loadLanguages() {
    for (let i of this.authService.languages.entries()) {
      this.languages.push(i);
    }
    // if somehow fails to load languages, then do this
    if (this.languages.length == 0) {
      this.dataService.getURL<any[]>('user/videos/languages/', {observe: 'body', responseType: 'json'})
        .subscribe((resp: any[]) => {
          this.languages = resp;
        });
    }
  }

  openPasswordDialog() {
    this.dialog.open(PasswordDialogComponent, {
      disableClose: false,
      minWidth: '400px'
    });
  }

  // user will be logged out from all devices, including Trello and Teams
  // token will be deleted and a backend activity will also be created
  logOutAllDevices() {
    let message = this.translateService.instant("Bist du sicher?");
    if(window.confirm(message)){
      this.dataService.deleteURL(`user/`).subscribe();
      // use logout from nabvbar, so that user would be redirected to proper url
      this.authService.logout();
    }
  }

  showHideSideNav(): void {
    if (!this.authService.currentUser.is_profile_completed) {
      this.navbarService.showSideNav = false;
      this.regForm.markAsDirty();  // needed as first time users may not see save button
    }
    else {
      this.navbarService.showSideNav = true;
    }
  }

  onDepartmentSelect() {
    this.dataService.getURL<any>(`user/company/department/${this.regForm.get('department').value}/teams/`)
      .subscribe((res) => {
        this.teams = res;
        const longest = this.getLengthOfMaxContent([...res])
        this.team_dd_width = longest.name.length;
        if (this.team_id){
          // set selected team
          this.regForm.get('team').setValue(this.team_id);
          this.team_id = 0; // to ensure that upon dept change, this block is not called
        }
        else {
          // set first team
          this.regForm.get('team').setValue(this.teams[0].id);
        }
      });
  }

  setTransLanguage(changedLang) {
    let lang = 'en';
    if(changedLang.indexOf('de')==0){
      lang = 'de';
    }
    this.translateService.use(lang);
    localStorage.setItem('language', lang);
    // document.documentElement.setAttribute("lang", lang);
    // now, we need to save it as well, user may not press the save button
    this.dataService.putURL('user/', {'email_language': changedLang}).subscribe(() => {
      this.authService.userDetails.email_language = changedLang;
      this.snackBar.open(this.translateService.instant('Gespeichert'), "", { duration: 1000 });
    }, () => {
      let message = this.translateService.instant('Ein Fehler ist aufgetreten');
      this.snackBar.open(message, "", { duration: 1000 });
    });
  }

  gotoHome(): void {
    this.router.navigate(['start']);
  }

  private prepareRegForm(): void {
    this.showHideSideNav();

    this.regForm.get('user').setValue(this.authService.currentUser.name);
    this.regForm.get('email').setValue(this.authService.currentUser.email);
    this.regForm.get('first_name').setValue(this.authService.currentUser.firstName);
    this.regForm.get('last_name').setValue(this.authService.currentUser.lastName);

    // update company related local parameters
    this.regForm.get('company').setValue(this.authService.company.name);
    // check if we need to show password field
    this.disable_vanilla_registration = this.authService.company.disable_vanilla_registration;
    this.dept_alias = this.authService.company.dept_alias;
    this.team_alias = this.authService.company.team_alias;

    this.dataService.getURL<any>('user/').subscribe((res) => {
      // update location
      this.dataService.getURL(`user/company/locations/`).subscribe((locationResp: MiniDetails[]) => {
        this.locationResp = locationResp;
        const longest = this.getLengthOfMaxContent([...locationResp])
        this.loc_dd_width = longest.name.length;
        // set first default value
        if (res.location) {
          // set correct location, else first default value stays
          this.regForm.get('location').setValue(res.location);
        } else {
          this.regForm.get('location').setValue(this.locationResp[0].id);
        }
      });

      // update roles
      // fetch roles first, before performing any action
      this.dataService.getURL(`user/company/roles/`, {
        observe: 'body',
        responseType: 'json'
      }).subscribe((rolesResp: MiniDetails[]) => {
        this.rolesResp = rolesResp;
        // roles can be empty
        if (res.user_role) {
          // set correct role
          this.regForm.get('user_role').setValue(res.user_role);
        } else {
          // else first default value stays
          if (this.rolesResp.length) {
            this.regForm.get('user_role').setValue(this.rolesResp[0].id);
          }
        }
      });

      // update departments
      this.dataService.getURL<any>('user/company/department/').subscribe((deptResp) => {
        this.departments = deptResp;
        const longest = this.getLengthOfMaxContent([...deptResp])
        this.dept_dd_width = longest.name.length;

        if (res.department) {
          // set correct department
          this.regForm.get('department').setValue(res.department);
          this.team_id = res.team;
        }
        else {
          // else first default value stays
          this.regForm.get('department').setValue(this.departments[0].id);
        }
        // fetch teams for this dept
        this.onDepartmentSelect();
      });

      this.profile_pic_url = res.profile_pic;
      this.regForm.get('is_profile_completed').setValue(res.is_profile_completed);

      this.is_profile_completed = res.is_profile_completed;

      this.regForm.get('is_marketing_accepted').setValue(res.is_marketing_accepted);
      this.regForm.get('is_privacy_accepted').setValue(res.is_privacy_accepted);
      this.regForm.get('video_liked').setValue(res.video_liked);
      this.regForm.get('video_unliked').setValue(res.video_unliked);
      this.regForm.get('video_commented').setValue(res.video_commented);
      this.regForm.get('video_shared').setValue(res.video_shared);
      this.regForm.get('video_public_playlist').setValue(res.video_public_playlist);
      this.regForm.get('video_approved').setValue(res.video_approved);
      this.regForm.get('video_featured').setValue(res.video_featured);
      this.regForm.get('video_un_featured').setValue(res.video_un_featured);
      this.regForm.get('video_processed').setValue(res.video_processed);
      this.regForm.get('video_transcribed').setValue(res.video_transcribed);
      this.regForm.get('dept_video_requested').setValue(res.dept_video_requested);
      this.regForm.get('team_video_requested').setValue(res.team_video_requested);
      this.regForm.get('group_added').setValue(res.group_added);
      this.regForm.get('group_removed').setValue(res.group_removed);
      this.regForm.get('group_video_added').setValue(res.group_video_added);
      this.regForm.get('group_invitation_accepted').setValue(res.group_invitation_accepted);
      this.regForm.get('group_invitation_rejected').setValue(res.group_invitation_rejected);
      this.regForm.get('profile_edited').setValue(res.profile_edited);
      this.regForm.get('video_request_fulfilled').setValue(res.video_request_fulfilled);

      this.getPrivacyLinkAndTranslations();  // update privacy url if business user
      this.saveInitialForm();  // create a form copy
    });
  }

  getPrivacyLinkAndTranslations() {
    // free/pro user
    this.clypp_privacy_policy_url = this.translateService.instant("POLICY_URL");

    // if company is 1440, then auto accept the policy
    if (this.authService.company.max_upload_video_height == '1440') {
      this.regForm.get('is_privacy_accepted').setValue(true);
    }

    if (this.authService.company.custom_privacy_url) {
      // then that company's policies apply
      this.custom_privacy_policy_url = this.authService.company.custom_privacy_url;
      // fetch the translation: the guidelines from company_name apply
      this.custom_privacy_policy_text = this.translateService.instant('_company_guidelines', {
        company_name: this.authService.company.name
      });
    }
  }

  openCreatorDialog() {
    this.navbarService.setCreatorPopup();
  }

  updateUserProfile(): void {
    if (this.regForm.invalid) {
      let err = [];
      if(this.regForm.get('first_name').value == '' || this.regForm.get('first_name').value == null){
        err.push(this.translateService.instant('Vorname'));
      }
      if(this.regForm.get('last_name').value == '' || this.regForm.get('last_name').value == null){
        err.push(this.translateService.instant('Nachname'));
      }
      if(this.regForm.get('location').value == '' || this.regForm.get('location').value == null){
        err.push(this.translateService.instant('Standort'));
      }
      if(this.regForm.get('department').value == '' || this.regForm.get('department').value == null){
        err.push(this.dept_alias);
      }
      if(this.regForm.get('team').value == '' || this.regForm.get('team').value == null) {
        err.push(this.team_alias);
      }
      if(this.regForm.get('is_privacy_accepted').value == false) {
        err.push(this.translateService.instant("Please accept the privacy policy"));
      }
      if(this.regForm.get('user_role').value == '' || this.regForm.get('user_role').value == null) {
        err.push('Position');
      }

      this.dialog.open(UserProfileErrorDetailsDialogComponent, {
        disableClose: false,
        panelClass: 'pwd-dialog',
        minWidth: "400px",
        data: err
      });

      return;
    }

    const formBasic = {
      "first_name": this.regForm.value.first_name,
      "last_name": this.regForm.value.last_name
    }

    this.dataService.putURL('user/basic/', formBasic).subscribe((resp) => {
      if (resp['reason']) {
        window.alert(resp['reason']);
      }
    });

    const profile_body = {
      "location": this.regForm.value.location,
      "department": this.regForm.value.department,
      "team": this.regForm.value.team,
      "user_role": this.regForm.value.user_role,
      "is_marketing_accepted": this.regForm.value.is_marketing_accepted,
      "is_privacy_accepted": this.regForm.value.is_privacy_accepted,
      "video_liked": this.regForm.value.video_liked,
      "video_unliked": this.regForm.value.video_unliked,
      "video_commented": this.regForm.value.video_commented,
      "video_shared": this.regForm.value.video_shared,
      "video_public_playlist": this.regForm.value.video_public_playlist,
      "video_approved": this.regForm.value.video_approved,
      "video_featured": this.regForm.value.video_featured,
      "video_un_featured": this.regForm.value.video_un_featured,
      "video_processed": this.regForm.value.video_processed,
      "video_transcribed": this.regForm.value.video_transcribed,
      "dept_video_requested": this.regForm.value.dept_video_requested,
      "team_video_requested": this.regForm.value.team_video_requested,
      "group_added": this.regForm.value.group_added,
      "group_removed": this.regForm.value.group_removed,
      "group_video_added": this.regForm.value.group_video_added,
      "group_invitation_accepted": this.regForm.value.group_invitation_accepted,
      "group_invitation_rejected": this.regForm.value.group_invitation_rejected,

      "profile_edited": this.regForm.value.profile_edited,

      "video_request_fulfilled": this.regForm.value.video_request_fulfilled,
    }

    // save data
    this.dataService.updateUser(profile_body).subscribe((resUser) => {
      if (resUser['reason']) {
        window.alert(resUser['reason']);
      } else {
        if (!this.is_profile_completed && resUser['is_profile_completed']) {
          // user is completing profile for the first time
          // go to either home page or on start page
          if (navigator.userAgent.indexOf('Teams') > -1) {
            window.location.href = '/internal';
          } else {
            window.location.href = '/start';
          }
        } else {
          // else, stay on the page
          location.reload();
        }
        this.snackBar.open(this.translateService.instant('Gespeichert'), "", {duration: 2500});
      }
    });
  }

  getLengthOfMaxContent(list): any {
    const longest = list.sort(
      function (a, b) {
        return b.name.length - a.name.length;
      }
    )[0];
    return longest;
  }

  // global admin: delete account after sending OTP
  // not global admin: send email to all global admins
  deleteUserAccount(){
    let message: string = "";

    if(this.authService.userDetails.is_global_manager){
      // confirm, send otp, then delete
      message = this.translateService.instant("All of your Clypp data, including videos, would be deleted.");
      message += "\n";
      message += this.translateService.instant("Would you like to continue?");
      if(window.confirm(message)){
        // send OTP
        this.dataService.deleteURL('user/basic/').subscribe((res) => {
          // 201: OTP sent successfully
          message = this.translateService.instant("Bitte gib hier den 4-stelligen PIN-Code ein,");
          message += "\n";
          message += this.translateService.instant("der soeben an deine geschäftliche Email-Adresse gesendet wurde:");
          let otp = window.prompt(message);
          if(parseInt(otp)){
            // send to BE
            this.dataService.deleteURL(`user/basic/?otp=${parseInt(otp)}`).subscribe((res) => {
              // 204: account deleted successfully
              // show message and log out
              message = this.translateService.instant("Erfolgreich gelöscht");
              this.snackBar.open(message, '', {duration: 2500});
              this.authService.logout();
            }, (err) => {
              // 400: failed to delete account
              console.error(err);
              message = this.translateService.instant("Falscher OTP-Pin");
              this.snackBar.open(message, '', {duration: 2500});
            });
          }
          else{
            message = this.translateService.instant("Falscher OTP-Pin");
            this.snackBar.open(message, '', {duration: 2500});
          }

        }, (err) => {
          // failed to send otp
          console.error(err);
          message = this.translateService.instant("Ein Fehler ist aufgetreten");
          this.snackBar.open(message, '', {duration: 2500});
        });
      }
    } else {
      // not a global admin, send email to admin
      message = this.translateService.instant("This will send an email to the global administrators of your workspace.");
      message += "\n";
      message += this.translateService.instant("They can then delete your account.");
      message += "\n";
      message += this.translateService.instant("The global administrators can decide to keep or delete your Clypps.");
      message += "\n\n";
      message += this.translateService.instant("Would you like to continue?");
      if (window.confirm(message)) {
        this.dataService.deleteURL(`user/basic/`).subscribe((res) => {
          message = this.translateService.instant("Erfolgreich");
          this.snackBar.open(message, '', {duration: 2500});
        });
      }
    }
  }

  saveInitialForm(){
    // save after 2 second so that the form is ready
    const interval = setInterval(() => {
      // getRawValue is to get all the value of the form including disabled
      this.initial_form = {...this.regForm.getRawValue()};
      // teams mat-option value is saved
      // because if dept get changed teams option also changed and cannot be reverted if not saving the initial value
      this.initial_teams_list = this.teams;
      clearInterval(interval);
    }, 2000);
  }

  discardChanges() {
    this.regForm.reset();
    this.regForm.markAsPristine();
    this.teams = this.initial_teams_list;
    this.regForm.patchValue(this.initial_form);
  }


  // uploads the profile pic immediately while showing spinner
  fileSelected(event) {
    // prepare form
    let formData = new FormData();
    let video_file = event;
    if(event.name === undefined){
      const ele = document.getElementById('file-upload') as HTMLInputElement
      video_file = ele.files[0];
      event.target.value = '';
    }
    if(video_file.size > this.maxSize){
      let message = this.translateService.instant("Limit Exceeded: ") +
        `${Math.floor(video_file.size / 1000000)} MB / ${this.maxSize / 1000000} MB`;
      window.alert(message);
      return;
    }
    // add the data to the form
    formData.append("profile_pic", video_file, video_file.name);
    // start upload
    // show spinner
    const dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> =
      this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true,
        data: "Uploading"
      });

    this.dataService.putURL('user/', formData, {observe: 'body', responseType: 'json'}).subscribe((res) => {
      this.profile_pic_url = res['profile_pic'];
      this.authService.userDetails.profile_pic = this.profile_pic_url;
      this.authService.currentUser.profile_pic = this.profile_pic_url;
      dialogRef.close();
      this.snackBar.open(this.translateService.instant('Gespeichert'), "", {duration: 2000});
    }, () => {
      let message = this.translateService.instant('Ein Fehler ist aufgetreten');
      dialogRef.close();
      this.snackBar.open(message, "", {duration: 2000});
    });
  }
}
